import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';


const CheckoutForm = ({onSubmit}) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { paymentIntent, error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: 'if_required'
        });

        if (error !== undefined) {
            console.error(error)
        }

        if (paymentIntent.status === 'succeeded') {
            console.log(`Payment Succeeded ${paymentIntent}`)
        } else if (paymentIntent.status === 'requires_action') {
            /*
            const result = await stripe.handleNextAction({
                clientSecret: paymentIntent.client_secret
            })
            */
        } else {
            console.log(`Payment Intent Status: ${paymentIntent.status}`)
        }

        onSubmit(false)
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <button disabled={!stripe}>Submit</button>
        </form>
    )
};

export default CheckoutForm;
