import React, { useRef, useState } from "react";
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import "./SignTripSlip.css";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import ResponseModal from "../../ResponseModal/ResponseModal";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  useMediaQuery,
  useTheme,
  Grid,
  Modal,
} from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../Dashboards/GuardianDashboard/CheckoutForm";

const stripePubKey = process.env.STRIPE_PUB_KEY;
const stripePromise = loadStripe(stripePubKey);

// Suppress ResizeObserver loop limit exceeded warning
const suppressResizeObserverError = () => {
  const resizeObserverErrDiv = document.createElement("div");
  resizeObserverErrDiv.id = "resize-observer-err-suppress";
  resizeObserverErrDiv.style.display = "none";
  document.body.appendChild(resizeObserverErrDiv);

  const resizeObserverErr = (event) => {
    if (event.message === "ResizeObserver loop limit exceeded") {
      event.stopImmediatePropagation();
    }
  };

  window.addEventListener("error", resizeObserverErr);
};

suppressResizeObserverError();

const SignTripSlip = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    guardianFirstName: "",
    guardianLastName: "",
    guardianEmail: "",
    guardianPhone: "",
    guardianAddress: {
      streetAddress: "",
      streetAddress2: "",
      city: "",
      state: "",
      zipCode: "",
    },
    emergencyInformation: {
      emergencyContactFirstName: "",
      emergencyContactLastName: "",
      emergencyRelationship: "",
      emergencyBestContact: "email",
      emergencyContactInfo: "",
    },
    childData: {
      childFirstName: "",
      childLastName: "",
      childAllergies: "",
      childAddressDifferent: false,
      childAddress: {
        streetAddress: "",
        streetAddress2: "",
        city: "",
        state: "",
        zipCode: "",
      },
      grade: "",
      teacher: "",
      school: "",
    },
  });
  const tripSlipData = location.state?.tripSlipData;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsLinkClicked, setTermsLinkClicked] = useState(false);
  const [contactMethod, setContactMethod] = useState("email");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [showPaymentScreen, shouldShowPaymentScreen] = useState(false);
  const [options, setOptions] = useState({});
  const [setPayment] = useState({
    tripSlipId: null,
    amount: 0,
  });
  const navigate = useNavigate();
  const sigPadRef = useRef(null);
  const url = process.env.REACT_APP_SERVICE_URL;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmitFromCheckoutForm = (data) => {
    shouldShowPaymentScreen(data);
    // todo: should successful payment allow parent to submit?
  };

  const clearSignature = () => {
    sigPadRef.current.clear();
  };

  if (!tripSlipData) return <Typography>Loading...</Typography>;

  const departureLocation = JSON.parse(tripSlipData.departureLocation);
  const destinationLocation = JSON.parse(tripSlipData.destinationLocation);

  const tripSlipDateFormatted = format(
    new Date(tripSlipData.tripSlipDate),
    "PPP"
  );
  const createdAtFormatted = format(new Date(tripSlipData.createdAt), "PPPp");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith("guardian")) {
      const fieldName = name.replace("guardian", "");
      if (
        [
          "StreetAddress",
          "StreetAddress2",
          "City",
          "State",
          "ZipCode",
        ].includes(fieldName)
      ) {
        setFormData((prevState) => ({
          ...prevState,
          guardianAddress: {
            ...prevState.guardianAddress,
            [fieldName.charAt(0).toLowerCase() + fieldName.slice(1)]: value,
          },
        }));
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else if (name === "childAddressDifferent") {
      setFormData({
        ...formData,
        childData: {
          ...formData.childData,
          childAddressDifferent: !formData.childData.childAddressDifferent,
        },
      });
    } else if (name.startsWith("child")) {
      const fieldName = name.replace("child", "");
      if (
        [
          "StreetAddress",
          "StreetAddress2",
          "City",
          "State",
          "ZipCode",
        ].includes(fieldName)
      ) {
        setFormData((prevState) => ({
          ...prevState,
          childData: {
            ...prevState.childData,
            childAddress: {
              ...prevState.childData.childAddress,
              [fieldName.charAt(0).toLowerCase() + fieldName.slice(1)]: value,
            },
          },
        }));
      } else {
        setFormData({
          ...formData,
          childData: {
            ...formData.childData,
            [name.replace("child", "").charAt(0).toLowerCase() +
            name.replace("child", "").slice(1)]: value,
          },
        });
      }
    } else if (name.startsWith("emergency")) {
      setFormData({
        ...formData,
        emergencyInformation: {
          ...formData.emergencyInformation,
          [name]: value,
        },
      });
    }
  };

  const handlePayment = async (tripSlipId, amount) => {
    console.info(`clicked handle payment with amount ${amount}`);
    let clientSecret;

    const body = {
      tripSlipId,
      amount,
      purchaseDate: new Date().toISOString(),
      status: "PENDING",
    };

    try {
      const response = await axios.post(`${url}/payments/intent/`, body);
      clientSecret = response.data.paymentIntent.client_secret;
      setOptions({ clientSecret });
      setPayment({
        tripSlipId,
        amount,
      });
      shouldShowPaymentScreen(true);
    } catch (error) {
      console.error("Error getting client secret");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const signatureImage = sigPadRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    const payload = new FormData();
    payload.append("tripSlipId", tripSlipData._id);
    payload.append("guardianFirstName", formData.guardianFirstName);
    payload.append("guardianLastName", formData.guardianLastName);
    payload.append("guardianEmail", formData.guardianEmail);
    payload.append("guardianPhone", formData.guardianPhone);
    payload.append("guardianAddress", JSON.stringify(formData.guardianAddress));
    payload.append(
      "emergencyInformation",
      JSON.stringify(formData.emergencyInformation)
    );
    payload.append("childData", JSON.stringify(formData.childData));
    payload.append("guardianSignatureImage", dataURItoBlob(signatureImage));

    try {
      const response = await axios.post(`${url}/signedtripslips`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201) {
        setModalHeader("Success");
        setModalMessage(
          `TripSlip Signed for ${formData.childData.firstName} ${formData.childData.lastName}`
        );
      } else {
        setModalHeader("Error");
        setModalMessage("An error occurred. Please try again.");
      }
      setModalVisible(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      setModalHeader("Error");
      setModalMessage("An error occurred. Please try again.");
      setModalVisible(true);
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    if (modalMessage.includes("Signed")) {
      navigate("/");
    }
  };

  const handleContactMethodChange = (event) => {
    setContactMethod(event.target.value);
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {tripSlipData.tripName}
        </Typography>
        <Typography variant="h5" gutterBottom>
          Trip Slip Code: {tripSlipData.tripSlipCode}
        </Typography>
        <Typography>Organizer: {tripSlipData.organizerName}</Typography>
        <Typography>Trip Slip Date: {tripSlipDateFormatted}</Typography>
        <Typography>
          Grades Involved: {tripSlipData.grades.join(", ")}
        </Typography>
        <Typography>
          Maximum Number of Students: {tripSlipData.maxNumOfStudents}
        </Typography>
        <Typography>
          Cost: {tripSlipData.cost ? tripSlipData.cost.numberDecimal : "$0"}
        </Typography>
        <Typography>Notes: {tripSlipData.notes}</Typography>
        <Typography>Created At: {createdAtFormatted}</Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box sx={{ mb: isMobile ? 2 : 0 }}>
            <Typography variant="h6">Departure Location</Typography>
            <Typography>
              <strong>{departureLocation.buildingName}</strong>
            </Typography>
            <Typography>{departureLocation.streetAddress}</Typography>
            <Typography>
              {departureLocation.city}, {departureLocation.state}{" "}
              {departureLocation.zipCode}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">Destination Location</Typography>
            <Typography>
              <strong>{destinationLocation.buildingName}</strong>
            </Typography>
            <Typography>{destinationLocation.streetAddress}</Typography>
            <Typography>
              {destinationLocation.city}, {destinationLocation.state}{" "}
              {destinationLocation.zipCode}
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h5" gutterBottom>
            Guardian Information
          </Typography>
          <TextField
            fullWidth
            label="Guardian First Name"
            name="guardianFirstName"
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Guardian Last Name"
            name="guardianLastName"
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Guardian Email"
            name="guardianEmail"
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Guardian Phone"
            name="guardianPhone"
            onChange={handleInputChange}
            margin="normal"
          />

          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Address
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Street Address"
                name="guardianStreetAddress"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Street Address 2"
                name="guardianStreetAddress2"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                name="guardianCity"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="State"
                name="guardianState"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                fullWidth
                label="Zip Code"
                name="guardianZipCode"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
            Child Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Child First Name"
                name="childFirstName"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Child Last Name"
                name="childLastName"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Child Allergies"
                name="childAllergies"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.childData.childAddressDifferent}
                onChange={handleInputChange}
                name="childAddressDifferent"
              />
            }
            label="Address different from Guardian"
          />

          {formData.childData.childAddressDifferent && (
            <>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Child's Address
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Street Address"
                    name="childStreetAddress"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Street Address 2"
                    name="childStreetAddress2"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="City"
                    name="childCity"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="State"
                    name="childState"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Zip Code"
                    name="childZipCode"
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Education Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Grade"
                name="childGrade"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Teacher"
                name="childTeacher"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="School"
                name="childSchool"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
            Emergency Contact Information
          </Typography>
          <TextField
            fullWidth
            label="Emergency Contact First Name"
            name="emergencyContactFirstName"
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Emergency Contact Last Name"
            name="emergencyContactLastName"
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Relationship"
            name="emergencyRelationship"
            onChange={handleInputChange}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Best Way To Contact</InputLabel>
            <Select
              value={contactMethod}
              onChange={handleContactMethodChange}
              label="Best Way To Contact"
            >
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
            </Select>
          </FormControl>
          {contactMethod === "email" ? (
            <TextField
              fullWidth
              type="email"
              label="Email"
              name="emergencyContactEmail"
              onChange={handleInputChange}
              margin="normal"
            />
          ) : (
            <TextField
              fullWidth
              type="tel"
              label="Phone Number"
              name="emergencyContactPhone"
              onChange={handleInputChange}
              margin="normal"
            />
          )}

          <Button
            onClick={() =>
              handlePayment(tripSlipData._id, tripSlipData.cost.$numberDecimal)
            }
            sx={{ mt: 1 }}
          >
            Click Here to Make Payment
          </Button>
          <br />
          <br />

          {tripSlipData.customTerms ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  disabled={!termsLinkClicked}
                />
              }
              label={
                <Typography>
                  I accept the terms of the field trip.{" "}
                  <Button
                    href={tripSlipData.customTerms}
                    target="_blank"
                    onClick={() => setTermsLinkClicked(true)}
                    rel="noopener noreferrer"
                  >
                    View Terms and Conditions
                  </Button>
                </Typography>
              }
            />
          ) : null}

          <Box sx={{ mt: 2, mb: 2 }}>
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: isMobile ? 300 : 500,
                height: 200,
                className: "sigCanvas",
              }}
              ref={sigPadRef}
              backgroundColor="white"
            />
            <Button
              onClick={clearSignature}
              disabled={tripSlipData.customTerms ? !termsAccepted : false}
              sx={{ mt: 1 }}
            >
              Clear Signature
            </Button>
          </Box>

          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </Paper>

      {modalVisible && (
        <ResponseModal
          header={modalHeader}
          message={modalMessage}
          onClose={handleCloseModal}
        />
      )}
      <Modal
        open={showPaymentScreen}
        onClose={() => shouldShowPaymentScreen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800, // should be percentage based
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm onSubmit={handleSubmitFromCheckoutForm} />
          </Elements>
        </Box>
      </Modal>
    </Container>
  );
};

export default SignTripSlip;
