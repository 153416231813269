import React, {useState, useEffect} from "react";
import axios from "axios";
import {
    Box,
    Button,
    Container,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import OrganizationAccessControl from "../../AccessControls/organizerAccessControl";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import './GuardianDashboard.css'

const stripePubKey = process.env.STRIPE_PUB_KEY;
const stripePromise = loadStripe(stripePubKey);

const GuardianDashboard = () => {
    OrganizationAccessControl();
    const url = process.env.REACT_APP_SERVICE_URL;
    const [dashboardData, setDashboardData] = useState({
        trips: []
    })
    const [showPaymentScreen, shouldShowPaymentScreen] = useState(false)
    const [options, setOptions] = useState({})
    const [setPayment] = useState({
        tripSlipId: null,
        userId: null,
        amount: 0
    })

    const handleSubmitFromCheckoutForm = (data) => {
        shouldShowPaymentScreen(data);
    };

    const handlePayment = async (tripSlipId, userId, amount) => {
        console.info(`clicked handle payment with amount ${amount}`)
        let clientSecret

        const token = sessionStorage.getItem("token")
        if (!token) return;

        const body = {
            tripSlipId,
            userId,
            amount,
            purchaseDate: new Date().toISOString(),
            status: 'PENDING'
        }

        try {
            const response = await axios.post(`${url}/payments/intent/`,
                body,
                {headers: { Authorization: `Bearer ${token}`}}
            )
            clientSecret = response.data.paymentIntent.client_secret
            setOptions({clientSecret})
            setPayment({
                tripSlipId,
                userId,
                amount
            })
            shouldShowPaymentScreen(true)
        } catch (error) {
            console.error("Error getting client secret")
        }
    }

    useEffect(() => {
        const fetchData = async() => {
            const token = sessionStorage.getItem("token")
            if (!token) return;

            try {
                const response = await axios.get(`${url}/payments/userId/67c612115e5c09ba37493526/trips`, {
                    // TODO get list of trips for guardian/user
                    headers: { Authorization: `Bearer ${token}` }
                })
                setDashboardData(response.data)
            } catch (error) {
                console.error("")
            }
        }

        fetchData()
    }, [url])

    return (
        <Container maxWidth="md">
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Trip Name</TableCell>
                            <TableCell>Trip Date</TableCell>
                            <TableCell>Trip Cost</TableCell>
                            <TableCell align="center">Payment Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dashboardData.trips.map((trip) => (
                            <TableRow
                                key={trip._id}
                            >
                                <TableCell>{trip.tripName}</TableCell>
                                <TableCell>{trip.tripSlipDate}</TableCell>
                                <TableCell>{trip.cost.$numberDecimal}</TableCell>
                                <TableCell align="center">
                                    {trip.payments.length === 0 ?
                                        <Button
                                            key={'button_' + trip._id}
                                            // todo determine how to handle user with payments
                                            onClick={() => handlePayment(trip._id, "67c612115e5c09ba37493526",trip.cost.$numberDecimal)}
                                        >
                                            Click to Make a Payment
                                        </Button>
                                        : trip.payments[0].status}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                open={showPaymentScreen}
                onClose={() => shouldShowPaymentScreen(false)}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800, // should be percentage based
                        bgcolor: 'background.paper',
                        border: '1px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Elements stripe={stripePromise} options={options}>
                        <CheckoutForm onSubmit={handleSubmitFromCheckoutForm} />
                    </Elements>
                </Box>
            </Modal>
        </Container>
    );
}

export default GuardianDashboard;
