import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "./CreateTripSlipForm.css"; // Import custom CSS for modal

const CreateTripSlipForm = () => {
  const [formState, setFormState] = useState({
    tripName: "",
    tripSlipDate: null,
    departureLocation: {
      buildingName: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
    },
    destinationLocation: {
      buildingName: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
    },
    cost: 0,
    notes: "",
  });

  const [formErrors, setFormErrors] = useState({
    tripName: false,
    tripSlipDate: false,
    departureLocation: {
      buildingName: false,
      streetAddress: false,
      city: false,
      state: false,
      zipCode: false,
    },
    destinationLocation: {
      buildingName: false,
      streetAddress: false,
      city: false,
      state: false,
      zipCode: false,
    },
  });

  const [termsFile, setTermsFile] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [tripSlipId, setTripSlipId] = useState(null);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const navigate = useNavigate();

  const url = process.env.REACT_APP_SERVICE_URL;
  const token = sessionStorage.getItem("token");
  const config = { headers: { Authorization: `Bearer ${token}` } };

  const SuccessModal = ({ onClose, onView }) => (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <p>Your TripSlip Has Been Created!</p>
        <div style={{ textAlign: "center" }}>
          <Button onClick={onView}>View</Button>
          <Button onClick={onClose}>Close</Button>
        </div>
      </div>
    </div>
  );

  const LoadingModal = () => (
    <div className="modal-backdrop">
      <div className="modal">
        <p>Loading...</p>
      </div>
    </div>
  );

  const ErrorModal = ({ onClose }) => (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <p>An error occurred. Please try again later.</p>
        <div style={{ textAlign: "center" }}>
          <Button onClick={onClose}>Close</Button>
        </div>
      </div>
    </div>
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Special handling for cost field
    if (name === "cost") {
      // Ensure only numbers are entered
      const numericValue = value.replace(/[^0-9.]/g, "");
      const parsedValue = parseFloat(numericValue);
      const finalValue = isNaN(parsedValue) ? 0 : parsedValue;
      setFormState({ ...formState, [name]: finalValue });
    } else {
      setFormState({ ...formState, [name]: value });
    }
  };

  const handleLocationChange = (e, locationType) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [locationType]: { ...formState[locationType], [name]: value },
    });
  };

  // Validate the current step fields
  const validateCurrentStep = () => {
    let isValid = true;
    let errors = { ...formErrors };

    // Step 0: Trip Details
    if (activeStep === 0) {
      if (!formState.tripName.trim()) {
        errors.tripName = true;
        isValid = false;
      } else {
        errors.tripName = false;
      }

      if (!formState.tripSlipDate) {
        errors.tripSlipDate = true;
        isValid = false;
      } else {
        errors.tripSlipDate = false;
      }
    }

    // Step 1: Both Locations
    else if (activeStep === 1) {
      // Validate Departure Location
      const depLocation = formState.departureLocation;
      const depLocationErrors = { ...errors.departureLocation };

      if (!depLocation.buildingName.trim()) {
        depLocationErrors.buildingName = true;
        isValid = false;
      } else {
        depLocationErrors.buildingName = false;
      }

      if (!depLocation.streetAddress.trim()) {
        depLocationErrors.streetAddress = true;
        isValid = false;
      } else {
        depLocationErrors.streetAddress = false;
      }

      if (!depLocation.city.trim()) {
        depLocationErrors.city = true;
        isValid = false;
      } else {
        depLocationErrors.city = false;
      }

      if (!depLocation.state.trim()) {
        depLocationErrors.state = true;
        isValid = false;
      } else {
        depLocationErrors.state = false;
      }

      if (!depLocation.zipCode.trim()) {
        depLocationErrors.zipCode = true;
        isValid = false;
      } else {
        depLocationErrors.zipCode = false;
      }

      errors.departureLocation = depLocationErrors;

      // Validate Destination Location
      const destLocation = formState.destinationLocation;
      const destLocationErrors = { ...errors.destinationLocation };

      if (!destLocation.buildingName.trim()) {
        destLocationErrors.buildingName = true;
        isValid = false;
      } else {
        destLocationErrors.buildingName = false;
      }

      if (!destLocation.streetAddress.trim()) {
        destLocationErrors.streetAddress = true;
        isValid = false;
      } else {
        destLocationErrors.streetAddress = false;
      }

      if (!destLocation.city.trim()) {
        destLocationErrors.city = true;
        isValid = false;
      } else {
        destLocationErrors.city = false;
      }

      if (!destLocation.state.trim()) {
        destLocationErrors.state = true;
        isValid = false;
      } else {
        destLocationErrors.state = false;
      }

      if (!destLocation.zipCode.trim()) {
        destLocationErrors.zipCode = true;
        isValid = false;
      } else {
        destLocationErrors.zipCode = false;
      }

      errors.destinationLocation = destLocationErrors;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleNext = () => {
    // Validate current step fields
    if (!validateCurrentStep()) {
      // Show alert for missing required fields
      alert("Please fill out all required fields before proceeding.");
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all steps before submission
    if (!formState.tripName.trim() || !formState.tripSlipDate) {
      alert("Please ensure Trip Name and Date are filled out");
      setActiveStep(0);
      return;
    }

    const departureLocation = formState.departureLocation;
    if (
      !departureLocation.buildingName.trim() ||
      !departureLocation.streetAddress.trim() ||
      !departureLocation.city.trim() ||
      !departureLocation.state.trim() ||
      !departureLocation.zipCode.trim()
    ) {
      alert("Please ensure all Departure Location fields are filled out");
      setActiveStep(1);
      return;
    }

    const destinationLocation = formState.destinationLocation;
    if (
      !destinationLocation.buildingName.trim() ||
      !destinationLocation.streetAddress.trim() ||
      !destinationLocation.city.trim() ||
      !destinationLocation.state.trim() ||
      !destinationLocation.zipCode.trim()
    ) {
      alert("Please ensure all Destination Location fields are filled out");
      setActiveStep(1);
      return;
    }

    setShowLoadingModal(true);

    const formData = new FormData();

    Object.keys(formState).forEach((key) => {
      if (typeof formState[key] === "object" && formState[key] !== null) {
        formData.append(key, JSON.stringify(formState[key]));
      } else {
        formData.append(key, formState[key]);
      }
    });

    if (termsFile) {
      formData.append("termsFile", termsFile);
    }

    try {
      const response = await axios.post(`${url}/tripslips`, formData, {
        headers: {
          ...config.headers,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowLoadingModal(false);

      if (response.status === 201) {
        setTripSlipId(response.data._id);
        setShowSuccessModal(true);
      }
    } catch (error) {
      setShowLoadingModal(false);
      setShowErrorModal(true);
      console.error("Error submitting trip slip:", error);
    }
  };

  const handleClose = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    if (tripSlipId) {
      navigate(`/view-tripslips`);
    }
  };

  const handleView = () => {
    setShowSuccessModal(false);
    navigate(`/view-trip-slip/${tripSlipId}`);
  };

  const toggleDatePicker = () => {
    setDatePickerOpen(!datePickerOpen);
  };

  // Updated steps to reflect the new layout
  const steps = ["Trip Details", "Location Information", "Confirm"];

  const goBack = () => {
    window.location.reload();
  };

  return (
    <Box sx={{ px: 4, py: 3, maxWidth: "1200px", mx: "auto" }}>
      <Button onClick={() => goBack()} sx={{ mb: 2 }}>
        Go Back
      </Button>

      <Paper elevation={3} sx={{ p: 0 }}>
        <Typography variant="h4" sx={{ mb: 4, px: 4, pt: 3 }}>
          Create A TripSlip
        </Typography>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit}>
          {activeStep === 0 && (
            <Grid container spacing={3} sx={{ p: 3 }}>
              <Grid item xs={12}>
                <Paper elevation={1} sx={{ p: 3, mb: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Trip Information
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        selected={formState.tripSlipDate}
                        onChange={(date) =>
                          setFormState({ ...formState, tripSlipDate: date })
                        }
                        open={datePickerOpen}
                        onClickOutside={() => setDatePickerOpen(false)}
                        placeholderText="Select trip date"
                        customInput={
                          <TextField
                            label="Trip Date"
                            variant="outlined"
                            fullWidth
                            required
                            error={formErrors.tripSlipDate}
                            helperText={
                              formErrors.tripSlipDate
                                ? "Trip date is required"
                                : ""
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={toggleDatePicker}
                                    edge="end"
                                  >
                                    <CalendarTodayIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Trip Name"
                        name="tripName"
                        value={formState.tripName}
                        onChange={handleInputChange}
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.tripName}
                        helperText={
                          formErrors.tripName ? "Trip name is required" : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Cost Per Student"
                        name="cost"
                        value={formState.cost}
                        onChange={handleInputChange}
                        fullWidth
                        variant="outlined"
                        inputProps={{ inputMode: "numeric", min: 0 }}
                        InputProps={{
                          startAdornment: <span>$</span>,
                        }}
                        onBlur={(e) => {
                          // If the field is empty when leaving, set it to 0
                          if (
                            e.target.value === "" ||
                            parseFloat(e.target.value) === 0
                          ) {
                            setFormState({ ...formState, cost: 0 });
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label="Notes"
                        name="notes"
                        value={formState.notes}
                        onChange={handleInputChange}
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        placeholder="Optional notes for the trip"
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper elevation={1} sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    Optional Details
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">
                        Upload Terms & Conditions (PDF only)
                      </Typography>
                      <input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) => setTermsFile(e.target.files[0])}
                        style={{ width: "100%", padding: "10px 0" }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeStep === 1 && (
            <Grid container spacing={3} sx={{ p: 3 }}>
              {/* Departure Location */}
              <Grid item xs={12} md={6}>
                <Paper elevation={1} sx={{ p: 3, height: "100%" }}>
                  <Typography variant="h6" gutterBottom>
                    Departure Location
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        label="Building Name"
                        name="buildingName"
                        value={formState.departureLocation.buildingName}
                        onChange={(e) =>
                          handleLocationChange(e, "departureLocation")
                        }
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.departureLocation.buildingName}
                        helperText={
                          formErrors.departureLocation.buildingName
                            ? "Building name is required"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Street Address"
                        name="streetAddress"
                        value={formState.departureLocation.streetAddress}
                        onChange={(e) =>
                          handleLocationChange(e, "departureLocation")
                        }
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.departureLocation.streetAddress}
                        helperText={
                          formErrors.departureLocation.streetAddress
                            ? "Street address is required"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="City"
                        name="city"
                        value={formState.departureLocation.city}
                        onChange={(e) =>
                          handleLocationChange(e, "departureLocation")
                        }
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.departureLocation.city}
                        helperText={
                          formErrors.departureLocation.city
                            ? "City is required"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="State"
                        name="state"
                        value={formState.departureLocation.state}
                        onChange={(e) =>
                          handleLocationChange(e, "departureLocation")
                        }
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.departureLocation.state}
                        helperText={
                          formErrors.departureLocation.state
                            ? "State is required"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Zipcode"
                        name="zipCode"
                        value={formState.departureLocation.zipCode}
                        onChange={(e) =>
                          handleLocationChange(e, "departureLocation")
                        }
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.departureLocation.zipCode}
                        helperText={
                          formErrors.departureLocation.zipCode
                            ? "Zipcode is required"
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Destination Location */}
              <Grid item xs={12} md={6}>
                <Paper elevation={1} sx={{ p: 3, height: "100%" }}>
                  <Typography variant="h6" gutterBottom>
                    Destination Location
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        label="Building Name"
                        name="buildingName"
                        value={formState.destinationLocation.buildingName}
                        onChange={(e) =>
                          handleLocationChange(e, "destinationLocation")
                        }
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.destinationLocation.buildingName}
                        helperText={
                          formErrors.destinationLocation.buildingName
                            ? "Building name is required"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Street Address"
                        name="streetAddress"
                        value={formState.destinationLocation.streetAddress}
                        onChange={(e) =>
                          handleLocationChange(e, "destinationLocation")
                        }
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.destinationLocation.streetAddress}
                        helperText={
                          formErrors.destinationLocation.streetAddress
                            ? "Street address is required"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="City"
                        name="city"
                        value={formState.destinationLocation.city}
                        onChange={(e) =>
                          handleLocationChange(e, "destinationLocation")
                        }
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.destinationLocation.city}
                        helperText={
                          formErrors.destinationLocation.city
                            ? "City is required"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="State"
                        name="state"
                        value={formState.destinationLocation.state}
                        onChange={(e) =>
                          handleLocationChange(e, "destinationLocation")
                        }
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.destinationLocation.state}
                        helperText={
                          formErrors.destinationLocation.state
                            ? "State is required"
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label="Zipcode"
                        name="zipCode"
                        value={formState.destinationLocation.zipCode}
                        onChange={(e) =>
                          handleLocationChange(e, "destinationLocation")
                        }
                        fullWidth
                        variant="outlined"
                        required
                        error={formErrors.destinationLocation.zipCode}
                        helperText={
                          formErrors.destinationLocation.zipCode
                            ? "Zipcode is required"
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}

          {activeStep === 2 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                mt: 4,
              }}
            >
              <Grid container spacing={3} sx={{ p: 3 }}>
                <Grid item xs={12}>
                  <Paper elevation={1} sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                      Confirm Details
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        Trip Information
                      </Typography>
                      <Typography variant="body1">
                        Trip Name: {formState.tripName}
                      </Typography>
                      <Typography variant="body1">
                        Trip Slip Date:{" "}
                        {formState.tripSlipDate
                          ? formState.tripSlipDate.toLocaleDateString()
                          : "No date selected"}
                      </Typography>
                      <Typography variant="body1">
                        Cost: ${formState.cost}
                      </Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        Departure Location
                      </Typography>
                      <Typography variant="body1">
                        {`${formState.departureLocation.buildingName}, ${formState.departureLocation.streetAddress}, ${formState.departureLocation.city}, ${formState.departureLocation.state} ${formState.departureLocation.zipCode}`}
                      </Typography>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "bold" }}
                      >
                        Destination Location
                      </Typography>
                      <Typography variant="body1">
                        {`${formState.destinationLocation.buildingName}, ${formState.destinationLocation.streetAddress}, ${formState.destinationLocation.city}, ${formState.destinationLocation.state} ${formState.destinationLocation.zipCode}`}
                      </Typography>
                    </Box>

                    {formState.notes && (
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold" }}
                        >
                          Notes
                        </Typography>
                        <Typography variant="body1">
                          {formState.notes}
                        </Typography>
                      </Box>
                    )}

                    {termsFile && (
                      <Box sx={{ mt: 2 }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold" }}
                        >
                          Terms & Conditions
                        </Typography>
                        <Typography variant="body1">
                          File Attached: {termsFile.name}
                        </Typography>
                      </Box>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "space-between", m: 4 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
            >
              {activeStep === steps.length - 1 ? "Create Trip Slip" : "Next"}
            </Button>
          </Box>
        </form>
      </Paper>

      {showSuccessModal && (
        <SuccessModal onClose={handleClose} onView={handleView} />
      )}
      {showLoadingModal && <LoadingModal />}
      {showErrorModal && <ErrorModal onClose={handleClose} />}
    </Box>
  );
};

export default CreateTripSlipForm;
