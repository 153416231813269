import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Grid,
  Box,
  Chip,
  Divider,
  Button,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Alert,
  Breadcrumbs,
  Link,
  Tooltip,
} from '@mui/material';
import {
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarTodayIcon,
  Person as PersonIcon,
  Group as GroupIcon,
  School as SchoolIcon,
  Description as DescriptionIcon,
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Print as PrintIcon,
  Email as EmailIcon,
  NavigateNext as NavigateNextIcon,
  Pending as PendingIcon,
} from '@mui/icons-material';
import { bookingService } from '../../../apiCalls/vendorServices';

 // Convert 24h time to 12h format
 const formatTime = (time24h) => {
  if (!time24h) return 'N/A';
  
  // Check if time already has AM/PM
  if (time24h.toLowerCase().includes('am') || time24h.toLowerCase().includes('pm')) {
    return time24h;
  }
  
  try {
    // Parse hour and minute from 24h format
    const [hour, minute] = time24h.split(':').map(Number);
    
    // Determine period
    const period = hour >= 12 ? 'PM' : 'AM';
    
    // Convert hour to 12h format
    const hour12 = hour % 12 || 12; // 0 should be displayed as 12
    
    // Return formatted time
    return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
  } catch (error) {
    return time24h;
  }
};

export const formatTimeSlot = (timeSlot) => {
  if (!timeSlot) return 'N/A - N/A';
  
  // Handle different timeSlot formats
  if (typeof timeSlot === 'string') {
    // If it's already in the format "HH:MM - HH:MM"
    if (timeSlot.includes(' - ')) {
      const [startTime, endTime] = timeSlot.split(' - ');
      return `${formatTime(startTime)} - ${formatTime(endTime)}`;
    }
    return timeSlot;
  }
  
  // If it's an object with startTime and endTime properties
  if (timeSlot.startTime && timeSlot.endTime) {
    return `${formatTime(timeSlot.startTime)} - ${formatTime(timeSlot.endTime)}`;
  }
  
  return 'N/A - N/A';
};
const BookingDetails = ({ data, setData, refreshData }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [booking, setBooking] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [actionType, setActionType] = useState('');

  useEffect(() => {
    const fetchBookingDetails = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        // First check if we already have this booking in our data prop
        if (data && data.allBookings) {
          const foundBooking = data.allBookings.find(b => b._id === id);
          if (foundBooking) {
            setBooking(foundBooking);
            setIsLoading(false);
            return;
          }
        }
        
        // If not found in existing data, fetch from API
        const bookingData = await bookingService.getBookingTripInfo(id);
        if (bookingData) {
          setBooking(bookingData);
        } else {
          setError('Booking not found');
        }
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching booking details:', err);
        setError('Failed to load booking details. Please try again later.');
        setIsLoading(false);
      }
    };

    if (id) {
      fetchBookingDetails();
    }
  }, [id, data]);

  // Handle opening the confirmation dialog
  const handleActionClick = (action) => {
    setActionType(action);
    setDialogOpen(true);
  };

  // Handle closing the dialog
  const handleDialogClose = () => {
    setDialogOpen(false);
    setActionType('');
  };

  // Handle confirming the action
  const handleConfirmAction = async () => {
    try {
      setIsLoading(true);
      
      let action;
      let newStatus;
      
      switch(actionType) {
        case 'confirm':
          action = 'approve';
          newStatus = 'confirmed';
          break;
        case 'cancel':
          action = 'deny';
          newStatus = 'canceled';
          break;
        case 'pending':
          action = 'pending';
          newStatus = 'pending';
          break;
        default:
          action = '';
          newStatus = booking.status;
      }
      
      if (action) {
        await bookingService.submitBookingAction(id, action);
        
        // Update local state
        setBooking(prev => ({
          ...prev,
          status: newStatus,
          statusHistory: [
            ...(prev.statusHistory || []),
            {
              status: newStatus,
              timestamp: new Date().toISOString()
            }
          ]
        }));
        
        // Refresh parent data if needed
        if (refreshData) {
          refreshData();
        }
      }
      
      setIsLoading(false);
      handleDialogClose();
      
    } catch (error) {
      console.error(`Error updating booking status:`, error);
      setError(`Failed to update booking status. Please try again later.`);
      setIsLoading(false);
      handleDialogClose();
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    
    // Parse the date ensuring it's treated as a local date
    const dateStr = new Date(dateString).toISOString().split('T')[0];
    const date = new Date(dateStr + 'T00:00:00');
    
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  

 

  // Get organizer name from booking
  const getOrganizerName = (booking) => {
    if (!booking) return 'Unknown';
    
    
    // If booking has organizer first and last name
    if (booking.organizerId) {
      return `${booking.organizerId}`;
    }
    
    return 'Unknown Organization';
  };

  // Status configuration
  const statusConfig = {
    confirmed: { color: 'success', icon: <CheckCircleIcon /> },
    pending: { color: 'warning', icon: <PendingIcon /> },
    canceled: { color: 'error', icon: <CancelIcon /> }
  };

  // All bookings can now have their status changed, regardless of current status
  const canChangeStatus = true;
  
  // Handle printing
  const handlePrint = () => {
    window.print();
  };

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ py: 4, textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading booking details...
        </Typography>
      </Container>
    );
  }

  if (error || !booking) {
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error || 'Booking not found'}
        </Alert>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Container>
    );
  }

  // Status label and color
  const statusLower = booking.status.toLowerCase();
  const statusColor = statusConfig[statusLower]?.color || 'default';
  const StatusIcon = statusConfig[statusLower]?.icon || null;

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Breadcrumbs Navigation */}
      <Breadcrumbs 
        separator={<NavigateNextIcon fontSize="small" />} 
        aria-label="breadcrumb"
        sx={{ mb: 3 }}
      >
        <Link 
          color="inherit" 
          href="#" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/vendor-dashboard');
          }}
        >
          Dashboard
        </Link>
        <Link 
          color="inherit" 
          href="#" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/view-bookings');
          }}
        >
          Bookings
        </Link>
        <Typography color="text.primary">Booking #{booking.tripSlipCode?.slice(-4) || id.slice(-4)}</Typography>
      </Breadcrumbs>

      {/* Page Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display="flex" alignItems="center">
          <IconButton 
            onClick={() => navigate(-1)} 
            sx={{ mr: 2 }}
            aria-label="go back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" component="h1">
            Booking Details
          </Typography>
        </Box>
        <Box>
          <Tooltip title="Print Booking">
            <IconButton onClick={handlePrint} sx={{ mx: 1 }}>
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container spacing={3}>
        {/* Left Column - Booking Info */}
        <Grid item xs={12} md={8}>
          <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
            {/* Booking Header */}
            <Box 
              display="flex" 
              justifyContent="space-between" 
              alignItems="flex-start"
              mb={2}
            >
              <Box>
                <Typography variant="h5" gutterBottom>
                  Booking #{booking.tripSlipCode?.slice(-4) || id.slice(-4)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Created on {new Date(booking.createdAt || Date.now()).toLocaleDateString()}
                </Typography>
              </Box>
              <Chip 
                label={booking.status}
                color={statusColor}
                icon={StatusIcon}
                sx={{ fontWeight: 'bold' }}
              />
            </Box>
            
            <Divider sx={{ my: 2 }} />
            
            {/* Booking Details */}
            <Grid container spacing={3}>
              {/* Date & Time */}
              <Grid item xs={12} sm={6}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start',
                    mb: 2
                  }}
                >
                  <CalendarTodayIcon sx={{ mr: 2, color: 'primary.main' }} />
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Date
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      {formatDate(booking.bookingDate)}
                    </Typography>
                  </Box>
                </Box>
                
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start'
                  }}
                >
                  <AccessTimeIcon sx={{ mr: 2, color: 'primary.main' }} />
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Time Slot
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      {formatTimeSlot(booking.timeSlot)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              
              {/* Group Details */}
              <Grid item xs={12} sm={6}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start',
                    mb: 2
                  }}
                >
                  <GroupIcon sx={{ mr: 2, color: 'primary.main' }} />
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Group Size
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      {booking.numberOfPeople} people
                    </Typography>
                  </Box>
                </Box>
                
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start'
                  }}
                >
                  <SchoolIcon sx={{ mr: 2, color: 'primary.main' }} />
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary">
                      Organization
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      {getOrganizerName(booking)}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            
            {/* Notes Section */}
            {booking.notes && (
              <>
                <Divider sx={{ my: 3 }} />
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'flex-start'
                  }}
                >
                  <DescriptionIcon sx={{ mr: 2, color: 'primary.main' }} />
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                      Notes
                    </Typography>
                    <Typography variant="body1">
                      {booking.notes}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            
            {/* Academic Info */}
            {(booking.grades?.length > 0 || booking.subjects?.length > 0) && (
              <>
                <Divider sx={{ my: 3 }} />
                <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                  Academic Information
                </Typography>
                
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {booking.grades?.length > 0 && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Grade Levels:
                      </Typography>
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        {booking.grades.map((grade) => (
                          <Chip 
                            key={grade} 
                            label={grade} 
                            size="small" 
                            variant="outlined"
                          />
                        ))}
                      </Box>
                    </Grid>
                  )}
                  
                  {booking.subjects?.length > 0 && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        Subjects:
                      </Typography>
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        {booking.subjects.map((subject) => (
                          <Chip 
                            key={subject} 
                            label={subject} 
                            size="small" 
                            variant="outlined"
                          />
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Paper>
          
          {/* Contact Information */}
          {(booking.contactPerson || booking.organizer) && (
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Contact Information
              </Typography>
              <Divider sx={{ mb: 2 }} />
              
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'flex-start',
                  mb: 2
                }}
              >
                <PersonIcon sx={{ mr: 2, color: 'primary.main' }} />
                <Box>
                  <Typography variant="subtitle2" color="text.secondary">
                    Contact Person
                  </Typography>
                  <Typography variant="body1" fontWeight="medium">
                    {booking.contactPerson?.name || 
                     (booking.organizer ? `${booking.organizer.firstName} ${booking.organizer.lastName}` : 'N/A')}
                  </Typography>
                </Box>
              </Box>
              
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center'
                    }}
                  >
                    <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                      Email:
                    </Typography>
                    <Typography variant="body2">
                      {booking.contactPerson?.email || booking.organizer?.email || booking.organizerEmail || 'N/A'}
                    </Typography>
                    {(booking.contactPerson?.email || booking.organizer?.email || booking.organizerEmail) && (
                      <IconButton 
                        size="small" 
                        href={`mailto:${booking.contactPerson?.email || booking.organizer?.email || booking.organizerEmail}`}
                        sx={{ ml: 1 }}
                      >
                        <EmailIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center'
                    }}
                  >
                    <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                      Phone:
                    </Typography>
                    <Typography variant="body2">
                      {booking.contactPerson?.phone || booking.organizer?.contactPhoneNumber || booking.organizerContactPhone || 'N/A'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          )}
        </Grid>
        
        {/* Right Column - Actions & Status */}
        <Grid item xs={12} md={4}>
          {/* Booking Actions Card */}
          <Card elevation={2} sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Booking Actions
              </Typography>
              <Divider sx={{ mb: 2 }} />
              
              {canChangeStatus && (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    startIcon={<CheckCircleIcon />}
                    onClick={() => handleActionClick('confirm')}
                    sx={{ mb: 2 }}
                    disabled={statusLower === 'confirmed'}
                  >
                    Confirm Booking
                  </Button>
                  
                  <Button
                    variant="outlined"
                    color="warning"
                    fullWidth
                    startIcon={<PendingIcon />}
                    onClick={() => handleActionClick('pending')}
                    sx={{ mb: 2 }}
                    disabled={statusLower === 'pending'}
                  >
                    Mark as Pending
                  </Button>
                  
                  <Button
                    variant="outlined"
                    color="error"
                    fullWidth
                    startIcon={<CancelIcon />}
                    onClick={() => handleActionClick('cancel')}
                    sx={{ mb: 2 }}
                    disabled={statusLower === 'canceled'}
                  >
                    Cancel Booking
                  </Button>
                  
                  <Divider sx={{ my: 2 }} />
                </>
              )}
              
              <Button
                variant="outlined"
                fullWidth
                startIcon={<PrintIcon />}
                onClick={handlePrint}
                sx={{ mb: 2 }}
              >
                Print Details
              </Button>
            </CardContent>
          </Card>
          
          {/* Booking Status Card */}
          <Card elevation={2}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Booking Status
              </Typography>
              <Divider sx={{ mb: 2 }} />
              
              <Box 
                sx={{ 
                  p: 2, 
                  borderRadius: 1,
                  bgcolor: `${statusColor}.light`,
                  color: `${statusColor}.dark`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center'
                }}
              >
                {StatusIcon && (
                  <Box sx={{ fontSize: '2rem', mb: 1 }}>
                    {StatusIcon}
                  </Box>
                )}
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {booking.status}
                </Typography>
                <Typography variant="body2">
                  {statusLower === 'confirmed' && 'This booking has been confirmed.'}
                  {statusLower === 'pending' && 'This booking is awaiting confirmation.'}
                  {statusLower === 'canceled' && 'This booking has been canceled.'}
                </Typography>
              </Box>
              
              {/* Status Timeline */}
              {booking.statusHistory && booking.statusHistory.length > 0 && (
                <>
                  <Typography variant="subtitle2" sx={{ mt: 3, mb: 1 }}>
                    Status History
                  </Typography>
                  <Box sx={{ pl: 2 }}>
                    {booking.statusHistory.map((status, index) => (
                      <Box 
                        key={index}
                        sx={{ 
                          display: 'flex',
                          mb: 1,
                          position: 'relative',
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: -10,
                            top: 10,
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            bgcolor: 'primary.main',
                          },
                          ...(index < booking.statusHistory.length - 1 ? {
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              left: -6,
                              top: 18,
                              width: 1,
                              height: 20,
                              bgcolor: 'divider',
                            }
                          } : {})
                        }}
                      >
                        <Box sx={{ ml: 1 }}>
                          <Typography variant="body2" fontWeight="medium">
                            {status.status}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {new Date(status.timestamp).toLocaleString()}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Confirmation Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {actionType === 'confirm' 
            ? 'Confirm Booking' 
            : actionType === 'cancel' 
              ? 'Cancel Booking' 
              : 'Mark Booking as Pending'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {actionType === 'confirm'
              ? 'Are you sure you want to confirm this booking? This will update the status to confirmed.'
              : actionType === 'cancel'
                ? 'Are you sure you want to cancel this booking? This action cannot be undone.'
                : 'Are you sure you want to mark this booking as pending? This will update the status to pending.'}
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2">Booking Details:</Typography>
            <Typography variant="body2">
              Date: {formatDate(booking.bookingDate)}
            </Typography>
            <Typography variant="body2">
              Time: {formatTimeSlot(booking.timeSlot)}
            </Typography>
            <Typography variant="body2">
              Organization: {getOrganizerName(booking)}
            </Typography>
            <Typography variant="body2">
              People: {booking.numberOfPeople}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="inherit">
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmAction} 
            color={
              actionType === 'confirm' 
                ? 'success' 
                : actionType === 'cancel' 
                  ? 'error' 
                  : 'warning'
            } 
            variant="contained"
            autoFocus
          >
            {actionType === 'confirm' 
              ? 'Confirm Booking' 
              : actionType === 'cancel' 
                ? 'Cancel Booking' 
                : 'Mark as Pending'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BookingDetails;