import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
  Paper,
  Box,
  Card,
  CardContent,
  Divider,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Edit,
  Save,
  Cancel,
  Phone,
  Email,
  Person,
  Badge,
} from "@mui/icons-material";

const Profile = () => {
  // State for user data and form handling
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactPhoneNumber: "",
    role: "",
  });
  const [editData, setEditData] = useState({});
  const [newPassword, setNewPassword] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  // Dialog states
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const userId = sessionStorage.getItem("userId");
  const url = process.env.REACT_APP_SERVICE_URL;

  // Fetch user data on component mount
  useEffect(() => {
    if (userId) {
      axios
        .get(`${url}/users/${userId}`)
        .then((response) => {
          setUserData(response.data);
          setEditData(response.data); // Initialize edit data with current data
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setResponseHeader("Error");
          setResponseMessage("Failed to load your profile information.");
          setShowResponseModal(true);
        });
    }
  }, [userId, url]);

  // Toggle between view and edit modes
  const toggleEditMode = () => {
    if (isEditMode) {
      // If canceling edit, reset edit data to current user data
      setEditData({...userData});
      setNewPassword("");
    } else {
      // If entering edit mode, ensure edit data is up to date
      setEditData({...userData});
    }
    setIsEditMode(!isEditMode);
  };

  // Handle field changes in edit mode
  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle password changes
  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  // Toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    setShowConfirmationDialog(true);
  };

  // Handle confirmation of update
  const handleConfirmUpdate = () => {
    const updatedData = { ...editData };
    if (newPassword) {
      updatedData.password = newPassword;
    }

    axios
      .put(`${url}/users`, updatedData)
      .then((response) => {
        if (response.status === 200) {
          setUserData(editData); // Update the main user data
          setResponseHeader("Success");
          setResponseMessage("Your profile has been updated successfully.");
          setShowResponseModal(true);
          setNewPassword("");
          setIsEditMode(false); // Return to view mode
        }
      })
      .catch((error) => {
        setResponseHeader("Error");
        setResponseMessage("An error occurred while updating your profile.");
        setShowResponseModal(true);
      })
      .finally(() => {
        setShowConfirmationDialog(false);
      });
  };

  // Close response modal
  const handleCloseModal = () => {
    setShowResponseModal(false);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 3, 
          borderRadius: 2,
          background: "linear-gradient(to right bottom, #ffffff, #f8f9fa)"
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            {userData.role
              ? `${userData.role.charAt(0).toUpperCase() + userData.role.slice(1).toLowerCase()} Profile`
              : "User Profile"}
          </Typography>
          
          <Tooltip title={isEditMode ? "Cancel Edit" : "Edit Profile"}>
            <IconButton
              color={isEditMode ? "error" : "primary"}
              onClick={toggleEditMode}
              size="large"
              sx={{ border: 1, borderColor: isEditMode ? "error.main" : "primary.main" }}
            >
              {isEditMode ? <Cancel /> : <Edit />}
            </IconButton>
          </Tooltip>
        </Box>

        <Chip
          icon={<Badge />}
          label={userData.role ? `${userData.role.toUpperCase()}` : "USER"}
          color="primary"
          variant="outlined"
          sx={{ mb: 3 }}
        />

        <Divider sx={{ mb: 4 }} />

        {isEditMode ? (
          // EDIT MODE
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  name="firstName"
                  value={editData.firstName || ""}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person color="primary" />
                      </InputAdornment>
                    ),
                    sx: { backgroundColor: "white" }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={editData.lastName || ""}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person color="primary" />
                      </InputAdornment>
                    ),
                    sx: { backgroundColor: "white" }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={editData.email || ""}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email color="primary" />
                      </InputAdornment>
                    ),
                    sx: { backgroundColor: "white" }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Contact Phone Number"
                  name="contactPhoneNumber"
                  type="tel"
                  value={editData.contactPhoneNumber || ""}
                  onChange={handleChange}
                  fullWidth
                  required
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone color="primary" />
                      </InputAdornment>
                    ),
                    sx: { backgroundColor: "white" }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="New Password"
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={handlePasswordChange}
                  placeholder="Leave blank to keep current password"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: { backgroundColor: "white" }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<Save />}
                    sx={{ flex: 1 }}
                  >
                    Save Changes
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    size="large"
                    startIcon={<Cancel />}
                    onClick={toggleEditMode}
                    sx={{ flex: 1 }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        ) : (
          // VIEW MODE
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom sx={{ color: "primary.main", borderBottom: 1, borderColor: "divider", pb: 1 }}>
                    Personal Information
                  </Typography>
                  
                  <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                    <Person color="primary" sx={{ mr: 2 }} />
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Full Name
                      </Typography>
                      <Typography variant="h6">
                        {userData.firstName} {userData.lastName}
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                    <Badge color="primary" sx={{ mr: 2 }} />
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Role
                      </Typography>
                      <Typography variant="h6">
                        {userData.role
                          ? userData.role.charAt(0).toUpperCase() +
                            userData.role.slice(1).toLowerCase()
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Card variant="outlined" sx={{ height: "100%" }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom sx={{ color: "primary.main", borderBottom: 1, borderColor: "divider", pb: 1 }}>
                    Contact Information
                  </Typography>
                  
                  <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                    <Email color="primary" sx={{ mr: 2 }} />
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Email Address
                      </Typography>
                      <Typography variant="h6">
                        {userData.email || "-"}
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                    <Phone color="primary" sx={{ mr: 2 }} />
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        Phone Number
                      </Typography>
                      <Typography variant="h6">
                        {userData.contactPhoneNumber || "-"}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<Edit />}
                  onClick={toggleEditMode}
                >
                  Edit Profile
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Paper>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to update your profile information?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowConfirmationDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmUpdate} 
            color="primary" 
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Response Modal */}
      <Dialog open={showResponseModal} onClose={handleCloseModal}>
        <DialogTitle>{responseHeader}</DialogTitle>
        <DialogContent>
          <Typography>{responseMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Profile;