/* eslint-disable react-hooks/exhaustive-deps */
// Function to ensure consistent time format across all days
import React, { useState, useEffect } from 'react';
import {
Box,
Container,
Typography,
Button,
Card,
CardMedia,
CardContent,
TextField,
Dialog,
DialogActions,
DialogContent,
DialogContentText,
InputLabel,
Select,
MenuItem,
Grid,
CircularProgress,
Accordion,
AccordionSummary,
AccordionDetails,
Checkbox,
FormControlLabel,
Fade,
Divider,
Paper,
Chip,
FormControl,
IconButton,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { ExpandMore as ExpandMoreIcon, Edit as EditIcon, Save as SaveIcon, Cancel as CancelIcon, Delete } from '@mui/icons-material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import axios from 'axios';
import dayjs from 'dayjs';
import ImageCarousel from '../../ImageCarousel';
import ViewWeeklyAvailability from '../../WeeklyAvailability/ViewWeeklyAvailability';

const normalizeWeeklyAvailability = (weeklyAvailability) => {
  if (!weeklyAvailability) return {};
  
  // Create a copy to avoid modifying the original
  const normalized = JSON.parse(JSON.stringify(weeklyAvailability));
  
  // Process each day
  for (const day in normalized) {
    const availability = normalized[day];
    
    // Skip if closed or no availability data
    if (availability.isClosed || !availability) continue;
    
    // Convert time values to consistent string format
    if (availability.startTime) {
      // For full ISO strings
      if (typeof availability.startTime === 'string' && availability.startTime.includes('T')) {
        try {
          const date = new Date(availability.startTime);
          if (!isNaN(date)) {
            availability.startTime = date.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
              hour12: true
            });
          }
        } catch (e) {
          console.error(`Error processing startTime for ${day}:`, e);
        }
      }
      // For dayjs objects
      else if (typeof availability.startTime === 'object' && availability.startTime?.format) {
        availability.startTime = availability.startTime.format('h:mm A');
      }
    }
    
    if (availability.endTime) {
      // For full ISO strings
      if (typeof availability.endTime === 'string' && availability.endTime.includes('T')) {
        try {
          const date = new Date(availability.endTime);
          if (!isNaN(date)) {
            availability.endTime = date.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: '2-digit',
              hour12: true
            });
          }
        } catch (e) {
          console.error(`Error processing endTime for ${day}:`, e);
        }
      }
      // For dayjs objects
      else if (typeof availability.endTime === 'object' && availability.endTime?.format) {
        availability.endTime = availability.endTime.format('h:mm A');
      }
    }
  }
  
  return normalized;
};

const CombinedVendorInformation = () => {
const url = process.env.REACT_APP_SERVICE_URL;
const [isEditMode, setIsEditMode] = useState(false);
const [originalVendorInfo, setOriginalVendorInfo] = useState(null);
const [vendorInfo, setVendorInfo] = useState({
  name: '',
  type: '',
  businessDescription: '',
  businessEmail: '',
  businessPhoneNumber: '',
  venueCapacity: '',
  customDates: [], // Add empty array for customDates
  businessLocation: {
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
  },
  weeklyAvailability: {
    Monday: { startTime: null, endTime: null, isClosed: false },
    Tuesday: { startTime: null, endTime: null, isClosed: false },
    Wednesday: { startTime: null, endTime: null, isClosed: false },
    Thursday: { startTime: null, endTime: null, isClosed: false },
    Friday: { startTime: null, endTime: null, isClosed: false },
    Saturday: { startTime: null, endTime: null, isClosed: false },
    Sunday: { startTime: null, endTime: null, isClosed: false },
  },
});
const [logoFile, setLogoFile] = useState(null);
const [businessMediaFiles, setBusinessMediaFiles] = useState([]);
const [hasChanges, setHasChanges] = useState(false);
const [openModal, setOpenModal] = useState(false);
const [loading, setLoading] = useState(false);
const vendorId = sessionStorage.getItem('vendorId');

const fetchVendorInfo = async () => {
  if (vendorId) {
    await axios.get(`${url}/vendorinformation/${vendorId}`)
      .then(response => {
        if (Object.keys(response.data).length) {
          // Process weeklyAvailability times for consistency
          const data = { ...response.data };
          
          if (data.weeklyAvailability) {
            data.weeklyAvailability = normalizeWeeklyAvailability(data.weeklyAvailability);
          }
          
          setVendorInfo(data);
          setOriginalVendorInfo(JSON.parse(JSON.stringify(data))); // Deep copy
        } else {
          setVendorInfo(null);
          setOriginalVendorInfo(null);
          setIsEditMode(true); // Auto-switch to edit mode if no info exists
        }
      })
      .catch(error => {
        console.error('Error fetching vendor information:', error);
        setVendorInfo(null);
        setOriginalVendorInfo(null);
        setIsEditMode(true); // Auto-switch to edit mode if error
      });
  } else {
    setVendorInfo(null);
    setOriginalVendorInfo(null);
    setIsEditMode(true); // Auto-switch to edit mode if no vendorId
  }
};

useEffect(() => {
  fetchVendorInfo();
}, [url, vendorId]);


const handleSubmit = async (event) => {
  if (event) event.preventDefault();
  setLoading(true);

  const userId = sessionStorage.getItem('userId');
  const formData = new FormData();

  formData.append('userId', userId);
  
  // Format phone number: remove +1 prefix if it exists - server adds it back
  if (vendorInfo.businessPhoneNumber && vendorInfo.businessPhoneNumber.startsWith('+1')) {
    vendorInfo.businessPhoneNumber = vendorInfo.businessPhoneNumber.substring(2);
  }
  
  // Log what we're about to send for debugging
  console.log('Submitting vendor info:', vendorInfo);

  // Add all simple fields directly
  formData.append('name', vendorInfo.name || '');
  formData.append('type', vendorInfo.type || '');
  formData.append('businessDescription', vendorInfo.businessDescription || '');
  formData.append('businessEmail', vendorInfo.businessEmail || '');
  formData.append('businessPhoneNumber', vendorInfo.businessPhoneNumber?.replace('+1', '') || '');
  formData.append('venueCapacity', vendorInfo.venueCapacity || '');
  
  // For objects that the server explicitly parses, stringify them
  // Make sure weeklyAvailability is normalized before sending
  const normalizedAvailability = normalizeWeeklyAvailability(vendorInfo.weeklyAvailability || {});
  formData.append('businessLocation', JSON.stringify(vendorInfo.businessLocation || {}));
  formData.append('weeklyAvailability', JSON.stringify(normalizedAvailability));
  
  // CRITICAL FIX: Don't include customDates in the formData at all for edit mode
  // The server code shows it doesn't parse customDates like it does with other objects
  if (!vendorId) {
    // For new records, we need an empty array but we'll omit it
    // because the server seems to have a default value or schema setting
  }

  if (logoFile) {
    formData.append('logoFile', logoFile);
  }
  
  businessMediaFiles.forEach((file) => {
    formData.append(`businessMediaFiles`, file);
  });

  try {
    const response = await axios({
      method: vendorId ? 'put' : 'post',
      url: vendorId ? `${url}/vendorinformation/${vendorId}` : `${url}/vendorinformation`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if (response.status === 201) {
      sessionStorage.setItem('vendorId', response.data._id);
    }
    
    setOpenModal(true);
    fetchVendorInfo(); // Refresh data
    setIsEditMode(false); // Switch back to view mode
    setHasChanges(false);
    
  } catch (error) {
    console.error('Error saving vendor information:', error);
  } finally {
    setLoading(false);
  }
};

const handleInputChange = (event) => {
  const { name, value } = event.target;
  if (name.includes('businessLocation.')) {
    const key = name.split('.')[1];
    setVendorInfo((prev) => ({
      ...prev,
      businessLocation: {
        ...prev.businessLocation,
        [key]: value,
      },
    }));
  } else {
    setVendorInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  setHasChanges(true);
};


const handleClose = () => {
  setOpenModal(false);
};

const handleWeeklyAvailabilityChange = (day, field, value) => {
  if (value) {
    // Store as formatted time string (12-hour format with AM/PM)
    let timeString;
    
    // Handle dayjs objects
    if (typeof value === 'object' && value?.format) {
      timeString = value.format('h:mm A');
    } 
    // Handle Date objects
    else if (value instanceof Date) {
      timeString = value.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    }
    // Handle ISO strings
    else if (typeof value === 'string' && value.includes('T')) {
      const date = new Date(value);
      timeString = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      });
    }
    // Use the value as is if we couldn't convert it
    else {
      timeString = value;
    }
    
    setVendorInfo((prevInfo) => ({
      ...prevInfo,
      weeklyAvailability: {
        ...prevInfo.weeklyAvailability,
        [day]: {
          ...prevInfo.weeklyAvailability[day],
          [field]: timeString,
        },
      },
    }));
    setHasChanges(true);
  }
};

const handleClosedChange = (day) => {
  setVendorInfo((prevInfo) => ({
    ...prevInfo,
    weeklyAvailability: {
      ...prevInfo.weeklyAvailability,
      [day]: {
        ...prevInfo.weeklyAvailability[day],
        isClosed: !prevInfo.weeklyAvailability[day].isClosed,
      },
    },
  }));
  setHasChanges(true);
};

const toggleEditMode = () => {
  if (isEditMode && hasChanges) {
    // If we're exiting edit mode and have changes, confirm first
    if (window.confirm("You have unsaved changes. Discard changes?")) {
      setVendorInfo(JSON.parse(JSON.stringify(originalVendorInfo))); // Reset to original
      setHasChanges(false);
      setIsEditMode(false);
    }
  } else {
    setIsEditMode(!isEditMode);
  }
};

const cancelEdit = () => {
  setVendorInfo(JSON.parse(JSON.stringify(originalVendorInfo))); // Reset to original
  setHasChanges(false);
  setIsEditMode(false);
};

// View mode content
const renderViewMode = () => {
  if (!vendorInfo) {
    return (
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
        <Typography variant="body1">
          No information available. Please update your profile to appear in the TripSlip Vendor Database.
        </Typography>
        <Button 
          variant="contained" 
          sx={{ mt: 2 }} 
          onClick={() => setIsEditMode(true)}
          startIcon={<EditIcon />}
        >
          Add Information
        </Button>
      </Box>
    );
  }

  return (
    <Card raised sx={{ width: '100%', overflow: 'visible' }}>
    {/* Business Logo */}
    {vendorInfo.logo && (
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          height="200"
          image={vendorInfo.logo}
          alt="Business Logo"
          sx={{ objectFit: 'cover' }}
        />
        <Box 
          sx={{ 
            position: 'absolute', 
            bottom: '-20px', 
            right: '20px',
            zIndex: 2
          }}
        >
          <Button 
            variant="contained" 
            color="primary" 
            onClick={toggleEditMode}
            startIcon={<EditIcon />}
            sx={{ boxShadow: 3 }}
          >
            Edit Profile
          </Button>
        </Box>
      </Box>
    )}

    <CardContent sx={{ pt: vendorInfo.logo ? 4 : 2 }}>
      {/* Business Header Section */}
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography gutterBottom variant="h4" component="div" fontWeight="bold">
            {vendorInfo.name}
          </Typography>
          <Chip 
            icon={<BusinessIcon />} 
            label={vendorInfo.type} 
            color="primary" 
            size="large" 
            variant="outlined" 
          />
        </Box>
        
        {/* Edit Button for screens without logo */}
        {!vendorInfo.logo && (
          <Button 
            variant="outlined" 
            color="primary" 
            onClick={toggleEditMode}
            startIcon={<EditIcon />}
          >
            Edit Profile
          </Button>
        )}
      </Box>
      
      <Divider sx={{ mb: 3 }} />
      
      {/* Business Description */}
      <Paper elevation={0} sx={{ p: 2, mb: 3, bgcolor: '#f8f9fa', borderRadius: 2 }}>
        {/* <Typography variant="h6" sx={{ mb: 1 }}>About Our Business</Typography> */}
        <Typography variant="body1" color="text.secondary">
          {vendorInfo.businessDescription}
        </Typography>
      </Paper>
      
      {/* Contact Information Section */}
      <Paper elevation={0} sx={{ p: 2, mb: 3, bgcolor: '#f8f9fa', borderRadius: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              bgcolor: 'primary.main', 
              color: 'white', 
              borderRadius: '50%', 
              width: 36, 
              height: 36 
            }}
          >
            <EmailIcon />
          </Box>
          <Typography variant="subtitle1" sx={{ ml: 2 }}>
            <Box component="span" fontWeight="bold">Email: </Box> 
            {vendorInfo.businessEmail}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              bgcolor: 'primary.main', 
              color: 'white', 
              borderRadius: '50%', 
              width: 36, 
              height: 36 
            }}
          >
            <PhoneIcon />
          </Box>
          <Typography variant="subtitle1" sx={{ ml: 2 }}>
            <Box component="span" fontWeight="bold">Phone: </Box> 
            {vendorInfo.businessPhoneNumber}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              bgcolor: 'primary.main', 
              color: 'white', 
              borderRadius: '50%', 
              width: 36, 
              height: 36 
            }}
          >
            <PeopleIcon />
          </Box>
          <Typography variant="subtitle1" sx={{ ml: 2 }}>
            <Box component="span" fontWeight="bold">Capacity: </Box> 
            {vendorInfo.venueCapacity} people
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              bgcolor: 'primary.main', 
              color: 'white', 
              borderRadius: '50%', 
              width: 36, 
              height: 36 
            }}
          >
            <LocationOnIcon />
          </Box>
          <Typography variant="subtitle1" sx={{ ml: 2 }}>
            <Box component="span" fontWeight="bold">Location: </Box> 
            {`${vendorInfo.businessLocation.streetAddress}, ${vendorInfo.businessLocation.city}, ${vendorInfo.businessLocation.state} ${vendorInfo.businessLocation.zipCode}`}
          </Typography>
        </Box>
      </Paper>
      
      {/* Availability Section */}
      <Paper elevation={0} sx={{ p: 2, mb: 3, bgcolor: '#f8f9fa', borderRadius: 2 }}>
        
        <ViewWeeklyAvailability weeklyAvailability={vendorInfo.weeklyAvailability} />
      </Paper>
      
      {/* Business Photos Section */}
      {vendorInfo.businessMedia && vendorInfo.businessMedia.length > 0 && (
        <Paper elevation={0} sx={{ p: 2, bgcolor: '#f8f9fa', borderRadius: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <PhotoLibraryIcon color="primary" sx={{ mr: 1 }} />
            <Typography variant="h6">Business Photos</Typography>
          </Box>
          <Box sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <ImageCarousel images={vendorInfo.businessMedia || []} />
          </Box>
        </Paper>
      )}
    </CardContent>
  </Card>
  );
};

// Edit mode content
const renderEditMode = () => {
  // Modified handlers for previews without using React hooks
  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const logoPreviewContainer = document.getElementById('logo-preview-container');
      
      // Create or update the logo preview
      if (logoPreviewContainer) {
        // Clear previous preview
        logoPreviewContainer.innerHTML = '';
        
        // Create preview image
        const img = document.createElement('img');
        img.style.maxWidth = '100%';
        img.style.maxHeight = '130px';
        img.style.objectFit = 'contain';
        
        // Create delete button
        const deleteBtn = document.createElement('button');
        deleteBtn.innerHTML = 'Remove';
        deleteBtn.className = 'MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textError MuiButton-sizeSmall MuiButton-textSizeSmall';
        deleteBtn.style.position = 'absolute';
        deleteBtn.style.top = '5px';
        deleteBtn.style.right = '5px';
        deleteBtn.onclick = (evt) => {
          evt.preventDefault();
          logoPreviewContainer.innerHTML = '';
          logoPreviewContainer.style.display = 'none';
          const logoInput = document.getElementById('logo-input');
          if (logoInput) {
            logoInput.value = '';
          }
          // Clear the file from state
          setLogoFile(null);
        };
        
        // Set image source from file
        const reader = new FileReader();
        reader.onload = (e) => {
          img.src = e.target.result;
          logoPreviewContainer.appendChild(img);
          logoPreviewContainer.appendChild(deleteBtn);
          logoPreviewContainer.style.display = 'block';
        };
        reader.readAsDataURL(file);
      }
      
      // Update the logoFile state
      setLogoFile(e.target.files[0]);
      setHasChanges(true);
    }
  };
  
  // Handler for media preview
  const handleMediaUpload = (e) => {
    const newFiles = Array.from(e.target.files);
    if (newFiles.length > 0) {
      // Get existing files and append new ones
      const updatedFiles = [...businessMediaFiles, ...newFiles];
      
      // Update the businessMediaFiles state first - accumulate files instead of replacing
      setBusinessMediaFiles(updatedFiles);
      setHasChanges(true);
      
      // Also update the vendorInfo preview images for immediate UI feedback
      const newPreviewURLs = [];
      
      // Process each new file to create data URLs for preview
      let filesProcessed = 0;
      newFiles.forEach(file => {
        const reader = new FileReader();
        reader.onload = (e) => {
          newPreviewURLs.push(e.target.result);
          filesProcessed++;
          
          // When all files are processed, update the vendorInfo state with the new previews
          if (filesProcessed === newFiles.length) {
            setVendorInfo(prev => ({
              ...prev,
              businessMedia: [...(prev.businessMedia || []), ...newPreviewURLs]
            }));
            
            // Update the preview count
            const previewCount = document.getElementById('media-preview-count');
            if (previewCount) {
              const totalCount = (vendorInfo?.businessMedia?.length || 0) + newFiles.length;
              previewCount.textContent = `Media Previews (${totalCount} files)`;
              previewCount.style.display = 'block';
            }
          }
        };
        reader.readAsDataURL(file);
      });
      
      // Clear the file input to allow selecting the same files again if needed
      const mediaInput = document.getElementById('media-input');
      if (mediaInput) {
        mediaInput.value = '';
      }
    }
  };
  
  return (
    <Box component="form" noValidate>
      <Card sx={{ p: 4, mb: 4 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography variant="h5" component="h1">
              {vendorInfo && vendorInfo.name ? 'Edit Vendor Information' : 'Add Vendor Information'}
            </Typography>
            <Box>
              <Button 
                variant="outlined" 
                color="secondary" 
                onClick={cancelEdit}
                startIcon={<CancelIcon />}
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
                disabled={!hasChanges || loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </Box>
          </Box>
          
          <Divider sx={{ mb: 4 }} />
          
          <Typography variant="h6" sx={{ mb: 2 }}>Basic Information</Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                id="name"
                label="Business Name"
                name="name"
                autoComplete="name"
                onChange={handleInputChange}
                value={vendorInfo?.name || ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Added FormControl Wrapper for Select Component */}
              <FormControl fullWidth>
                <InputLabel id="type-label">Business Type</InputLabel>
                <Select
                  labelId="type-label"
                  id="type-label"
                  name="type"
                  value={vendorInfo?.type || ''}
                  onChange={handleInputChange}
                  label="Business Type"
                >
                  <MenuItem value="Venue">Venue</MenuItem>
                  <MenuItem value="Experience">Experience</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="businessDescription"
                label="Business Description"
                name="businessDescription"
                autoComplete="businessDescription"
                multiline
                rows={4}
                onChange={handleInputChange}
                value={vendorInfo?.businessDescription || ''}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ p: 4, mb: 4 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>Contact Information</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                id="businessEmail"
                label="Business Email"
                name="businessEmail"
                autoComplete="email"
                onChange={handleInputChange}
                value={vendorInfo?.businessEmail || ''}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                id="businessPhoneNumber"
                label="Business Phone Number"
                name="businessPhoneNumber"
                autoComplete="tel"
                onChange={handleInputChange}
                value={vendorInfo?.businessPhoneNumber || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="venueCapacity"
                label="Venue Capacity"
                name="venueCapacity"
                type="number"
                onChange={handleInputChange}
                value={vendorInfo?.venueCapacity || ''}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>Business Location</Typography>
            </Grid>
            
            {vendorInfo && vendorInfo.businessLocation && Object.keys(vendorInfo.businessLocation).map((key) => (
              <Grid item xs={12} md={key === 'streetAddress' ? 12 : 4} key={key}>
                <TextField
                  required
                  fullWidth
                  id={key}
                  label={key === 'zipCode' ? 'ZIP Code' : key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                  name={`businessLocation.${key}`}
                  autoComplete={key}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (key === "zipCode") {
                      // Only allow numeric values, and limit to 5 digits
                      if (/^\d{0,5}$/.test(value)) {
                        handleInputChange(e);
                      }
                    } else {
                      handleInputChange(e);
                    }
                  }}
                  value={vendorInfo.businessLocation[key] || ''}
                  type={key === "zipCode" ? "number" : "text"}
                  inputProps={key === "zipCode" ? { min: 10000, max: 99999, pattern: "[0-9]{5}" } : {}}
                />
              </Grid>
            ))}

          </Grid>
        </CardContent>
      </Card>
      
      <Card sx={{ p: 4, mb: 4 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 2 }}>Media Upload</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Upload Logo</Typography>
              <input 
                type="file" 
                onChange={handleLogoUpload}
                accept="image/*"
                id="logo-input"
                style={{ marginBottom: '16px' }}
              />
              
              {/* Logo Preview Container */}
              <Box 
                id="logo-preview-container" 
                sx={{ 
                  mt: 2, 
                  p: 2, 
                  border: '1px solid #ddd', 
                  borderRadius: '4px',
                  height: '150px',
                  display: vendorInfo?.logo ? 'block' : 'none',
                  position: 'relative',
                  backgroundColor: '#f5f5f5',
                  textAlign: 'center'
                }}
              >
                {vendorInfo?.logo && (
                  <>
                    <img 
                      src={vendorInfo.logo} 
                      alt="Business Logo" 
                      style={{ maxWidth: '100%', maxHeight: '130px', objectFit: 'contain' }} 
                    />
                    <Button
                      variant="text"
                      color="error"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        const container = document.getElementById('logo-preview-container');
                        if (container) {
                          container.innerHTML = '';
                          container.style.display = 'none';
                        }
                        const logoInput = document.getElementById('logo-input');
                        if (logoInput) {
                          logoInput.value = '';
                        }
                        // Clear the logoFile state
                        setLogoFile(null);
                        // Update vendorInfo
                        setVendorInfo(prev => ({
                          ...prev, 
                          logo: null
                        }));
                        setHasChanges(true);
                      }}
                      sx={{ position: 'absolute', top: 5, right: 5 }}
                    >
                      Remove
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>Upload Business Media Files</Typography>
              <input 
                type="file" 
                multiple 
                onChange={handleMediaUpload}
                accept="image/*"
                id="media-input"
                style={{ marginBottom: '16px' }}
              />
              
              {/* Media Previews Container */}
              <Box sx={{ mt: 2 }}>
                <Typography 
                  id="media-preview-count" 
                  variant="body2" 
                  sx={{ mb: 1, display: vendorInfo?.businessMedia?.length > 0 ? 'block' : 'none' }}
                >
                  {vendorInfo?.businessMedia?.length > 0 ? `Media Previews (${vendorInfo.businessMedia.length} files)` : ''}
                </Typography>
                
                <Box 
                  id="media-previews-container" 
                  sx={{ 
                    display: vendorInfo?.businessMedia?.length > 0 ? 'block' : 'none' 
                  }}
                >
                  {vendorInfo?.businessMedia?.length > 0 && (
                    <Box 
                      sx={{ 
                        display: 'grid', 
                        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', 
                        gap: 2
                      }}
                    >
                      {vendorInfo.businessMedia.map((media, index) => (
                        <Box 
                          key={index} 
                          sx={{ 
                            border: '1px solid #ddd', 
                            borderRadius: '4px', 
                            p: 1, 
                            position: 'relative' 
                          }}
                        >
                          <img 
                            src={media} 
                            alt={`Business Media ${index + 1}`} 
                            style={{ 
                              width: '100%', 
                              height: '100px', 
                              objectFit: 'cover' 
                            }} 
                          />
                          <IconButton
                            size="small"
                            color="error"
                            onClick={(e) => {
                              e.preventDefault();
                              
                              // Remove from vendorInfo
                              const updatedMedia = [...vendorInfo.businessMedia];
                              updatedMedia.splice(index, 1);
                              setVendorInfo(prev => ({
                                ...prev,
                                businessMedia: updatedMedia
                              }));
                              
                              // Also remove from the businessMediaFiles array if it exists there
                              // This is a best-effort approach as we can't directly match
                              // the preview URL to the file object
                              if (businessMediaFiles.length > index) {
                                const updatedBusinessMediaFiles = [...businessMediaFiles];
                                updatedBusinessMediaFiles.splice(index, 1);
                                setBusinessMediaFiles(updatedBusinessMediaFiles);
                              }
                              
                              setHasChanges(true);
                              
                              // Update the preview count
                              const previewCount = document.getElementById('media-preview-count');
                              if (previewCount) {
                                previewCount.textContent = `Media Previews (${updatedMedia.length} files)`;
                                previewCount.style.display = updatedMedia.length > 0 ? 'block' : 'none';
                              }
                            }}
                            sx={{ position: 'absolute', top: 2, right: 2, bgcolor: 'rgba(255,255,255,0.8)' }}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      
      <Card sx={{ p: 4, mb: 4 }}>
        <CardContent>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Weekly Availability</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {vendorInfo && vendorInfo.weeklyAvailability && Object.entries(vendorInfo.weeklyAvailability).map(([day, availability]) => (
                  <Grid item xs={12} key={day}>
                    <Card variant="outlined" sx={{ p: 2, mb: 2 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{day}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={availability.isClosed}
                                onChange={() => handleClosedChange(day)}
                              />
                            }
                            label="Closed"
                          />
                        </Grid>
                        {!availability.isClosed && (
                          <>
                            <Grid item xs={12} sm={6}>
                              <TimePicker
                                label="Start Time"
                                value={
                                  availability.startTime 
                                    ? (typeof availability.startTime === 'string' 
                                        ? dayjs(new Date(`2025-03-10 ${availability.startTime.replace(/(\d+):(\d+) (AM|PM)/, function(match, h, m, ampm) {
                                            let hour = parseInt(h, 10);
                                            if (ampm === 'PM' && hour < 12) hour += 12;
                                            if (ampm === 'AM' && hour === 12) hour = 0;
                                            return `${hour}:${m}:00`;
                                          })}`))
                                        : availability.startTime)
                                    : null
                                }
                                onChange={(newValue) =>
                                  handleWeeklyAvailabilityChange(day, 'startTime', newValue)
                                }
                              />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TimePicker
                              label="End Time"
                              value={
                                availability.endTime 
                                  ? (typeof availability.endTime === 'string' 
                                      ? dayjs(new Date(`2025-03-10 ${availability.endTime.replace(/(\d+):(\d+) (AM|PM)/, function(match, h, m, ampm) {
                                          let hour = parseInt(h, 10);
                                          if (ampm === 'PM' && hour < 12) hour += 12;
                                          if (ampm === 'AM' && hour === 12) hour = 0;
                                          return `${hour}:${m}:00`;
                                        })}`))
                                      : availability.endTime)
                                  : null
                              }
                              onChange={(newValue) =>
                                handleWeeklyAvailabilityChange(day, 'endTime', newValue)
                              }
                            />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>

          <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mb: 4 }}>
            <Box></Box>
            <Box>
              <Button 
                variant="outlined" 
                color="secondary" 
                onClick={cancelEdit}
                startIcon={<CancelIcon />}
                sx={{ mr: 2 }}
              >
                Cancel
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSubmit}
                startIcon={<SaveIcon />}
                disabled={!hasChanges || loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Save'}
              </Button>
            </Box>
          </Box>
          </Accordion>
          
        </CardContent>
      </Card>
    </Box>
  );
};

return (
  <Container maxWidth="lg" sx={{ p: 3, mt: 4, mb: 4 }}>
    <Fade in={true} timeout={500}>
      {isEditMode ? renderEditMode() : renderViewMode()}
    </Fade>
    
    <Dialog open={openModal} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>
          Information saved successfully.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  </Container>
);
};

export default CombinedVendorInformation;