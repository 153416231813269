import React, { useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import Welcome from './components/Welcome/Welcome';
import OrganizerDashboard from './components/Dashboards/OrganizerDashboard/OrganizerDashboard';
import VendorDashboard from './components/Dashboards/VendorDashboard/VendorDashboard';
import CreateTripSlipScreen from './components/CreatingTripSlips/CreateTripSlipScreen';
import ViewTripSlip from './components/TripSlips/ViewTripSlip';
import ViewTripSlips from './components/TripSlips/ViewTripSlips';
import SignTripSlip from './components/TripSlips/SignTripSlip/SignTripSlip';
import EditProfile from './components/EditProfile/EditProfile';
import ViewVendorInformation from './components/VendorInformation/ViewVendorInformation/ViewVendorInformation';
import EditVendorInformation from './components/VendorInformation/EditVendorInformation/EditVendorInformation';
import ViewAllBookings from './components/Bookings/ViewAllBookings/ViewAllBookings';
import PendingBookings from './components/Bookings/PendingBookings/PendingBookings';
import ViewBooking from './components/Bookings/ViewBooking/ViewBooking';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ViewBookingsCalendar from './components/ViewBookingsCalendar/ViewBookingsCalendar';
import GuardianDashboard from "./components/Dashboards/GuardianDashboard/GuardianDashboard";

const AppRouter = ({ drawerOpen, toggleDrawer, }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({
      pendingBookings: [],
      upcomingMonthBookings: [],
      todayBookings: [],
      cancelledBookings: [],
      allBookings: []
    });

  const menuItems = [
    { text: 'Login', path: '/' },
    { text: 'Signup', path: '/signup' },
    { text: 'Welcome', path: '/welcome' },
    { text: 'Sign Trip Slip', path: '/sign-trip-slip' },
    { text: 'Privacy Policy', path: '/privacy-policy' },
    { text: 'Terms & Conditions', path: '/terms-and-conditions' },
  ];
  const OrganizerMenuItems = [
    { text: 'Dashboard', path: '/organizer-dashboard' },
    { text: 'Create Trip Slip', path: '/create-trip-slip' },
    { text: 'View Trip Slips', path: '/view-tripslips' },
    { text: 'Edit Profile', path: '/edit-profile' },
    { text: 'Privacy Policy', path: '/privacy-policy' },
    { text: 'Terms & Conditions', path: '/terms-and-conditions' },
  ];
  const vendorMenuItems = [
    { text: 'Dashboard', path: '/vendor-dashboard' },
    { text: 'Bookings', path: '/view-bookings' },
    { text: 'Vendor Information', path: '/vendor-information' },
    { text: 'Profile', path: '/edit-profile' },
    // { text: 'Edit Information', path: '/edit-vendor-information' },
    // { text: 'Pending Bookings', path: '/pending-bookings' },
    { text: 'Privacy Policy', path: '/privacy-policy' },
    { text: 'Terms & Conditions', path: '/terms-and-conditions' },
  ];
  const guardianMenuItems = [
    { text: 'Dashboard', path: '/guardian-dashboard' }
  ]

  const handleMenuItemClick = (path) => {
    console.log('close drawer')
    toggleDrawer();
    navigate(path);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>


      <Drawer anchor="left"  sx={{ '& .MuiDrawer-paper': { backgroundColor: 'black', color: 'white', pt: 7 }}} open={drawerOpen} onClose={toggleDrawer}>
        <List>
          {!sessionStorage.userId && menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => handleMenuItemClick(item.path)}
              selected={location.pathname === item.path}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}

          { sessionStorage.userId && sessionStorage.role === 'organizer' && OrganizerMenuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => handleMenuItemClick(item.path)}
              selected={location.pathname === item.path}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}

          { sessionStorage.userId && sessionStorage.role === 'vendor' && vendorMenuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => handleMenuItemClick(item.path)}
              selected={location.pathname === item.path}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}

          { sessionStorage.userId && sessionStorage.role === 'guardian' && guardianMenuItems.map((item) => (
              <ListItem
                  button
                  key={item.text}
                  onClick={() => handleMenuItemClick(item.path)}
                  selected={location.pathname === item.path}
              >
                <ListItemText primary={item.text} />
              </ListItem>
          ))}
        </List>
      </Drawer>
      {/* {sessionStorage.userId && sessionStorage.role === 'organizer' && <OrganizerDashboardDrawer open={drawerOpen} setDrawerOpen={setDrawerOpen} />} */}
      {/* {sessionStorage.userId && sessionStorage.role === 'vendor' && <VendorDashboardDrawer open={drawerOpen} setDrawerOpen={setDrawerOpen} />} */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/organizer-dashboard" element={<OrganizerDashboard />} />
        <Route path="/vendor-dashboard" element={<VendorDashboard data={data} setData={setData} />} />
        <Route path="/create-trip-slip" element={<CreateTripSlipScreen />} />
        <Route path="/view-trip-slip/:tripSlipId" element={<ViewTripSlip />} />
        <Route path="/view-tripslips" element={<ViewTripSlips />} />
        <Route path="/sign-trip-slip" element={<SignTripSlip />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/vendor-information" element={<ViewVendorInformation />} />
        <Route path="/edit-vendor-information" element={<EditVendorInformation />} />
        <Route path="/view-bookings" element={<ViewAllBookings  data={data} setData={setData} />} />
        <Route path="/pending-bookings" element={<PendingBookings  data={data} setData={setData} />} />
        <Route path="/view-booking/:id" element={<ViewBooking data={data} setData={setData}/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

        {/* Guardian routes */}
        <Route path="/guardian-dashboard" element={<GuardianDashboard />} />

        {/* Test routes*/}
        <Route path="/calendar" element={<ViewBookingsCalendar data={data} />} />
      </Routes>
    </LocalizationProvider>
  );
};

export default AppRouter;
