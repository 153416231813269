/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Chip,
  Collapse,
  IconButton,
  Tooltip,
  Badge
} from '@mui/material';
import { 
  Group, 
  CheckCircleOutline, 
  PendingOutlined, 
  FilterAlt,
} from '@mui/icons-material';

const VisitorsCard = ({ data }) => {
  // State for filters and toggles
  const [visitorType, setVisitorType] = useState('all');
  const [selectedMonth, setSelectedMonth] = useState('all');
  const [selectedDay, setSelectedDay] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  const [filteredCount, setFilteredCount] = useState({
    all: 0,
    pending: 0,
    confirmed: 0
  });
  const [availableMonths, setAvailableMonths] = useState([]);
  const [availableDays, setAvailableDays] = useState([]);

  // Get current date for defaults
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  
  // List of all possible months
  const allMonths = [
    { value: 0, label: 'January' },
    { value: 1, label: 'February' },
    { value: 2, label: 'March' },
    { value: 3, label: 'April' },
    { value: 4, label: 'May' },
    { value: 5, label: 'June' },
    { value: 6, label: 'July' },
    { value: 7, label: 'August' },
    { value: 8, label: 'September' },
    { value: 9, label: 'October' },
    { value: 10, label: 'November' },
    { value: 11, label: 'December' }
  ];

  // Process data to determine available months and calculate counts
  useEffect(() => {
    if (!data || !data.allBookings || data.allBookings.length === 0) {
      return;
    }

    // Extract the bookings we need to work with
    const allBookings = data.allBookings || [];
    const pendingBookings = allBookings.filter(booking => booking.status === 'pending');
    const confirmedBookings = allBookings.filter(booking => booking.status === 'confirmed');

    // Calculate the total counts
    const totalPendingPeople = pendingBookings.reduce((sum, booking) => sum + booking.numberOfPeople, 0);
    const totalConfirmedPeople = confirmedBookings.reduce((sum, booking) => sum + booking.numberOfPeople, 0);
    
    // Find all unique months in the data
    const monthSet = new Set();
    allBookings.forEach(booking => {
      const date = new Date(booking.bookingDate);
      monthSet.add(date.getMonth());
    });
    
    const monthsArray = Array.from(monthSet).sort((a, b) => a - b);
    setAvailableMonths(monthsArray);

    // Initial count setup
    setFilteredCount({
      all: totalPendingPeople + totalConfirmedPeople,
      pending: totalPendingPeople,
      confirmed: totalConfirmedPeople
    });
  }, [data]);

  // Update available days when month changes
  useEffect(() => {
    if (!data || !data.allBookings) return;
    
    if (selectedMonth === 'all') {
      setAvailableDays([]);
      return;
    }
    
    // Find all days that have bookings in the selected month
    const daySet = new Set();
    data.allBookings.forEach(booking => {
      const date = new Date(booking.bookingDate);
      if (date.getMonth() === parseInt(selectedMonth)) {
        daySet.add(date.getDate());
      }
    });
    
    const daysArray = Array.from(daySet).sort((a, b) => a - b);
    setAvailableDays(daysArray);
    
    // Reset day selection if the current selection isn't in the new day list
    if (selectedDay !== 'all' && !daysArray.includes(parseInt(selectedDay))) {
      setSelectedDay('all');
    }
  }, [selectedMonth, data]);

  // Filter data based on selected criteria
  useEffect(() => {
    if (!data || !data.allBookings) return;

    const filteredBookings = data.allBookings.filter(booking => {
      const date = new Date(booking.bookingDate);
      
      // Filter by month
      if (selectedMonth !== 'all' && date.getMonth() !== parseInt(selectedMonth)) {
        return false;
      }
      
      // Filter by day
      if (selectedDay !== 'all' && date.getDate() !== parseInt(selectedDay)) {
        return false;
      }
      
      return true;
    });
    
    const filteredPending = filteredBookings
      .filter(booking => booking.status === 'pending')
      .reduce((sum, booking) => sum + booking.numberOfPeople, 0);
    
    const filteredConfirmed = filteredBookings
      .filter(booking => booking.status === 'confirmed')
      .reduce((sum, booking) => sum + booking.numberOfPeople, 0);
    
    setFilteredCount({
      all: filteredPending + filteredConfirmed,
      pending: filteredPending,
      confirmed: filteredConfirmed
    });
  }, [selectedMonth, selectedDay, data]);

  // Handle visitor type toggle change
  const handleVisitorTypeChange = (event, newVisitorType) => {
    if (newVisitorType !== null) {
      setVisitorType(newVisitorType);
    }
  };

  // Handle month selection change
  const handleMonthChange = (event) => {
    const value = event.target.value;
    setSelectedMonth(value);
    setSelectedDay('all');
  };

  // Handle day selection change
  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  // Toggle filter visibility
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // Clear all filters
  const clearFilters = () => {
    setSelectedMonth('all');
    setSelectedDay('all');
  };

  // Determine which count to display based on the selected toggle
  const displayCount = () => {
    return filteredCount[visitorType];
  };

  // Get subtitle text based on selected filters
  const getSubtitleText = () => {
    let baseText = '';
    
    switch (visitorType) {
      case 'pending':
        baseText = 'Pending visitors';
        break;
      case 'confirmed':
        baseText = 'Confirmed visitors';
        break;
      default:
        baseText = 'All visitors';
    }
    
    // Add date filter info if applicable
    let dateText = '';
    if (selectedMonth !== 'all') {
      const monthName = allMonths.find(m => m.value === parseInt(selectedMonth))?.label;
      dateText = ` in ${monthName}`;
      
      if (selectedDay !== 'all') {
        dateText += ` (Day ${selectedDay})`;
      }
    }
    
    return `${baseText}${dateText}`;
  };

  // Determine card border color based on selected type
  const getBorderColor = () => {
    switch (visitorType) {
      case 'pending':
        return '#f9a825'; // amber/warning color
      case 'confirmed':
        return '#4caf50'; // green/success color
      default:
        return '#1976d2'; // blue/info color
    }
  };

  // Check if any filters are active
  const hasActiveFilters = selectedMonth !== 'all' || selectedDay !== 'all';

  return (
    <Card 
      elevation={2} 
      className="dashboard-card" 
      sx={{ 
        borderLeft: `4px solid ${getBorderColor()}`,
        transition: 'border-color 0.3s ease'
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <Box display="flex" alignItems="center">
            <Group color="info" sx={{ mr: 1 }} />
            <Typography variant="subtitle2" color="text.secondary">
              Visitors
            </Typography>
          </Box>
          <Tooltip title={showFilters ? "Hide filters" : "Show filters"}>
            <IconButton 
              size="small" 
              onClick={toggleFilters}
              color={hasActiveFilters ? "primary" : "default"}
            >
              {hasActiveFilters && (
                <Badge
                  color="primary"
                  variant="dot"
                  sx={{ 
                    '& .MuiBadge-dot': {
                      top: 3,
                      right: 3,
                    }
                  }}
                >
                  <FilterAlt fontSize="small" />
                </Badge>
              )}
              {!hasActiveFilters && <FilterAlt fontSize="small" />}
            </IconButton>
          </Tooltip>
        </Box>
        
        <Typography variant="h4" component="div" fontWeight="bold">
          {displayCount()}
        </Typography>
        
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 1 }}>
          {getSubtitleText()}
        </Typography>
        
        {/* Date Filters */}
        <Collapse in={showFilters} timeout="auto">
          <Box sx={{ my: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="month-select-label">Month</InputLabel>
                  <Select
                    labelId="month-select-label"
                    id="month-select"
                    value={selectedMonth}
                    label="Month"
                    onChange={handleMonthChange}
                  >
                    <MenuItem value="all">All Months</MenuItem>
                    {availableMonths.map((monthValue) => (
                      <MenuItem key={monthValue} value={monthValue.toString()}>
                        {allMonths.find(m => m.value === monthValue)?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl size="small" fullWidth disabled={selectedMonth === 'all' || availableDays.length === 0}>
                  <InputLabel id="day-select-label">Day</InputLabel>
                  <Select
                    labelId="day-select-label"
                    id="day-select"
                    value={selectedDay}
                    label="Day"
                    onChange={handleDayChange}
                  >
                    <MenuItem value="all">All Days</MenuItem>
                    {availableDays.map((day) => (
                      <MenuItem key={day} value={day.toString()}>
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            
            {hasActiveFilters && (
              <Box display="flex" justifyContent="flex-end" mt={1}>
                <Chip 
                  label="Clear Filters" 
                  size="small" 
                  onDelete={clearFilters} 
                  color="primary" 
                  variant="outlined"
                  sx={{ fontSize: '0.7rem' }}
                />
              </Box>
            )}
          </Box>
        </Collapse>
        
        <Divider sx={{ my: 1.5 }} />
        
        {/* Visitor Type Toggle */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
          <ToggleButtonGroup
            value={visitorType}
            exclusive
            onChange={handleVisitorTypeChange}
            size="small"
            sx={{ 
              '& .MuiToggleButtonGroup-grouped': {
                border: 1,
                borderColor: 'divider',
                '&.Mui-selected': {
                  backgroundColor: (theme) => 
                    visitorType === 'pending' 
                      ? 'rgba(249, 168, 37, 0.1)' 
                      : visitorType === 'confirmed' 
                        ? 'rgba(76, 175, 80, 0.1)' 
                        : 'rgba(25, 118, 210, 0.1)',
                  color: (theme) => 
                    visitorType === 'pending' 
                      ? '#f9a825' 
                      : visitorType === 'confirmed' 
                        ? '#4caf50' 
                        : '#1976d2',
                }
              }
            }}
          >
            <ToggleButton value="all">
              <Box sx={{ display: 'flex', alignItems: 'center', px: 0.5 }}>
                <Group fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="caption">All</Typography>
              </Box>
            </ToggleButton>
            
            <ToggleButton value="pending">
              <Box sx={{ display: 'flex', alignItems: 'center', px: 0.5 }}>
                <PendingOutlined fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="caption">Pending</Typography>
              </Box>
            </ToggleButton>
            
            <ToggleButton value="confirmed">
              <Box sx={{ display: 'flex', alignItems: 'center', px: 0.5 }}>
                <CheckCircleOutline fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="caption">Confirmed</Typography>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </CardContent>
    </Card>
  );
};

export default VisitorsCard;