/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  TextField,
  Paper,
  InputAdornment,
  Stack,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import ClearIcon from "@mui/icons-material/Clear";
import { dummyOrganizations } from "../dummydata";
import axios from "axios";
import { formatTimeSlot } from "../../../Bookings/ViewBooking/ViewBooking";

// Component for the bookings table with status filter
const BookingsTable = ({
  data,
  organizations = dummyOrganizations,
  navigate,
  onUpdateStatus,
  fetchBookings,
}) => {
  // Add new state for API notifications
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  console.log(data);
  // State for filtered bookings
  const [filteredBookings, setFilteredBookings] = useState([]);
  // State for status filter
  const [statusFilter, setStatusFilter] = useState("all");
  // State for search query
  const [searchQuery, setSearchQuery] = useState("");
  // State for date filter
  const [dateFilter, setDateFilter] = useState("");
  // State for sorting
  const [sortConfig, setSortConfig] = useState({
    key: "bookingDate",
    direction: "asc",
  });
  // State for confirmation dialog
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    bookingId: null,
    newStatus: "",
    title: "",
    message: "",
  });

  // Status options with their respective colors
  const statusOptions = [
    { value: "confirmed", color: "success" },
    { value: "pending", color: "warning" },
    { value: "cancelled", color: "error" },
  ];
  // Function to handle status updates
  const handleUpdateStatus = async (bookingId, newStatus) => {
    const serviceUrl = process.env.REACT_APP_SERVICE_URL;

    // Map frontend status to backend action
    const action =
      newStatus === "confirmed"
        ? "approve"
        : newStatus === "cancelled"
        ? "deny"
        : newStatus === "pending"
        ? "pending"
        : null;

    try {
      const response = await axios.put(
        `${serviceUrl}/bookings/action/${bookingId}`,
        { action }
      );

      if (response.status === 200) {
        setNotification({
          open: true,
          message: `Booking ${newStatus} successfully.`,
          severity: "success",
        });

        // Refresh bookings data
        if (fetchBookings) {
          fetchBookings();
        } else {
          // Update local state if fetchBookings is not provided
          setFilteredBookings((prevBookings) =>
            prevBookings.map((booking) =>
              booking._id === bookingId
                ? { ...booking, status: newStatus }
                : booking
            )
          );
        }
      }
    } catch (error) {
      console.error("Error updating booking status:", error);
      setNotification({
        open: true,
        message: `Failed to update booking: ${
          error.response?.data?.message || error.message
        }`,
        severity: "error",
      });
    }
  };

  // Close notification
  const handleCloseNotification = () => {
    setNotification({
      ...notification,
      open: false,
    });
  };

  // Update filtered bookings when data, filter, or search changes
  useEffect(() => {
    // Get all bookings from the data structure
    const allBookings = data?.allBookings || [];

    if (allBookings.length > 0) {
      let filtered = [...allBookings];

      // Apply status filter
      if (statusFilter !== "all") {
        filtered = filtered.filter(
          (booking) =>
            booking.status.toLowerCase() === statusFilter.toLowerCase()
        );
      }

      // Apply date filter
      if (dateFilter) {
        // Create date from the selected filter value and normalize to YYYY-MM-DD
        const filterDateStr = dateFilter.split("T")[0];

        filtered = filtered.filter((booking) => {
          if (!booking.bookingDate) return false;

          // Normalize booking date to YYYY-MM-DD format
          const bookingDateStr = new Date(booking.bookingDate)
            .toISOString()
            .split("T")[0];

          // Compare dates as strings to avoid timezone issues
          return bookingDateStr === filterDateStr;
        });
      }

      // Apply search filter
      if (searchQuery.trim() !== "") {
        const query = searchQuery.toLowerCase();
        filtered = filtered.filter((booking) => {
          // Get organization name for this booking
          let orgName = "";

          // Try to find organization in our main data
          if (booking.organizer) {
            orgName = `${booking.organizer.firstName} ${booking.organizer.lastName}`;
          } else if (booking.organizerId) {
            // Fallback to organizations list lookup
            orgName =
              organizations.find((org) => org._id === booking.organizerId)
                ?.name || "";
          }

          // Format time slot for searching
          let timeSlotString = "";
          if (typeof booking.timeSlot === "string") {
            timeSlotString = booking.timeSlot;
          } else if (
            booking.timeSlot &&
            booking.timeSlot.startTime &&
            booking.timeSlot.endTime
          ) {
            timeSlotString = `${booking.timeSlot.startTime} - ${booking.timeSlot.endTime}`;
          }

          // Search across multiple fields
          return (
            (booking.tripSlipCode || booking._id || "")
              .toLowerCase()
              .includes(query) ||
            orgName.toLowerCase().includes(query) ||
            (new Date(booking?.bookingDate).toLocaleDateString() || "")
              .toLowerCase()
              .includes(query) ||
            timeSlotString.toLowerCase().includes(query) ||
            (booking.numberOfPeople?.toString() || "").includes(query) ||
            (booking.status || "").toLowerCase().includes(query)
          );
        });
      }

      // Apply sorting and update state
      setFilteredBookings(getSortedBookings(filtered));
    }
  }, [data, statusFilter, searchQuery, dateFilter, sortConfig, organizations]);

  // Handle status filter change
  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  // Handle date filter change
  const handleDateFilterChange = (event) => {
    setDateFilter(event.target.value);
  };

  // Handle sorting
  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Get sorted and filtered bookings
  const getSortedBookings = (bookings) => {
    const sortableBookings = [...bookings];

    // Apply sorting
    if (sortConfig.key) {
      sortableBookings.sort((a, b) => {
        // Special handling for date sorting
        if (sortConfig.key === "bookingDate") {
          const dateA = new Date(a.bookingDate);
          const dateB = new Date(b.bookingDate);

          if (sortConfig.direction === "asc") {
            return dateA - dateB;
          } else {
            return dateB - dateA;
          }
        }

        // Default sorting for other fields
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    return sortableBookings;
  };

  // Handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Get color for status chip
  const getStatusColor = (status) => {
    const statusLower = status.toLowerCase();
    const statusOption = statusOptions.find(
      (option) => option.value === statusLower
    );
    return statusOption?.color || "default";
  };

  // Open confirmation dialog for status change
  const openStatusChangeDialog = (bookingId, newStatus) => {
    const statusTexts = {
      confirmed: "confirm",
      pending: "mark as pending",
      cancelled: "cancel",
    };

    setConfirmDialog({
      open: true,
      bookingId,
      newStatus,
      title: `${statusTexts[newStatus]} Booking`,
      message: `Are you sure you want to ${statusTexts[newStatus]} this booking?`,
    });
  };

  // Close confirmation dialog
  const closeConfirmDialog = () => {
    setConfirmDialog({
      ...confirmDialog,
      open: false,
    });
  };

  // Check if a status action button should be disabled
  const isActionDisabled = (booking, status) => {
    return booking.status.toLowerCase() === status.toLowerCase();
  };

  // Render action buttons for status changes
  const renderStatusActions = (booking) => {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Tooltip title="Confirm Booking">
          <span>
            <IconButton
              size="small"
              color="success"
              disabled={isActionDisabled(booking, "confirmed")}
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click
                openStatusChangeDialog(booking._id, "confirmed");
              }}
            >
              <CheckCircleIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Mark as Pending">
          <span>
            <IconButton
              size="small"
              color="warning"
              disabled={isActionDisabled(booking, "pending")}
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click
                openStatusChangeDialog(booking._id, "pending");
              }}
            >
              <PendingIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title="Cancel Booking">
          <span>
            <IconButton
              size="small"
              color="error"
              disabled={isActionDisabled(booking, "cancelled")}
              onClick={(e) => {
                e.stopPropagation(); // Prevent row click
                openStatusChangeDialog(booking._id, "cancelled");
              }}
            >
              <CancelIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    );
  };

  // Confirm status change function - updates to use our new handler
  const confirmStatusChange = () => {
    if (confirmDialog.bookingId && confirmDialog.newStatus) {
      handleUpdateStatus(confirmDialog.bookingId, confirmDialog.newStatus);
    }
    closeConfirmDialog();
  };

  return (
    <Paper sx={{ p: 3, backgroundColor: "white" }} elevation={0}>
      {/* Add notification snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
      {/* Filter and Search Controls */}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        justifyContent="space-between"
        mb={2}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          fullWidth
          sx={{ maxWidth: { sm: "40%" } }}
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Search all fields..."
        />

        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            label="Filter by Date"
            type="date"
            size="small"
            value={dateFilter}
            onChange={handleDateFilterChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: dateFilter && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear date filter"
                    onClick={() => setDateFilter("")}
                    edge="end"
                    size="small"
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 170 }}
          />

          <FormControl sx={{ minWidth: 150 }} size="small">
            <InputLabel id="status-filter-label">Filter by Status</InputLabel>
            <Select
              labelId="status-filter-label"
              id="status-filter"
              value={statusFilter}
              onChange={handleStatusFilterChange}
              input={<OutlinedInput label="Filter by Status" />}
            >
              <MenuItem value="all">All Statuses</MenuItem>
              {statusOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Chip
                    label={
                      option.value.charAt(0).toUpperCase() +
                      option.value.slice(1)
                    }
                    size="small"
                    color={option.color}
                    sx={{ mr: 1 }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>

      {/* Bookings Table */}
      {filteredBookings?.length > 0 ? (
        <TableContainer
          sx={{
            maxHeight: "70vh",
            backgroundColor: "white",
            // These styles help ensure proper sticky header behavior
            overflow: "auto",
            position: "relative",
            "& .MuiTableHead-root": {
              position: "sticky",
              top: 0,
              zIndex: 2,
            },
            "& .MuiTableHead-root .MuiTableCell-root": {
              backgroundColor: "white",
              borderBottom: "2px solid rgba(224, 224, 224, 1)",
              fontWeight: "bold",
            },
          }}
        >
          <Table stickyHeader aria-label="bookings table">
            <TableHead>
              <TableRow>
                <TableCell>TripSlip Code</TableCell>
                <TableCell
                  onClick={() => requestSort("bookingDate")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                    position: "relative",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Booking Date
                    {sortConfig.key === "bookingDate" && (
                      <Box component="span" sx={{ ml: 0.5 }}>
                        {sortConfig.direction === "asc" ? "↑" : "↓"}
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell>Time Slot</TableCell>
                <TableCell
                  onClick={() => requestSort("numberOfPeople")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                    position: "relative",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Count
                    {sortConfig.key === "numberOfPeople" && (
                      <Box component="span" sx={{ ml: 0.5 }}>
                        {sortConfig.direction === "asc" ? "↑" : "↓"}
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  onClick={() => requestSort("status")}
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "#f5f5f5" },
                    position: "relative",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Status
                    {sortConfig.key === "status" && (
                      <Box component="span" sx={{ ml: 0.5 }}>
                        {sortConfig.direction === "asc" ? "↑" : "↓"}
                      </Box>
                    )}
                  </Box>
                </TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredBookings.map((booking) => (
                <TableRow
                  key={booking._id}
                  hover
                  onClick={() => navigate(`/view-booking/${booking._id}`)}
                  sx={{ cursor: "pointer", backgroundColor: "white" }}
                >
                  {/* Table cell content remains the same */}
                  <TableCell>
                    <Chip
                      label={
                        booking?.tripSlipCode || booking?._id.substring(0, 8)
                      }
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {booking?.bookingDate
                        ? formatBookingDate(booking.bookingDate)
                        : ""}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      icon={<EventIcon fontSize="small" />}
                      label={
                        <Typography variant="body2">
                          {formatTimeSlot(booking.timeSlot)}
                        </Typography>
                      }
                      size="small"
                      variant="outlined"
                      color="secondary"
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={booking?.numberOfPeople}
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={booking?.status}
                      size="small"
                      color={getStatusColor(booking?.status)}
                      variant="filled"
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {renderStatusActions(booking)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" align="center" sx={{ py: 3 }}>
          No bookings matching the current filter or search query.
        </Typography>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialog.open}
        onClose={closeConfirmDialog}
        aria-labelledby="status-change-dialog-title"
        aria-describedby="status-change-dialog-description"
      >
        <DialogTitle id="status-change-dialog-title">
          {confirmDialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="status-change-dialog-description">
            {confirmDialog.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog}>Cancel</Button>
          <Button
            onClick={confirmStatusChange}
            variant="contained"
            color={getStatusColor(confirmDialog.newStatus) || "primary"}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default BookingsTable;

// Format booking date to ensure correct date is displayed regardless of timezone
const formatBookingDate = (dateString) => {
  // Parse the date parts from the ISO format (YYYY-MM-DD)
  const [year, month, day] = dateString.split("-").map(Number);

  // Create a date using UTC to avoid timezone offset shifting the day
  const date = new Date(Date.UTC(year, month - 1, day));

  // Format the date to a locale string with specified options
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone: "UTC", // This ensures we display the exact date without timezone shifting
  });
};
