/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Collapse, Divider, Breadcrumbs } from '@mui/material';
import axios from 'axios';
import DashboardDrawer from '../../DashboardDrawers/VendorDashboardDrawer/VendorDashboardDrawer';
import { Link, useNavigate } from 'react-router-dom';
import BookingsTable from '../../Dashboards/VendorDashboard/BookingsTable/BookingsTable';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { NavigateNext } from '@mui/icons-material';

const BookingsView = ({ data, setData }) => {
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const url = process.env.REACT_APP_SERVICE_URL;  
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const vendorId = sessionStorage.getItem('vendorId');
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`${url}/bookings/vendor/${vendorId}`);
        setBookings(response.data);
        setFilteredBookings(response.data);
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      }
    };

    if (vendorId) {
      fetchBookings();
    }
  }, [url]);


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box  sx={{height: 'calc(100vh - 100px)'}}>
      {/* <DashboardDrawer open={drawerOpen} toggleDrawer={toggleDrawer} /> */}
      <Container maxWidth="lg"  sx={{height: '100%', py:4}}>
        {/* Breadcrumbs Navigation */}
      <Breadcrumbs 
        separator={<NavigateNext fontSize="small" />} 
        aria-label="breadcrumb"
        sx={{ mb: 3 }}
      >
        <Link 
          color="inherit" 
          href="#" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/vendor-dashboard');
          }}
        >
          Dashboard
        </Link>
        <Typography color="text.primary">Bookings</Typography>
        
      </Breadcrumbs>
      <Box sx={{ m: 3 }}>
        <Typography variant="h4" component="div">
          All Bookings
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show description"
            size="small"
            sx={{ ml: 1, transform: expanded ? 'rotate(180deg)' : 'none', transition: 'transform 0.3s' }}
          >
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Typography>
        
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2, 
              mt: 1, 
              mb: 2, 
              backgroundColor: '#f5f5f5', 
              borderLeft: '4px solid #1976d2',
              display: 'flex'
            }}
          >
            <InfoOutlinedIcon sx={{ mr: 1, color: '#1976d2' }} />
            <Typography variant="body2" color="text.secondary">
              This page displays all bookings in the system. You can search across all fields, filter by status, 
              and manage bookings directly from this table. Click on any booking row to view more details, 
              or use the action buttons to change booking status. Bookings can be confirmed, marked as pending, 
              or canceled using the buttons in the Actions column.
            </Typography>
          </Paper>
        </Collapse>
        <Divider sx={{ mb: 3 }} />
      </Box>


        <BookingsTable navigate={navigate} data= {data} setDate={setData} />
      </Container>
    </Box>
  );
};

export default BookingsView;