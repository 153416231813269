// src/data/dummyData.js
// This represents the data structure before transformation
// Formatted to match actual API responses from getBookingsByVendor and getTripInfoForBooking
export const dummyBookings = {
    pendingBookings: [
      {
        _id: "60d21b4667d0d8992e610c85",
        tripSlipCode: "TS1001",
        organizer: {
          firstName: "John",
          lastName: "Smith",
          email: "john.smith@school.edu",
          contactPhoneNumber: "+15551234567"
        },
        bookingDate: "2025-04-12",
        timeSlot: "09:00 - 14:00",
        numberOfPeople: 35,
        status: "pending",
        departureLocation: "Central Middle School"
      },
      {
        _id: "60d21b4667d0d8992e610c88",
        tripSlipCode: "TS1004",
        organizer: {
          firstName: "Emma",
          lastName: "Johnson",
          email: "emma.johnson@district45.edu",
          contactPhoneNumber: "+15552345678"
        },
        bookingDate: "2025-05-05",
        timeSlot: "08:00 - 16:00",
        numberOfPeople: 50,
        status: "pending",
        departureLocation: "Washington Elementary School"
      },
      {
        _id: "60d21b4667d0d8992e610c90",
        tripSlipCode: "TS1006",
        organizer: {
          firstName: "Michael",
          lastName: "Brown",
          email: "mbrown@lincolnschool.edu",
          contactPhoneNumber: "+15553456789"
        },
        bookingDate: "2025-03-30",
        timeSlot: "10:00 - 13:00",
        numberOfPeople: 25,
        status: "pending",
        departureLocation: "Lincoln Elementary"
      },
      {
        _id: "60d21b4667d0d8992e610c93",
        tripSlipCode: "TS1009",
        organizer: {
          firstName: "Jessica",
          lastName: "Taylor",
          email: "jtaylor@jeffersonhigh.edu",
          contactPhoneNumber: "+15554567890"
        },
        bookingDate: "2025-04-25",
        timeSlot: "09:45 - 15:15",
        numberOfPeople: 38,
        status: "pending",
        departureLocation: "Jefferson High School"
      },
      {
        _id: "60d21b4667d0d8992e610c97",
        tripSlipCode: "TS1012",
        organizer: {
          firstName: "Robert",
          lastName: "Davis",
          email: "r.davis@rooseveltms.edu",
          contactPhoneNumber: "+15555678901"
        },
        bookingDate: "2025-04-20",
        timeSlot: "15:30 - 18:00",
        numberOfPeople: 18,
        status: "pending",
        departureLocation: "Roosevelt Middle School"
      },
      {
        _id: "60d21b4667d0d8992e610d00",
        tripSlipCode: "TS1017",
        organizer: {
          firstName: "Jennifer",
          lastName: "Miller",
          email: "j.miller@edisonhs.edu",
          contactPhoneNumber: "+15556789012"
        },
        bookingDate: "2025-05-10",
        timeSlot: "15:00 - 18:00",
        numberOfPeople: 30,
        status: "pending",
        departureLocation: "Edison High School"
      },
      {
        _id: "60d21b4667d0d8992e610d01",
        tripSlipCode: "TS1018",
        organizer: {
          firstName: "Thomas",
          lastName: "Anderson",
          email: "t.anderson@franklinms.edu",
          contactPhoneNumber: "+15557890123"
        },
        bookingDate: "2025-03-22",
        timeSlot: "14:00 - 16:30",
        numberOfPeople: 15,
        status: "pending",
        departureLocation: "Franklin Middle School"
      }
    ],
    todayBookings: [
      {
        _id: "60d21b4667d0d8992e610d02",
        tripSlipCode: "TS1015",
        organizer: {
          firstName: "Sarah",
          lastName: "Parker",
          email: "sparker@oceanviewhs.edu",
          contactPhoneNumber: "+15558901234"
        },
        bookingDate: new Date().toISOString().split('T')[0],
        timeSlot: "10:00 - 13:00",
        numberOfPeople: 28,
        status: "confirmed",
        departureLocation: "Oceanview High School"
      },
      {
        _id: "60d21b4667d0d8992e610d03",
        tripSlipCode: "TS1016",
        organizer: {
          firstName: "David",
          lastName: "Wilson",
          email: "david.wilson@techmagnethigh.edu",
          contactPhoneNumber: "+15559012345"
        },
        bookingDate: new Date().toISOString().split('T')[0],
        timeSlot: "14:30 - 17:00",
        numberOfPeople: 22,
        status: "pending",
        departureLocation: "Tech Magnet High School"
      },
      {
        _id: "60d21b4667d0d8992e610d04",
        tripSlipCode: "TS1019",
        organizer: {
          firstName: "Lisa",
          lastName: "Garcia",
          email: "l.garcia@countryside.edu",
          contactPhoneNumber: "+15550123456"
        },
        bookingDate: new Date().toISOString().split('T')[0],
        timeSlot: "08:30 - 11:30",
        numberOfPeople: 32,
        status: "confirmed",
        departureLocation: "Countryside Elementary"
      },
      {
        _id: "60d21b4667d0d8992e610d05",
        tripSlipCode: "TS1020",
        organizer: {
          firstName: "James",
          lastName: "Martin",
          email: "jmartin@kennedyarts.edu",
          contactPhoneNumber: "+15551234567"
        },
        bookingDate: new Date().toISOString().split('T')[0],
        timeSlot: "12:00 - 15:00",
        numberOfPeople: 26,
        status: "confirmed",
        departureLocation: "Kennedy Arts Academy"
      },
      {
        _id: "60d21b4667d0d8992e610d06",
        tripSlipCode: "TS1021",
        organizer: {
          firstName: "Emily",
          lastName: "White",
          email: "e.white@madisonelem.edu",
          contactPhoneNumber: "+15552345678"
        },
        bookingDate: new Date().toISOString().split('T')[0],
        timeSlot: "16:00 - 19:00",
        numberOfPeople: 20,
        status: "pending",
        departureLocation: "Madison Elementary"
      }
    ]
  };
  

  
  export const dummyOrganizations = [
    {
      _id: "60d21b4667d0d8992e610c78",
      name: "Evergreen Elementary School",
      location: {
        streetAddress: "123 Oak Street",
        city: "Springfield",
        state: "IL",
        zipCode: "62704"
      },
      terms: "Standard field trip terms and conditions apply.",
      status: "approved",
      isActive: true,
      createdAt: new Date("2024-12-10T14:35:26.781Z"),
      updatedAt: new Date("2025-01-15T09:22:38.443Z")
    },
    {
      _id: "60d21b4667d0d8992e610c79",
      name: "Lincoln Middle School",
      location: {
        streetAddress: "456 Maple Avenue",
        city: "Riverside",
        state: "CA",
        zipCode: "92501"
      },
      terms: "All students must have signed permission slips.",
      status: "approved",
      isActive: true,
      createdAt: new Date("2024-11-18T11:45:32.218Z"),
      updatedAt: new Date("2025-01-10T16:37:41.129Z")
    },
    {
      _id: "60d21b4667d0d8992e610c80",
      name: "Washington High School",
      location: {
        streetAddress: "789 Pine Boulevard",
        city: "Portland",
        state: "OR",
        zipCode: "97205"
      },
      terms: "School must provide one chaperone for every 10 students.",
      status: "approved",
      isActive: true,
      createdAt: new Date("2024-10-25T08:12:55.634Z"),
      updatedAt: new Date("2025-02-03T10:45:19.872Z")
    },
    {
      _id: "60d21b4667d0d8992e610c81",
      name: "Jefferson Academy",
      location: {
        streetAddress: "101 Cedar Lane",
        city: "Boston",
        state: "MA",
        zipCode: "02108"
      },
      terms: "Advance payment required for all field trips.",
      status: "approved",
      isActive: true,
      createdAt: new Date("2024-11-30T15:28:39.910Z"),
      updatedAt: new Date("2025-01-22T13:41:27.356Z")
    },
    {
      _id: "60d21b4667d0d8992e610c82",
      name: "Roosevelt STEM Charter",
      location: {
        streetAddress: "202 Birch Road",
        city: "Chicago",
        state: "IL",
        zipCode: "60601"
      },
      terms: "Students must wear school uniforms on all trips.",
      status: "approved",
      isActive: true,
      createdAt: new Date("2024-12-05T09:33:17.528Z"),
      updatedAt: new Date("2025-02-10T11:18:42.691Z")
    },
    {
      _id: "60d21b4667d0d8992e610c83",
      name: "Franklin Arts Magnet School",
      location: {
        streetAddress: "303 Willow Drive",
        city: "Austin",
        state: "TX",
        zipCode: "78701"
      },
      terms: "Photo release forms must be submitted for all students.",
      status: "approved",
      isActive: true,
      createdAt: new Date("2024-10-15T13:22:46.175Z"),
      updatedAt: new Date("2025-01-05T16:59:33.892Z")
    },
    {
      _id: "60d21b4667d0d8992e610c84",
      name: "Adams Montessori School",
      location: {
        streetAddress: "404 Aspen Court",
        city: "Seattle",
        state: "WA",
        zipCode: "98101"
      },
      terms: "Dietary restrictions must be submitted one week in advance.",
      status: "approved",
      isActive: true,
      createdAt: new Date("2024-11-08T10:17:29.344Z"),
      updatedAt: new Date("2025-02-17T14:26:51.518Z")
    },
    {
      _id: "60d21b4667d0d8992e610c85",
      name: "Monroe Science Academy",
      location: {
        streetAddress: "505 Sequoia Place",
        city: "Denver",
        state: "CO",
        zipCode: "80202"
      },
      terms: "Safety equipment will be provided by the venue.",
      status: "pending",
      isActive: true,
      createdAt: new Date("2024-12-20T16:38:14.762Z"),
      updatedAt: new Date("2025-01-27T09:53:06.237Z")
    }
  ];

  // Trip info responses matching each booking in the dummyBookingsData
// This simulates what getTripInfoForBooking would return for each booking

export const tripInfoResponses = {
    // Pending Bookings Trip Info Responses
    "60d21b4667d0d8992e610c85": {
      _id: "60d21b4667d0d8992e610c85",
      tripName: "Science Museum Field Trip",
      grades: ["6", "7", "8"],
      tripSlipCode: "TS1001",
      departureLocation: "Central Middle School",
      organizer: {
        firstName: "John",
        lastName: "Smith"
      },
      organizerEmail: "john.smith@schooldistrict.edu",
      organizerContactPhone: "+15551234567",
      bookingDate: "2025-04-12",
      timeSlot: "09:00 - 14:00",
      numberOfPeople: 35,
      status: "pending"
    },
    
    "60d21b4667d0d8992e610c88": {
      _id: "60d21b4667d0d8992e610c88",
      tripName: "Natural History Museum Tour",
      grades: ["4", "5"],
      tripSlipCode: "TS1004",
      departureLocation: "Washington Elementary School",
      organizer: {
        firstName: "Emma",
        lastName: "Johnson"
      },
      organizerEmail: "e.johnson@elementaryschool.edu",
      organizerContactPhone: "+15552345678",
      bookingDate: "2025-05-05",
      timeSlot: "08:00 - 16:00",
      numberOfPeople: 50,
      status: "pending"
    },
    
    "60d21b4667d0d8992e610c90": {
      _id: "60d21b4667d0d8992e610c90",
      tripName: "Planetarium Adventure",
      grades: ["3", "4"],
      tripSlipCode: "TS1006",
      departureLocation: "Lincoln Elementary School",
      organizer: {
        firstName: "Michael",
        lastName: "Brown"
      },
      organizerEmail: "michael.brown@lincolnschool.edu",
      organizerContactPhone: "+15553456789",
      bookingDate: "2025-03-30",
      timeSlot: "10:00 - 13:00",
      numberOfPeople: 25,
      status: "pending"
    },
    
    "60d21b4667d0d8992e610c93": {
      _id: "60d21b4667d0d8992e610c93",
      tripName: "Art Gallery Study Tour",
      grades: ["9", "10", "11"],
      tripSlipCode: "TS1009",
      departureLocation: "Jefferson High School",
      organizer: {
        firstName: "Jessica",
        lastName: "Taylor"
      },
      organizerEmail: "j.taylor@jeffersonhigh.edu",
      organizerContactPhone: "+15554567890",
      bookingDate: "2025-04-25",
      timeSlot: "09:45 - 15:15",
      numberOfPeople: 38,
      status: "pending"
    },
    
    "60d21b4667d0d8992e610c97": {
      _id: "60d21b4667d0d8992e610c97",
      tripName: "Local History Museum",
      grades: ["7", "8"],
      tripSlipCode: "TS1012",
      departureLocation: "Roosevelt Middle School",
      organizer: {
        firstName: "Robert",
        lastName: "Davis"
      },
      organizerEmail: "r.davis@rooseveltms.edu",
      organizerContactPhone: "+15555678901",
      bookingDate: "2025-04-20",
      timeSlot: "15:30 - 18:00",
      numberOfPeople: 18,
      status: "pending"
    },
    
    "60d21b4667d0d8992e610d00": {
      _id: "60d21b4667d0d8992e610d00",
      tripName: "Robotics & Technology Exhibit",
      grades: ["10", "11", "12"],
      tripSlipCode: "TS1017",
      departureLocation: "Edison High School",
      organizer: {
        firstName: "Jennifer",
        lastName: "Miller"
      },
      organizerEmail: "jennifer.miller@edisonhs.edu",
      organizerContactPhone: "+15556789012",
      bookingDate: "2025-05-10",
      timeSlot: "15:00 - 18:00",
      numberOfPeople: 30,
      status: "pending"
    },
    
    "60d21b4667d0d8992e610d01": {
      _id: "60d21b4667d0d8992e610d01",
      tripName: "Botanical Gardens Science Trip",
      grades: ["5", "6"],
      tripSlipCode: "TS1018",
      departureLocation: "Franklin Middle School",
      organizer: {
        firstName: "Thomas",
        lastName: "Anderson"
      },
      organizerEmail: "t.anderson@franklinms.edu",
      organizerContactPhone: "+15557890123",
      bookingDate: "2025-03-22",
      timeSlot: "14:00 - 16:30",
      numberOfPeople: 15,
      status: "pending"
    },
    
    // Today Bookings Trip Info Responses
    "60d21b4667d0d8992e610d02": {
      _id: "60d21b4667d0d8992e610d02",
      tripName: "Aquarium Marine Biology Tour",
      grades: ["9", "10"],
      tripSlipCode: "TS1015",
      departureLocation: "Oceanview High School",
      organizer: {
        firstName: "Sarah",
        lastName: "Parker"
      },
      organizerEmail: "s.parker@oceanviewhs.edu",
      organizerContactPhone: "+15558901234",
      bookingDate: new Date().toISOString().split('T')[0] + "",
      timeSlot: "10:00 - 13:00",
      numberOfPeople: 28,
      status: "confirmed"
    },
    
    "60d21b4667d0d8992e610d03": {
      _id: "60d21b4667d0d8992e610d03",
      tripName: "Computer Science Career Day",
      grades: ["11", "12"],
      tripSlipCode: "TS1016",
      departureLocation: "Tech Magnet High School",
      organizer: {
        firstName: "David",
        lastName: "Wilson"
      },
      organizerEmail: "david.wilson@techmagnet.edu",
      organizerContactPhone: "+15559012345",
      bookingDate: new Date().toISOString().split('T')[0] + "",
      timeSlot: "14:30 - 17:00",
      numberOfPeople: 22,
      status: "pending"
    },
    
    "60d21b4667d0d8992e610d04": {
      _id: "60d21b4667d0d8992e610d04",
      tripName: "Farm & Agriculture Experience",
      grades: ["2", "3"],
      tripSlipCode: "TS1019",
      departureLocation: "Countryside Elementary",
      organizer: {
        firstName: "Lisa",
        lastName: "Garcia"
      },
      organizerEmail: "l.garcia@countryside.edu",
      organizerContactPhone: "+15550123456",
      bookingDate: new Date().toISOString().split('T')[0] + "",
      timeSlot: "08:30 - 11:30",
      numberOfPeople: 32,
      status: "confirmed"
    },
    
    "60d21b4667d0d8992e610d05": {
      _id: "60d21b4667d0d8992e610d05",
      tripName: "Symphony Orchestra Performance",
      grades: ["7", "8", "9"],
      tripSlipCode: "TS1020",
      departureLocation: "Kennedy Arts Academy",
      organizer: {
        firstName: "James",
        lastName: "Martin"
      },
      organizerEmail: "j.martin@kennedyarts.edu",
      organizerContactPhone: "+15551234567",
      bookingDate: new Date().toISOString().split('T')[0] + "",
      timeSlot: "12:00 - 15:00",
      numberOfPeople: 26,
      status: "confirmed"
    },
    
    "60d21b4667d0d8992e610d06": {
      _id: "60d21b4667d0d8992e610d06",
      tripName: "Natural Science Lab Tour",
      grades: ["4", "5", "6"],
      tripSlipCode: "TS1021",
      departureLocation: "Madison Elementary",
      organizer: {
        firstName: "Emily",
        lastName: "White"
      },
      organizerEmail: "e.white@madisonelem.edu",
      organizerContactPhone: "+15552345678",
      bookingDate: new Date().toISOString().split('T')[0] + "",
      timeSlot: "16:00 - 19:00",
      numberOfPeople: 20,
      status: "pending"
    }
  };
  
  // Helper function to retrieve trip info by booking ID
  export const getTripInfoById = (bookingId) => {
    return tripInfoResponses[bookingId] || null;
  };
  
  // Example usage:
  // const tripInfo = getTripInfoById('60d21b4667d0d8992e610c85');