import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import ViewSignedTripSlip from "./ViewSignedTripSlip/ViewSignedTripSlip"; // Import the modal component

const ViewTripSlip = () => {
  const { tripSlipId } = useParams();
  const [tripSlip, setTripSlip] = useState(null);
  const [openSlip, setOpenSlip] = useState(null); // Modal state for selected slip

  useEffect(() => {
    const url = process.env.REACT_APP_SERVICE_URL;
    axios
      .get(`${url}/tripslips/${tripSlipId}`)
      .then((response) => {
        const data = response.data;

        // Safely parse fields that are stored as JSON strings
        try {
          data.departureLocation =
            typeof data.departureLocation === "string"
              ? JSON.parse(data.departureLocation)
              : data.departureLocation;

          data.destinationLocation =
            typeof data.destinationLocation === "string"
              ? JSON.parse(data.destinationLocation)
              : data.destinationLocation;

          data.grades =
            typeof data.grades[0] === "string"
              ? JSON.parse(data.grades[0])
              : data.grades;

          data.signedTripSlips = data.signedTripSlips.map((slip) => ({
            ...slip,
            emergencyInformation:
              typeof slip.emergencyInformation === "string"
                ? JSON.parse(slip.emergencyInformation)
                : slip.emergencyInformation,
            childData:
              typeof slip.childData === "string"
                ? JSON.parse(slip.childData)
                : slip.childData,
            guardianAddress:
              typeof slip.guardianAddress === "string"
                ? JSON.parse(slip.guardianAddress)
                : slip.guardianAddress,
          }));
        } catch (error) {
          console.error("Error parsing JSON fields:", error);
        }

        setTripSlip(data);
      })
      .catch((error) => console.error("Error fetching trip slip:", error));
  }, [tripSlipId]);

  if (!tripSlip) {
    return <Typography variant="subtitle1">Loading...</Typography>;
  }

  const handleRowClick = (signedSlip) => {
    setOpenSlip(signedSlip); // Set the clicked slip to open the modal
  };

  const handleCloseModal = () => {
    setOpenSlip(null); // Close the modal
  };

  const formatAddress = (location) => {
    return `${location.buildingName}, ${location.streetAddress}, ${location.city}, ${location.state}, ${location.zipCode}`;
  };

  return (
    <Container maxWidth="lg">
      {/* TripSlip Details */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            {tripSlip.tripName}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Grades:</strong> {tripSlip.grades.join(", ")}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Max Number of Students:</strong> {tripSlip.maxNumOfStudents}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Trip Slip Date:</strong>{" "}
            {new Date(tripSlip.tripSlipDate).toLocaleDateString()}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Departure Location:</strong>{" "}
            {formatAddress(tripSlip.departureLocation)}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Destination Location:</strong>{" "}
            {formatAddress(tripSlip.destinationLocation)}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Trip Slip Code:</strong> {tripSlip.tripSlipCode}
          </Typography>
          {tripSlip.customTerms && (
            <Typography variant="subtitle1">
              <strong>Terms & Conditions:</strong>{" "}
              <a
                href={tripSlip.customTerms}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Document
              </a>
            </Typography>
          )}
          {tripSlip.notes && (
            <Typography variant="subtitle1">
              <strong>Notes:</strong> {tripSlip.notes}
            </Typography>
          )}
        </CardContent>
      </Card>

      {/* Signed Trip Slips Table */}
      <Typography variant="h5">Signed Trip Slips</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Guardian</TableCell>
              <TableCell>Child</TableCell>
              <TableCell>Allergies</TableCell>
              <TableCell>Emergency Contact</TableCell>
              <TableCell>Signed On</TableCell>
              <TableCell>Payment Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tripSlip.signedTripSlips.map((signedSlip, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(signedSlip)} // Add row click handler
                hover
                style={{ cursor: "pointer" }}
              >
                <TableCell>{`${signedSlip.guardianFirstName} ${signedSlip.guardianLastName}`}</TableCell>
                <TableCell>{`${signedSlip.childData.childFirstName} ${signedSlip.childData.childLastName}`}</TableCell>
                <TableCell>
                  {signedSlip.childData.childAllergies || "None"}
                </TableCell>
                <TableCell>
                  {`${signedSlip.emergencyInformation.emergencyContactFirstName} ${signedSlip.emergencyInformation.emergencyContactLastName}`}
                </TableCell>
                <TableCell>
                  {new Date(signedSlip.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {(signedSlip.payment.status === undefined) ? "PENDING" : signedSlip.payment.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal to view signed trip slip details */}
      {openSlip && (
        <ViewSignedTripSlip
          signedSlip={openSlip}
          open={Boolean(openSlip)}
          handleClose={handleCloseModal}
        />
      )}
    </Container>
  );
};

export default ViewTripSlip;
