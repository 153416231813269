import axios from 'axios';


// Create axios instance with default config
const api = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_URL || 'http://localhost:8080',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add auth token to requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


/**
 * Transforms booking data into an expanded format with additional categorized arrays
 * Adjusted to work with the specific dummy data format
 * 
 * @param {Object} bookingsData - Original bookings data with pendingBookings and todayBookings
 * @param {Array} [allBookings=[]] - Optional array of all bookings
 * @returns {Object} Expanded bookings data with additional categorized arrays
 */
export const transformBookingsData = (bookingsData, allBookings = []) => {
  // Create a new object to avoid mutating the original
  const { pendingBookings = [], todayBookings = [] } = bookingsData || {};
  
  // Combine pendingBookings and todayBookings if allBookings not provided
  const bookingsArray = allBookings.length > 0 
    ? allBookings 
    : [...pendingBookings, ...todayBookings];
  
  // Get current date for comparisons
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of day for accurate comparison
  
  // Calculate one month from today
  const oneMonthFromNow = new Date(today);
  oneMonthFromNow.setMonth(today.getMonth() + 1);
  
  // Filter upcoming month bookings
  const upcomingMonthBookings = bookingsArray.filter(booking => {
    // Handle different date formats in the data
    const bookingDate = new Date(booking.bookingDate);
    bookingDate.setHours(0, 0, 0, 0);
    
    const isUpcoming = bookingDate > today && bookingDate <= oneMonthFromNow;
    const isNotcancelled = booking.status !== 'cancelled';
    
    // Check if this isn't already in today's bookings (avoid duplication)
    const isNotToday = !todayBookings.some(todayBooking => 
      todayBooking._id === booking._id
    );
    
    return isUpcoming && isNotcancelled && isNotToday;
  });
  
  // Filter cancelled bookings
  const cancelledBookings = bookingsArray.filter(booking => 
    booking.status === 'cancelled'
  );
  
  return {
    pendingBookings,
    todayBookings,
    upcomingMonthBookings,
    cancelledBookings,
    allBookings: bookingsArray
  };
};


/**
 * Enhanced version of transformBookingsData that categorizes a single array of bookings
 * into the different categories
 * 
 * @param {Array} bookings - Array of all booking objects
 * @returns {Object} Categorized bookings data
 */
export const categorizeBookings = (bookings = []) => {
  // Get current date for comparisons
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of day for accurate comparison
  
  // Calculate one month from today
  const oneMonthFromNow = new Date(today);
  oneMonthFromNow.setMonth(today.getMonth() + 1);
  
  // Categorize bookings
  const pendingBookings = bookings.filter(booking => 
    booking.status === 'pending'
  );
  
  const todayBookings = bookings.filter(booking => {
    const bookingDate = new Date(booking.bookingDate);
    bookingDate.setHours(0, 0, 0, 0);
    return bookingDate.getTime() === today.getTime() && booking.status !== 'cancelled';
  });
  
  const upcomingMonthBookings = bookings.filter(booking => {
    const bookingDate = new Date(booking.bookingDate);
    bookingDate.setHours(0, 0, 0, 0);
    
    return bookingDate > today && 
           bookingDate <= oneMonthFromNow && 
           booking.status !== 'cancelled' &&
           !todayBookings.includes(booking); // Ensure no overlap with today's bookings
  });
  
  const cancelledBookings = bookings.filter(booking => 
    booking.status === 'cancelled'
  );
  
  return {
    pendingBookings,
    todayBookings,
    upcomingMonthBookings,
    cancelledBookings,
    allBookings: bookings
  };
};

// Vendor API service
export const vendorService = {
  // Get all vendors
  getAllVendors: async () => {
    try {
      const response = await api.get('/vendorInformation');
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get vendor by ID
  getVendorById: async (id) => {
    try {
      const response = await api.get(`/vendorInformation/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Create new vendor
  createVendor: async (vendorData) => {
    // Using FormData since this endpoint handles file uploads
    const formData = new FormData();
    
    // Add regular fields
    formData.append('name', vendorData.name);
    formData.append('type', vendorData.type);
    formData.append('businessDescription', vendorData.businessDescription);
    formData.append('businessEmail', vendorData.businessEmail);
    formData.append('businessPhoneNumber', vendorData.businessPhoneNumber);
    formData.append('venueCapacity', vendorData.venueCapacity);
    formData.append('userId', vendorData.userId);
    
    // Add complex objects as JSON strings
    formData.append('businessLocation', JSON.stringify(vendorData.businessLocation));
    formData.append('weeklyAvailability', JSON.stringify(vendorData.weeklyAvailability));
    
    // Add files
    if (vendorData.logoFile) {
      formData.append('logoFile', vendorData.logoFile);
    }
    
    if (vendorData.businessMediaFiles && vendorData.businessMediaFiles.length) {
      vendorData.businessMediaFiles.forEach(file => {
        formData.append('businessMediaFiles', file);
      });
    }
    
    try {
      const response = await api.post('/vendorInformation', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Update vendor
  updateVendor: async (id, vendorData) => {
    // Using FormData since this endpoint handles file uploads
    const formData = new FormData();
    
    // Add regular fields if they exist
    if (vendorData.name) formData.append('name', vendorData.name);
    if (vendorData.type) formData.append('type', vendorData.type);
    if (vendorData.businessDescription) formData.append('businessDescription', vendorData.businessDescription);
    if (vendorData.businessEmail) formData.append('businessEmail', vendorData.businessEmail);
    if (vendorData.businessPhoneNumber) formData.append('businessPhoneNumber', vendorData.businessPhoneNumber);
    if (vendorData.venueCapacity) formData.append('venueCapacity', vendorData.venueCapacity);
    
    // Add complex objects as JSON strings
    if (vendorData.businessLocation) {
      formData.append('businessLocation', JSON.stringify(vendorData.businessLocation));
    }
    
    if (vendorData.weeklyAvailability) {
      formData.append('weeklyAvailability', JSON.stringify(vendorData.weeklyAvailability));
    }
    
    // Add files
    if (vendorData.logoFile) {
      formData.append('logoFile', vendorData.logoFile);
    }
    
    if (vendorData.businessMediaFiles && vendorData.businessMediaFiles.length) {
      vendorData.businessMediaFiles.forEach(file => {
        formData.append('businessMediaFiles', file);
      });
    }
    
    try {
      const response = await api.put(`/vendorInformation/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Delete business media
  deleteBusinessMedia: async (id, imageUrl, mediaIndex) => {
    try {
      const response = await api.delete(`/vendorInformation/${id}/media`, {
        data: { imageUrl, mediaIndex }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

// Bookings API service
export const bookingService = {
  // Get all bookings for a vendor
  getVendorDashboardData: async (vendorId, token) => {
    try {
      const response = await api.get(`/users/dashboard`,  {
        headers: { Authorization: `Bearer ${token}` }
      });
      // return transformBookingsData(response.data);
      console.log(response.data)
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // Get all bookings for a vendor
  getVendorBookings: async (vendorId) => {
    try {
      const response = await api.get(`/bookings/vendor/${vendorId}`);
      return transformBookingsData(response.data);
    } catch (error) {
      throw error;
    }
  },

  // Get pending bookings for a vendor
  getPendingVendorBookings: async (vendorId) => {
    try {
      const response = await api.get(`/bookings/vendor/${vendorId}/pending`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Get trip info for a booking
  getBookingTripInfo: async (bookingId) => {
    try {
      const response = await api.get(`/bookings/${bookingId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Submit action on booking (approve, deny, pend)
  submitBookingAction: async (bookingId, action) => {
    try {
      const response = await api.put(`/bookings/action/${bookingId}`, { action });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

// Auth service example - expand as needed
export const authService = {
  login: async (credentials) => {
    try {
      const response = await api.post('/auth/login', credentials);
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  
  logout: () => {
    localStorage.removeItem('token');
  },
  
  getCurrentUser: () => {
    const token = localStorage.getItem('token');
    if (token) {
      // You could implement JWT decoding here to get user info
      return true;
    }
    return null;
  }
};

// Helper function for handling API errors
export const handleApiError = (error) => {
  const message = 
    error.response?.data?.message ||
    error.message ||
    'Something went wrong';
    
  const status = error.response?.status;
  
  return {
    message,
    status
  };
};

export default api;