import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CreateTripSlipInfoForBooking from "./CreateTripSlipInfoForBooking";
import CreateTripSlipForm from "./CreateTripSlipForm";
import ConfirmTripSlipBooking from "./ConfirmTripSlipBooking";
import OrganizerAccessControl from "../AccessControls/organizerAccessControl";
import { styled } from "@mui/system";
import EditBookingForm from "../EditBookingForm/EditBookingForm";

// eslint-disable-next-line
const Logo = styled("img")(({ theme }) => ({
  height: "100px",
  marginRight: "16px",
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    marginRight: "8px",
  },
}));

const CreateTripSlipScreen = () => {
  OrganizerAccessControl();
  // Set default screen to createSlip
  const [currentScreen, setCurrentScreen] = useState("createSlip");
  const [tripInfo, setTripInfo] = useState({});
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  // eslint-disable-next-line
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    console.log(isEditing);
  }, [isEditing]);

  const handleNextFromTripInfo = (tripInfo) => {
    console.log(tripInfo);
    setTripInfo(tripInfo);
  };

  const handleSelectVendor = (selection) => {
    console.log(selection, tripInfo);
    setSelectedVendor(selection.vendor);
    setTripInfo({
      ...tripInfo,
      startTime: selection.startTime,
      endTime: selection.endTime,
      vendorId: selection.vendor._id,
    });
    setCurrentScreen("confirmBooking");
  };

  const onCancel = () => {
    setIsEditing(false);
  };

  return (
    <Box sx={{ p: { xs: 1, md: 4 } }}>
      {currentScreen === "bookTrip" && (
        <CreateTripSlipInfoForBooking
          setTripInfo={setTripInfo}
          tripInfo={tripInfo}
          onVendorSelect={handleSelectVendor}
          onNext={handleNextFromTripInfo}
        />
      )}

      {currentScreen === "createSlip" && <CreateTripSlipForm />}

      {currentScreen === "confirmBooking" &&
        (isEditing ? (
          <EditBookingForm
            setIsEditing={setIsEditing}
            tripInfo={tripInfo}
            vendor={selectedVendor}
            onSave={handleNextFromTripInfo}
            onCancel={onCancel}
          />
        ) : (
          <ConfirmTripSlipBooking
            setIsEditing={setIsEditing}
            tripInfo={tripInfo}
            vendor={selectedVendor}
          />
        ))}
    </Box>
  );
};

export default CreateTripSlipScreen;
