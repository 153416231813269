/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Grid,
  Paper,
  Stack,
  Breadcrumbs
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  CalendarMonth as CalendarMonthIcon,
  Info as InfoIcon,
  AccessTime as AccessTimeIcon,
  FilterAlt as FilterAltIcon,
  Download as DownloadIcon,
  Circle as CircleIcon,
  NavigateNext
} from '@mui/icons-material';
import BookingCalendar from '../Dashboards/VendorDashboard/BookingsCalendar/BookingsCalendar';
import { Link, useNavigate } from 'react-router-dom';

const ViewBookingsCalendar = ({ data }) => {
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate();

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  // Calculate total bookings
  const totalBookings = data?.allBookings?.length || 0;
  const pendingCount = data?.pendingBookings?.length || 0;
  const confirmedCount = data?.allBookings?.filter(booking => 
    booking.status?.toLowerCase() === 'confirmed'
  ).length || 0;
  const cancelledCount = data?.cancelledBookings?.length || 0;

  return (
    <Box>
      <Container sx={{py: 4}}>

        {/* Breadcrumbs Navigation */}
      <Breadcrumbs 
        separator={<NavigateNext fontSize="small" />} 
        aria-label="breadcrumb"
        sx={{ mb: 3 }}
      >
        <Link 
          color="inherit" 
          href="#" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/vendor-dashboard');
          }}
        >
          Dashboard
        </Link>
        <Typography color="text.primary">Calendar</Typography>
      </Breadcrumbs>
        <Box>
          <Typography variant='h4' gutterBottom>
            Bookings Calendar
          </Typography>
          
          <Accordion 
            expanded={expanded} 
            onChange={handleAccordionChange}
            sx={{ 
              mb: 3, 
              boxShadow: 'none',
              border: '1px solid #e0e0e0',
              '&:before': { display: 'none' },
              borderRadius: '8px'
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="bookings-calendar-content"
              id="bookings-calendar-header"
              sx={{ 
                backgroundColor: expanded ? 'rgba(0, 0, 0, 0.03)' : 'transparent',
                borderRadius: '8px'
              }}
            >
              <Box display="flex" alignItems="center">
                <InfoIcon sx={{ color: 'primary.main', mr: 1 }} />
                <Typography>
                  Calendar Overview and Usage Guide
                </Typography>
              </Box>
            </AccordionSummary>
            
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                  <Typography variant="subtitle1" fontWeight="medium" gutterBottom>
                    About This Calendar
                  </Typography>
                  <Typography variant="body2" paragraph>
                    This calendar view shows all your bookings organized by date. You can easily see confirmed, 
                    pending, and cancelled bookings at a glance with our color-coded system.
                  </Typography>
                  <Typography variant="body2" paragraph>
                    Click on any day with bookings to view detailed information about the scheduled events. 
                    You can also filter bookings by status and export your schedule to your personal calendar.
                  </Typography>
                  
                  <Typography variant="subtitle1" fontWeight="medium" gutterBottom sx={{ mt: 2 }}>
                    How to Use This Calendar
                  </Typography>
                  <Box component="ul" sx={{ pl: 2 }}>
                    <Typography component="li" variant="body2">
                      <strong>Navigate</strong>: Use the arrow buttons to move between months
                    </Typography>
                    <Typography component="li" variant="body2">
                      <strong>Filter</strong>: Click the <FilterAltIcon fontSize="small" sx={{ verticalAlign: 'middle' }} /> icon to filter by booking status
                    </Typography>
                    <Typography component="li" variant="body2">
                      <strong>View Details</strong>: Click on any day with colored indicators to see bookings for that day
                    </Typography>
                    <Typography component="li" variant="body2">
                      <strong>Export</strong>: Use the <DownloadIcon fontSize="small" sx={{ verticalAlign: 'middle' }} /> icon to export your bookings as an ICS file
                    </Typography>
                  </Box>
                </Grid>
                
                <Grid item xs={12} md={5}>
                  <Paper elevation={0} sx={{ p: 2, bgcolor: 'background.default', border: '1px solid #e0e0e0', borderRadius: 2 }}>
                    <Typography variant="subtitle1" fontWeight="medium" gutterBottom>
                      Booking Statistics
                    </Typography>
                    
                    <Stack spacing={2} mt={2}>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                          <CalendarMonthIcon sx={{ mr: 1, color: 'primary.main' }} />
                          <Typography variant="body2">Total Bookings</Typography>
                        </Box>
                        <Chip label={totalBookings} color="primary" size="small" />
                      </Box>
                      
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                          <CircleIcon sx={{ mr: 1, color: 'success.main', fontSize: '0.8rem' }} />
                          <Typography variant="body2">Confirmed</Typography>
                        </Box>
                        <Chip label={confirmedCount} color="success" size="small" />
                      </Box>
                      
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                          <CircleIcon sx={{ mr: 1, color: 'warning.main', fontSize: '0.8rem' }} />
                          <Typography variant="body2">Pending</Typography>
                        </Box>
                        <Chip label={pendingCount} color="warning" size="small" />
                      </Box>
                      
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                          <CircleIcon sx={{ mr: 1, color: 'error.main', fontSize: '0.8rem' }} />
                          <Typography variant="body2">Cancelled</Typography>
                        </Box>
                        <Chip label={cancelledCount} color="error" size="small" />
                      </Box>
                    </Stack>
                    
                    <Box mt={3} pt={2} borderTop="1px solid #e0e0e0">
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Color Legend
                      </Typography>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center">
                            <Box width={12} height={12} bgcolor="success.main" borderRadius="50%" mr={1} />
                            <Typography variant="caption">Confirmed</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center">
                            <Box width={12} height={12} bgcolor="warning.main" borderRadius="50%" mr={1} />
                            <Typography variant="caption">Pending</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center">
                            <Box width={12} height={12} bgcolor="error.main" borderRadius="50%" mr={1} />
                            <Typography variant="caption">Cancelled</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display="flex" alignItems="center">
                            <Box width={12} height={12} bgcolor="#e3f2fd" borderRadius="3px" mr={1} />
                            <Typography variant="caption">Today</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        
        <BookingCalendar data={data} />
      </Container>
    </Box>
  );
};

export default ViewBookingsCalendar;