import React, { useState } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import {
  Box,
  Container,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Paper,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { Check, Clear, Visibility, VisibilityOff } from '@mui/icons-material';
import './Signup.css';

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    contactPhoneNumber: '',
    role: 'organizer', // Default to organizer
  });
  
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    contactPhoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    general: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  let navigate = useNavigate();

  // Role-specific content
  const roleContent = {
    organizer: {
      title: "Organizer Account Benefits",
      description: "Join TripSlip and transform how you manage field trips with our comprehensive platform designed for educators and trip organizers.",
      benefits: [
        {
          title: "Digital Permission Slips",
          description: "Create and collect permission slips electronically, eliminating paper forms and tracking responses in real-time."
        },
        {
          title: "Verified Venues",
          description: "Browse and book pre-vetted, educational venues that align with your curriculum objectives."
        },
        {
          title: "Comprehensive Trip Management",
          description: "Organize all aspects of your trips in one place - from planning to post-trip reviews."
        },
        {
          title: "Student Health Information",
          description: "Access important allergy information and medical needs securely during trips."
        },
        {
          title: "Emergency Contact Management",
          description: "Keep all emergency contact information organized and easily accessible when needed."
        }
      ]
    },
    vendor: {
      title: "Vendor Account Benefits",
      description: "Join TripSlip and connect with educators looking for quality venues and experiences for their students.",
      benefits: [
        {
          title: "Increased Visibility",
          description: "Showcase your venue to thousands of educators looking for field trip destinations."
        },
        {
          title: "Streamlined Booking",
          description: "Manage reservations easily with our intuitive calendar and booking system."
        },
        {
          title: "Educational Profiles",
          description: "Highlight the educational value of your venue with curriculum alignment tools."
        },
        {
          title: "Direct Communication",
          description: "Connect directly with organizers to answer questions and provide information."
        },
        {
          title: "Analytics Dashboard",
          description: "Track visits, popular programs, and feedback to optimize your offerings."
        }
      ]
    }
  };

  // Validation functions
  const validateName = (name) => {
    return /^[A-Za-z\s-']{2,50}$/.test(name);
  };

  const validatePhone = (phone) => {
    // Basic US phone format validation (10 digits)
    return /^\d{10}$/.test(phone.replace(/\D/g, ''));
  };

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    
    // Clear the specific error when user starts typing again
    setErrors(prev => ({
      ...prev,
      [name]: ''
    }));
  
    if (name === "password") {
      setPasswordValid(validatePassword(value)); // Check password strength
    }
  
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  // Password validation function
  const validatePassword = (password) => {
    const minLength = 8;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/;
    return password.length >= minLength && passwordRegex.test(password);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const passwordsMatch = formData.password && formData.confirmPassword && formData.password === formData.confirmPassword;
  const enableSubmit = isTermsChecked && passwordsMatch && formData.password && formData.email && passwordValid;
  
  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!formData.firstName) {
      newErrors.firstName = 'First name is required';
      isValid = false;
    } else if (!validateName(formData.firstName)) {
      newErrors.firstName = 'Please enter a valid first name (letters, hyphens, and apostrophes only)';
      isValid = false;
    }

    if (!formData.lastName) {
      newErrors.lastName = 'Last name is required';
      isValid = false;
    } else if (!validateName(formData.lastName)) {
      newErrors.lastName = 'Please enter a valid last name (letters, hyphens, and apostrophes only)';
      isValid = false;
    }

    if (!formData.contactPhoneNumber) {
      newErrors.contactPhoneNumber = 'Contact phone is required';
      isValid = false;
    } else if (!validatePhone(formData.contactPhoneNumber)) {
      newErrors.contactPhoneNumber = 'Please enter a valid 10-digit phone number';
      isValid = false;
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (!passwordValid) {
      newErrors.password = 'Password does not meet requirements';
      isValid = false;
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Please confirm your password';
      isValid = false;
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      isValid = false;
    }

    if (!isTermsChecked) {
      newErrors.general = 'You must accept the Terms & Conditions';
      isValid = false;
    }

    setErrors(prev => ({ ...prev, ...newErrors }));
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    if (!isTermsChecked || formData.password !== formData.confirmPassword) {
      setErrors(prev => ({
        ...prev,
        general: 'Please ensure passwords match and accept Terms & Conditions.'
      }));
      return;
    }

    try {
      // Format phone number to remove any non-digit characters before sending
      const formattedData = {
        ...formData,
        contactPhoneNumber: formData.contactPhoneNumber.replace(/\D/g, '')
      };
      
      const response = await Axios.post(`${process.env.REACT_APP_SERVICE_URL}/users`, formattedData);
      if (response.status === 201) {
        navigate('/welcome', { state: { email: formData.email, role: formData.role } });
      } else {
        setErrors(prev => ({
          ...prev,
          general: 'Cannot create your account at this time.'
        }));
      }
    } catch (error) {
      setErrors(prev => ({
        ...prev,
        general: error.response?.data?.message || 'There was an error submitting the form.'
      }));
    }
  };

  const handleTermsClick = (e) => {
    e.preventDefault(); // Prevent default if used with an anchor tag
    window.open('/terms-and-conditions', '_blank', 'noopener,noreferrer');
  };

  return (
    <Box sx={{ 
      background: '#FFDA44', 
      minHeight: '100vh', 
      py: 4 
    }}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={4} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
              <img src={logo} alt="TripSlip Logo" style={{ height: '80px' }} />
            </Grid>
            <Grid item xs={12} sm={8} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
              <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold' }}>
                Join TripSlip Today!
              </Typography>
            </Grid>
          </Grid>
        </Box>
        
        <Grid container spacing={4}>
          {/* Left Card - Benefits */}
          <Grid item xs={12} md={5}>
            <Paper sx={{ p: 4, height: '100%' }}>
              <Typography variant="h4" gutterBottom>
                {roleContent[formData.role].title}
              </Typography>
              
              <Typography paragraph>
                {roleContent[formData.role].description}
              </Typography>
              
              <Box sx={{ mt: 4 }}>
                {roleContent[formData.role].benefits.map((benefit, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', mb: 3 }}>
                    <Box 
                      sx={{ 
                        mr: 2, 
                        minWidth: 40, 
                        height: 40, 
                        borderRadius: '4px',
                        background: '#f0f0f0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body1">📋</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                        {benefit.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {benefit.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Paper>
          </Grid>
          
          {/* Right Card - Form */}
          <Grid item xs={12} md={7}>
            <Paper sx={{ p: 4 }}>
              <Box component="form" onSubmit={handleSubmit}>
                {errors.general && (
                  <Typography color="error" sx={{ mb: 2 }}>
                    {errors.general}
                  </Typography>
                )}
                
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <InputLabel id="role-label">Account Type</InputLabel>
                      <Select
                        labelId="role-label"
                        name="role"
                        value={formData.role}
                        onChange={handleInputChange}
                        label="Account Type"
                      >
                        <MenuItem value="organizer">Organizer</MenuItem>
                        <MenuItem value="vendor">Vendor</MenuItem>
                        {/* Uncomment if needed later */}
                        {/* <MenuItem value="guardian">Guardian</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField 
                      label="First Name" 
                      name="firstName" 
                      fullWidth 
                      required
                      value={formData.firstName}
                      onChange={handleInputChange} 
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                      InputProps={{
                        endAdornment: formData.firstName && (
                          <InputAdornment position="end">
                            {validateName(formData.firstName) ? <Check color="success" /> : <Clear color="error" />}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField 
                      label="Last Name" 
                      name="lastName" 
                      fullWidth 
                      required
                      value={formData.lastName}
                      onChange={handleInputChange} 
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                      InputProps={{
                        endAdornment: formData.lastName && (
                          <InputAdornment position="end">
                            {validateName(formData.lastName) ? <Check color="success" /> : <Clear color="error" />}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <TextField 
                      label="Contact Phone Number" 
                      name="contactPhoneNumber" 
                      fullWidth 
                      required
                      value={formData.contactPhoneNumber}
                      onChange={handleInputChange} 
                      error={!!errors.contactPhoneNumber}
                      helperText={errors.contactPhoneNumber || "Enter a 10-digit phone number"}
                      placeholder="1234567890"
                      InputProps={{
                        endAdornment: formData.contactPhoneNumber && (
                          <InputAdornment position="end">
                            {validatePhone(formData.contactPhoneNumber) ? <Check color="success" /> : <Clear color="error" />}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <TextField 
                      label="Email" 
                      name="email" 
                      fullWidth 
                      required
                      type="email" 
                      value={formData.email}
                      onChange={handleInputChange}
                      error={!!errors.email}
                      helperText={errors.email} 
                      InputProps={{
                        endAdornment: formData.email && (
                          <InputAdornment position="end">
                            {validateEmail(formData.email) ? <Check color="success" /> : <Clear color="error" />}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <TextField
                      label="Password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      required
                      fullWidth
                      value={formData.password}
                      onChange={handleInputChange}
                      error={!!errors.password}
                      helperText={errors.password || (formData.password && !passwordValid ? 
                        "Must be at least 8 characters, with one uppercase letter, one lower case letter, one number, and one special character." : "")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            {formData.password.length > 0 && (
                              passwordValid ? (
                                <Check color="success" />
                              ) : (
                                <Clear color="error" />
                              )
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <TextField 
                      label="Confirm Password" 
                      name="confirmPassword" 
                      fullWidth 
                      required
                      type={showConfirmPassword ? 'text' : 'password'}
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      error={!!errors.confirmPassword}
                      helperText={errors.confirmPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleToggleConfirmPasswordVisibility} edge="end">
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            {formData.confirmPassword.length > 0 && (
                              passwordsMatch ? (
                                <Check color="success" />
                              ) : (
                                <Clear color="error" />
                              )
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox 
                          checked={isTermsChecked} 
                          onChange={(e) => setIsTermsChecked(e.target.checked)} 
                        />
                      }
                      label={
                        <Typography variant="body2">
                          I accept the <Link onClick={handleTermsClick}>Terms of Service and Privacy Policy</Link>
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
                
                <Button 
                  type="submit" 
                  variant="contained" 
                  fullWidth 
                  size="large"
                  disabled={!enableSubmit}
                  sx={{ mt: 3, py: 1.5, fontWeight: 'bold', fontSize: '1rem' }}
                >
                  CREATE AN ACCOUNT
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Signup;