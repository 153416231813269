// DashboardDrawer.js
import React from 'react';
import { Typography, Drawer, List, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';

export default function DashboardDrawer({ open, toggleDrawer }) {

  const menuItems = [
    { text: 'Home', path: '/vendor-dashboard' },
    { text: 'Vendor Information', path: '/vendor-information'}, 
    { text: 'Pending Bookings', path: '/pending-bookings'},
    { text: 'View All Bookings', path: '/view-bookings'},
    { text: 'Edit Profile', path: '/edit-vendor'}
  ];


  return (
    <>
      {/* <AppBar position="static" className="appBar" sx={{ backgroundColor: 'black', color: 'white' }} >
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">TripSlip Vendor Portal</Typography>
          <div style={{ flexGrow: 1 }}></div>
          <Button color="inherit" onClick={handleLogout}>
            <ExitToAppIcon />
            Logout
          </Button>
        </Toolbar>
      </AppBar> */}
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)} sx={{ '& .MuiDrawer-paper': { backgroundColor: 'black', color: 'white' }}}>
        <div
          className="list"
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item) => (
              <ListItemButton button key={item.text} component={Link} to={item.path}>
                <Typography variant="subtitle1">{item.text}</Typography>
              </ListItemButton>
            ))}
          </List>
        </div>
      </Drawer>
    </>
  );
}