import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import axios from 'axios';
import dayjs from 'dayjs';

const EditVendorInformation = () => {
  const [vendorInfo, setVendorInfo] = useState({
    name: '',
    type: '',
    businessDescription: '',
    businessEmail: '',
    businessPhoneNumber: '',
    venueCapacity: '',
    businessLocation: {
      streetAddress: '',
      city: '',
      state: '',
      zipCode: '',
    },
    weeklyAvailability: {
      Monday: { startTime: null, endTime: null, isClosed: false },
      Tuesday: { startTime: null, endTime: null, isClosed: false },
      Wednesday: { startTime: null, endTime: null, isClosed: false },
      Thursday: { startTime: null, endTime: null, isClosed: false },
      Friday: { startTime: null, endTime: null, isClosed: false },
      Saturday: { startTime: null, endTime: null, isClosed: false },
      Sunday: { startTime: null, endTime: null, isClosed: false },
    },
  });
  const [logoFile, setLogoFile] = useState(null);
  const [businessMediaFiles, setBusinessMediaFiles] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = process.env.REACT_APP_SERVICE_URL;
  const vendorId = sessionStorage.getItem('vendorId');

  useEffect(() => {
    console.log(vendorId, url)
    if (vendorId) {
      axios
        .get(`${url}/vendorinformation/${vendorId}`)
        .then((response) => {
          if (Object.keys(response.data).length) {
            setVendorInfo(response.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching vendor information:', error);
        });
    }
  }, [url, vendorId]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const userId = sessionStorage.getItem('userId');
    const formData = new FormData();

    formData.append('userId', userId);

    Object.entries(vendorInfo).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    if (logoFile) {
      formData.append('logoFile', logoFile);
    }
    businessMediaFiles.forEach((file, index) =>
      formData.append(`businessMediaFiles`, file)
    );

    try {
      const response = await axios({
        method: vendorId ? 'put' : 'post',
        url: vendorId ? `${url}/vendorinformation/${vendorId}` : `${url}/vendorinformation`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      console.log(response)

      if (response.status === 201) {
        sessionStorage.setItem('vendorId', response.data._id);
        setOpenModal(true);
      } else if (response.status === 200) {
        setOpenModal(true);
      }
    } catch (error) {
      console.error('Error saving vendor information:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.includes('businessLocation.')) {
      const key = name.split('.')[1];
      setVendorInfo((prev) => ({
        ...prev,
        businessLocation: {
          ...prev.businessLocation,
          [key]: value,
        },
      }));
    } else {
      setVendorInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    setHasChanges(true);
  };

  const handleLogoUpload = (e) => {
    setLogoFile(e.target.files[0]);
    setHasChanges(true);
  };

  const handleMediaUpload = (e) => {
    setBusinessMediaFiles([...e.target.files]);
    setHasChanges(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    window.location.reload();
  };

  const handleWeeklyAvailabilityChange = (day, field, value) => {
    if (value) {
      const dayjsValue = dayjs(value);
      setVendorInfo((prevInfo) => ({
        ...prevInfo,
        weeklyAvailability: {
          ...prevInfo.weeklyAvailability,
          [day]: {
            ...prevInfo.weeklyAvailability[day],
            [field]: dayjsValue,
          },
        },
      }));
      setHasChanges(true);
    }
  };

  const handleClosedChange = (day) => {
    setVendorInfo((prevInfo) => ({
      ...prevInfo,
      weeklyAvailability: {
        ...prevInfo.weeklyAvailability,
        [day]: {
          ...prevInfo.weeklyAvailability[day],
          isClosed: !prevInfo.weeklyAvailability[day].isClosed,
        },
      },
    }));
    setHasChanges(true);
  };

  return (
    <div>
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography variant="h4" component="h1" align="center" sx={{ mb: 4 }}>
          Edit Vendor Information
        </Typography>
        <Card sx={{ p: 4 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Basic Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Business Name"
                  name="name"
                  autoComplete="name"
                  onChange={handleInputChange}
                  value={vendorInfo.name}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel id="type-label">Business Type</InputLabel>
                <Select
                  labelId="type-label"
                  id="type"
                  name="type"
                  value={vendorInfo.type}
                  onChange={handleInputChange}
                  label="Business Type"
                  fullWidth
                >
                  <MenuItem value="Venue">Venue</MenuItem>
                  <MenuItem value="Experience">Experience</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="businessDescription"
                  label="Business Description"
                  name="businessDescription"
                  autoComplete="businessDescription"
                  multiline
                  rows={4}
                  onChange={handleInputChange}
                  value={vendorInfo.businessDescription}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ p: 4, mt: 4 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Contact Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="businessEmail"
                  label="Business Email"
                  name="businessEmail"
                  autoComplete="email"
                  onChange={handleInputChange}
                  value={vendorInfo.businessEmail}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="businessPhoneNumber"
                  label="Business Phone Number"
                  name="businessPhoneNumber"
                  autoComplete="tel"
                  onChange={handleInputChange}
                  value={vendorInfo.businessPhoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="venueCapacity"
                  label="Venue Capacity"
                  name="venueCapacity"
                  type="number"
                  onChange={handleInputChange}
                  value={vendorInfo.venueCapacity}
                />
              </Grid>
              {Object.keys(vendorInfo.businessLocation).map((key) => (
                <Grid item xs={12} key={key}>
                  <TextField
                    required
                    fullWidth
                    id={key}
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    name={`businessLocation.${key}`}
                    autoComplete={key}
                    onChange={handleInputChange}
                    value={vendorInfo.businessLocation[key]}
                  />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ p: 4, mt: 4 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Media Upload
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Upload Logo</Typography>
                <input type="file" onChange={handleLogoUpload} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Upload Business Media Files</Typography>
                <input type="file" multiple onChange={handleMediaUpload} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ p: 4, mt: 4 }}>
          <CardContent>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Weekly Availability</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Object.entries(vendorInfo.weeklyAvailability).map(([day, availability]) => (
                  <Grid container key={day} spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle1">{day}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={availability.isClosed}
                            onChange={() => handleClosedChange(day)}
                          />
                        }
                        label="Closed"
                      />
                    </Grid>
                    {!availability.isClosed && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <TimePicker
                            label="Start Time"
                            value={availability.startTime ? dayjs(availability.startTime) : null}
                            onChange={(newValue) =>
                              handleWeeklyAvailabilityChange(day, 'startTime', newValue)
                            }
                            renderInput={(params) => <TextField {...params} fullWidth />}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TimePicker
                            label="End Time"
                            value={availability.endTime ? dayjs(availability.endTime) : null}
                            onChange={(newValue) =>
                              handleWeeklyAvailabilityChange(day, 'endTime', newValue)
                            }
                            renderInput={(params) => <TextField {...params} fullWidth />}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                ))}
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
        <Grid container justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!hasChanges || loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </Grid>
      </Container>
      <Dialog open={openModal} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>Information saved successfully.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditVendorInformation;