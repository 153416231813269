import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline, Box } from '@mui/material';
import {tripSlipLightTheme } from './theme';
import Header from './components/Header/Header';
import AppRouter from './routes';
function App() {
  // const [isDarkMode, setIsDarkMode] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  

  // Simplified toggle function
  const toggleDrawer = () => {
    setDrawerOpen(prev => !prev);
  };
  
  // For debugging purposes
  useEffect(() => {
  }, []);

  return (

    <ThemeProvider theme={tripSlipLightTheme}>
      <CssBaseline />
      <Router>
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
          <Header toggleDrawer={toggleDrawer} open={drawerOpen} />
          <Box sx={{ flex: 1, pt: '64px' }}>
            <AppRouter drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;