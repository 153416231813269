/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Typography,
  Button,
  Chip,
  List,
  ListItem,
  Tooltip,
  Menu,
  MenuItem,
  CircularProgress
} from '@mui/material';
import {
  CalendarMonth as CalendarMonthIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  AccessTime as AccessTimeIcon,
  Group as GroupIcon,
  FilterAlt as FilterAltIcon,
  School as SchoolIcon,
  Download as DownloadIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { formatTimeSlot } from '../../../Bookings/ViewBooking/ViewBooking';

const BookingCalendar = ({ data }) => {
  // Use the data prop which has the format:
  // {
  //   pendingBookings: [],
  //   upcomingMonthBookings: [],
  //   todayBookings: [],
  //   cancelledBookings: [],
  //   allBookings: []
  // }
  
  // State for current date, selected date and month navigation
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [bookingsForDay, setBookingsForDay] = useState([]);
  const [statusFilter, setStatusFilter] = useState({
    confirmed: true,
    pending: true,
    cancelled: true
  });
  const [filterOpen, setFilterOpen] = useState(false);
  const [exportMenuAnchor, setExportMenuAnchor] = useState(null);
  const [organizers, setOrganizers] = useState({});
  const [isLoadingOrganizers, setIsLoadingOrganizers] = useState(false);
  
  const navigate = useNavigate();
  const url = process.env.REACT_APP_SERVICE_URL;
  
  // Days of the week
  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  
  // Status colors and definitions
  const statusConfig = {
    confirmed: { color: 'success', bgColor: '#e8f5e9', chipColor: 'success' },
    pending: { color: 'warning', bgColor: '#fff8e1', chipColor: 'warning' },
    cancelled: { color: 'error', bgColor: '#ffebee', chipColor: 'error' }
  };

  // Process organizer info from bookings data
  useEffect(() => {
    // No need to fetch organizers since they're included in the bookings data
    if (!data || !data.allBookings || data.allBookings.length === 0) return;
    
    const organizersData = {};
    
    // Process organizer info from bookings
    data.allBookings.forEach(booking => {
      if (booking.organizer && booking.organizerId) {
        organizersData[booking.organizerId] = {
          _id: booking.organizerId,
          name: `${booking.organizer.firstName} ${booking.organizer.lastName}`,
          firstName: booking.organizer.firstName,
          lastName: booking.organizer.lastName,
          email: booking.organizer.email || booking.organizerEmail || '',
          contactPhoneNumber: booking.organizer.contactPhoneNumber || booking.organizerContactPhone || '',
          location: booking.organizer.location || null
        };
      }
    });
    
    setOrganizers(organizersData);
    setIsLoadingOrganizers(false);
    
  }, [data]);
  
  // Get organizer name from booking
  const getOrganizerName = (booking) => {
    // First try to get from organizers object (already processed)
    if (booking.organizerId && organizers[booking.organizerId]) {
      return organizers[booking.organizerId].name;
    }
    
    // Then try to get directly from the booking's organizer property
    if (booking.organizer) {
      return `${booking.organizer.firstName} ${booking.organizer.lastName}`;
    }
    
    // Fallback
    return "Unknown Organizer";
  };
  
  // Convert 24h time to 12h AM/PM format
  const formatTime = (time24h) => {
    if (!time24h) return '';
    
    // Handle if timeSlot is already formatted as "HH:MM - HH:MM"
    if (typeof time24h === 'string' && time24h.includes(' - ')) {
      return time24h;
    }
    
    // Parse hour and minute from 24h format
    const [hour, minute] = time24h.split(':').map(Number);
    
    // Determine period
    const period = hour >= 12 ? 'PM' : 'AM';
    
    // Convert hour to 12h format
    const hour12 = hour % 12 || 12; // 0 should be displayed as 12
    
    // Return formatted time
    return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
  };
  
  // Handle filter change
  const handleFilterChange = (event) => {
    setStatusFilter({
      ...statusFilter,
      [event.target.name]: event.target.checked
    });
  };
  
  // Toggle filter dialog
  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };
  
  // Get filtered bookings from all bookings
  const getFilteredBookings = () => {
    if (!data || !data.allBookings || !Array.isArray(data.allBookings)) {
      return [];
    }
    
    return data.allBookings.filter(booking => {
      const status = booking.status ? booking.status.toLowerCase() : '';
      return statusFilter[status];
    });
  };
  
  // Generate calendar days for the current month
  const generateCalendarDays = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    
    // First day of the month
    const firstDayOfMonth = new Date(year, month, 1);
    const dayOfWeek = firstDayOfMonth.getDay();
    
    // Last day of the month
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const totalDays = lastDayOfMonth.getDate();
    
    // Current date for highlighting
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const days = [];
    
    // Add empty slots for days before the first day of the month
    for (let i = 0; i < dayOfWeek; i++) {
      days.push(null);
    }
    
    // Add days of the month
    for (let day = 1; day <= totalDays; day++) {
      const date = new Date(year, month, day);
      date.setHours(0, 0, 0, 0);
      
      const isToday = 
        date.getDate() === today.getDate() && 
        date.getMonth() === today.getMonth() && 
        date.getFullYear() === today.getFullYear();
      
      // Count bookings for this day by status
      const bookingStats = countBookingsByStatus(date);
      
      days.push({
        day,
        date,
        isToday,
        bookingStats
      });
    }
    
    return days;
  };
  
  // Count bookings for a specific day by status
  const countBookingsByStatus = (date) => {
    const filteredBookings = getFilteredBookings();
    const bookingsForDay = filteredBookings.filter(booking => {
      // Create date from booking date string and normalize it
      const bookingDateStr = new Date(booking.bookingDate).toISOString().split('T')[0];
      const dateStr = date.toISOString().split('T')[0];
      
      // Compare dates as strings in YYYY-MM-DD format
      return bookingDateStr === dateStr;
    });
    
    const stats = {
      total: bookingsForDay.length,
      confirmed: 0,
      pending: 0,
      cancelled: 0
    };
    
    bookingsForDay.forEach(booking => {
      const status = booking.status ? booking.status.toLowerCase() : '';
      if (stats.hasOwnProperty(status)) {
        stats[status]++;
      }
    });
    
    return stats;
  };
  
  // Get bookings for a specific day
  const getBookingsForDay = (date) => {
    const filteredBookings = getFilteredBookings();
    return filteredBookings.filter(booking => {
      // Compare dates using YYYY-MM-DD string representation
      const bookingDateStr = new Date(booking.bookingDate).toISOString().split('T')[0];
      const dateStr = date.toISOString().split('T')[0];
      
      return bookingDateStr === dateStr;
    }).map(booking => {
      // Add the organizer name to each booking
      return {
        ...booking,
        organizationName: getOrganizerName(booking)
      };
    });
  };
  
  // Generate calendar days
  const calendarDays = generateCalendarDays();
  
  // Handle navigation to previous month
  const handlePrevMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1));
  };
  
  // Handle navigation to next month
  const handleNextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1));
  };
  
  // Handle day click
  const handleDayClick = (day) => {
    if (!day) return;
    
    setSelectedDate(day.date);
    const bookings = getBookingsForDay(day.date);
    setBookingsForDay(bookings);
    setDialogOpen(true);
  };
  
  // Format date for display
  const formatDate = (date) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };
  
  // Get status color
  const getStatusColor = (status) => {
    const statusLower = status ? status.toLowerCase() : '';
    return statusConfig[statusLower]?.chipColor || 'default';
  };
  
  // Navigate to today
  const goToToday = () => {
    setCurrentMonth(new Date());
  };

  // Export menu handlers
  const handleExportMenuOpen = (event) => {
    setExportMenuAnchor(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setExportMenuAnchor(null);
  };

  // Format date for ICS file
  const formatICSDate = (date, timeStr = null) => {
    const d = new Date(date);
    
    // If time is provided, set the hours and minutes
    if (timeStr) {
      const [hours, minutes] = timeStr.split(':').map(Number);
      d.setHours(hours, minutes, 0, 0);
    }
    
    return d.toISOString().replace(/-|:|\.\d+/g, '');
  };

  // Helper to get time from timeSlot (handles both object and string formats)
  const getTimeFromTimeSlot = (timeSlot, isStart = true) => {
    if (!timeSlot) return '';
    
    // If timeSlot is a string in format "HH:MM - HH:MM"
    if (typeof timeSlot === 'string') {
      const times = timeSlot.split(' - ');
      return isStart ? times[0] : times[1];
    }
    
    // If timeSlot is an object with startTime and endTime
    return isStart ? timeSlot.startTime : timeSlot.endTime;
  };

  // Create a simple ICS file content
  const createICSContent = (bookings) => {
    let icsContent = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'PRODID:-//TripSlip//Calendar//EN',
      'CALSCALE:GREGORIAN',
      'METHOD:PUBLISH'
    ];

    bookings.forEach(booking => {
      const organizerName = getOrganizerName(booking);
      const organizerEmail = booking.organizer?.email || booking.organizerEmail || '';
      const bookingDate = new Date(booking.bookingDate);
      
      // Start and end times
      const startTime = getTimeFromTimeSlot(booking.timeSlot, true);
      const endTime = getTimeFromTimeSlot(booking.timeSlot, false);
      
      const dtStart = formatICSDate(bookingDate, startTime);
      const dtEnd = formatICSDate(bookingDate, endTime);
      
      // Create a unique ID for the event
      const uid = `${booking.tripSlipCode || booking._id}@tripslip.app`;
      
      // Format location
      const location = booking.departureLocation || '';
      
      // Add event to ICS content
      icsContent = icsContent.concat([
        'BEGIN:VEVENT',
        `UID:${uid}`,
        `DTSTAMP:${formatICSDate(new Date())}`,
        `DTSTART:${dtStart}`,
        `DTEND:${dtEnd}`,
        `SUMMARY:${organizerName} Visit (${booking.numberOfPeople} people)`,
        `DESCRIPTION:Status: ${booking.status}\\nNumber of People: ${booking.numberOfPeople}\\nTrip Code: ${booking.tripSlipCode || ''}\\nOrganizer Email: ${organizerEmail}`,
        `LOCATION:${location}`,
        `STATUS:${booking.status === 'confirmed' ? 'CONFIRMED' : booking.status === 'cancelled' ? 'CANCELLED' : 'TENTATIVE'}`,
        'END:VEVENT'
      ]);
    });

    icsContent.push('END:VCALENDAR');
    return icsContent.join('\r\n');
  };

  // Generate and download ICS file
  const generateICS = (bookings, filename) => {
    const icsContent = createICSContent(bookings);
    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Export handlers
  const handleExportDayBookings = () => {
    if (!selectedDate || bookingsForDay.length === 0) return;
    
    const dateStr = selectedDate.toISOString().split('T')[0];
    const filename = `bookings-${dateStr}.ics`;
    generateICS(bookingsForDay, filename);
    handleExportMenuClose();
    setDialogOpen(false);
  };

  const handleExportMonthBookings = () => {
    const filteredBookings = getFilteredBookings().filter(booking => {
      const bookingDate = new Date(booking.bookingDate);
      return (
        bookingDate.getMonth() === currentMonth.getMonth() &&
        bookingDate.getFullYear() === currentMonth.getFullYear()
      );
    });
    
    if (filteredBookings.length === 0) return;
    
    const monthStr = currentMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' }).replace(' ', '-').toLowerCase();
    const filename = `bookings-${monthStr}.ics`;
    generateICS(filteredBookings, filename);
    handleExportMenuClose();
  };

  const handleExportAllBookings = () => {
    const filteredBookings = getFilteredBookings();
    if (filteredBookings.length === 0) return;
    
    const filename = 'all-bookings.ics';
    generateICS(filteredBookings, filename);
    handleExportMenuClose();
  };


  // Check if bookings data is available
  const hasBookings = data && data.allBookings && data.allBookings.length > 0;

  return (
    <>
      <Card elevation={3}>
        <CardHeader
          title={
            <Box display="flex" alignItems="center">
              <CalendarMonthIcon sx={{ mr: 1, color: '#43a047' }} />
              <Typography variant="h5">
                {currentMonth.toLocaleDateString(undefined, { month: 'long', year: 'numeric' })}
              </Typography>
            </Box>
          }
          action={
            <Box display="flex" alignItems="center">
              {hasBookings && (
                <Tooltip title="Export Calendar">
                  <IconButton onClick={handleExportMenuOpen}>
                    <FileDownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Filter Bookings">
                <IconButton onClick={toggleFilter} color={filterOpen ? "primary" : "default"}>
                  <FilterAltIcon />
                </IconButton>
              </Tooltip>
              <Button size="small" onClick={goToToday} sx={{ mx: 1 }}>
                Today
              </Button>
              <IconButton onClick={handlePrevMonth}>
                <ChevronLeftIcon />
              </IconButton>
              <IconButton onClick={handleNextMonth}>
                <ChevronRightIcon />
              </IconButton>
            </Box>
          }
        />
        {filterOpen && (
          <Box p={2} bgcolor="#f5f5f5">
            <Typography variant="subtitle2" gutterBottom>Filter by Status:</Typography>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={statusFilter.confirmed} 
                    onChange={handleFilterChange} 
                    name="confirmed" 
                    sx={{ color: statusConfig.confirmed.color, '&.Mui-checked': { color: statusConfig.confirmed.color } }}
                  />
                }
                label={
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2">Confirmed</Typography>
                    <Box ml={1} width={12} height={12} bgcolor={statusConfig.confirmed.color} borderRadius="50%" />
                  </Box>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={statusFilter.pending} 
                    onChange={handleFilterChange} 
                    name="pending" 
                    sx={{ color: statusConfig.pending.color, '&.Mui-checked': { color: statusConfig.pending.color } }}
                  />
                }
                label={
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2">Pending</Typography>
                    <Box ml={1} width={12} height={12} bgcolor={statusConfig.pending.color} borderRadius="50%" />
                  </Box>
                }
              />
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={statusFilter.cancelled} 
                    onChange={handleFilterChange} 
                    name="cancelled" 
                    sx={{ color: statusConfig.cancelled.color, '&.Mui-checked': { color: statusConfig.cancelled.color } }}
                  />
                }
                label={
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2">Canceled</Typography>
                    <Box ml={1} width={12} height={12} bgcolor={statusConfig.cancelled.color} borderRadius="50%" />
                  </Box>
                }
              />
            </FormGroup>
          </Box>
        )}
        <Divider />
        <CardContent>
          <Grid container spacing={1}>
            {/* Weekday headers */}
            {weekDays.map((day, index) => (
              <Grid item xs={12/7} key={`weekday-${index}`}>
                <Box 
                  textAlign="center" 
                  py={1} 
                  fontWeight="bold"
                  sx={{
                    color: index === 0 || index === 6 ? 'text.secondary' : 'text.primary'
                  }}
                >
                  {day}
                </Box>
              </Grid>
            ))}
            
            {/* Calendar days */}
            {calendarDays.map((day, index) => (
              <Grid item xs={12/7} key={`day-${index}`}>
                {day ? (
                  <Box 
                    onClick={() => handleDayClick(day)}
                    sx={{
                      height: '80px',
                      border: '1px solid #eee',
                      borderRadius: '4px',
                      padding: '4px',
                      position: 'relative',
                      cursor: day.bookingStats.total > 0 ? 'pointer' : 'default',
                      transition: 'all 0.2s',
                      backgroundColor: day.isToday ? '#e3f2fd' : 'transparent',
                      '&:hover': day.bookingStats.total > 0 ? {
                        backgroundColor: day.isToday ? '#bbdefb' : '#f5f5f5',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
                      } : {}
                    }}
                  >
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        fontWeight: day.isToday ? 'bold' : 'normal'
                      }}
                    >
                      {day.day}
                    </Typography>
                    
                    {/* Status-specific booking indicators */}
                    <Box 
                      sx={{ 
                        position: 'absolute',
                        bottom: '4px',
                        right: '4px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        alignItems: 'flex-end'
                      }}
                    >
                      {day.bookingStats.confirmed > 0 && (
                        <Chip
                          size="small"
                          label={day.bookingStats.confirmed}
                          color="success"
                          sx={{
                            height: '18px',
                            minWidth: '18px',
                            fontSize: '0.7rem'
                          }}
                        />
                      )}
                      {day.bookingStats.pending > 0 && (
                        <Chip
                          size="small"
                          label={day.bookingStats.pending}
                          color="warning"
                          sx={{
                            height: '18px',
                            minWidth: '18px',
                            fontSize: '0.7rem'
                          }}
                        />
                      )}
                      {day.bookingStats.cancelled > 0 && (
                        <Chip
                          size="small"
                          label={day.bookingStats.cancelled}
                          color="error"
                          sx={{
                            height: '18px',
                            minWidth: '18px',
                            fontSize: '0.7rem'
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                ) : (
                  <Box 
                    sx={{
                      height: '80px',
                      border: '1px solid #eee',
                      borderRadius: '4px',
                      backgroundColor: '#f5f5f5',
                      opacity: 0.5
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
      
      {/* Export Menu */}
      <Menu
        anchorEl={exportMenuAnchor}
        open={Boolean(exportMenuAnchor)}
        onClose={handleExportMenuClose}
      >
        <MenuItem onClick={handleExportMonthBookings}>
          <CalendarMonthIcon fontSize="small" sx={{ mr: 1 }} />
          Export {currentMonth.toLocaleDateString(undefined, { month: 'long' })} Bookings
        </MenuItem>
        <MenuItem onClick={handleExportAllBookings}>
          <DownloadIcon fontSize="small" sx={{ mr: 1 }} />
          Export All Bookings
        </MenuItem>
      </Menu>
      
      {/* Dialog for day details */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
        {selectedDate && (
          <>
            <DialogTitle>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">{formatDate(selectedDate)}</Typography>
                {bookingsForDay.length > 0 && (
                  <Tooltip title="Export Day's Bookings">
                    <IconButton onClick={handleExportDayBookings} size="small">
                      <FileDownloadIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <Typography variant="subtitle2" color="text.secondary">
                  {bookingsForDay.length} {bookingsForDay.length === 1 ? 'booking' : 'bookings'}
                </Typography>
                <Box ml={2} display="flex" gap={1}>
                  {bookingsForDay.filter(b => b.status?.toLowerCase() === 'confirmed').length > 0 && (
                    <Chip 
                      size="small" 
                      label={`${bookingsForDay.filter(b => b.status?.toLowerCase() === 'confirmed').length} confirmed`} 
                      color="success" 
                    />
                  )}
                  {bookingsForDay.filter(b => b.status?.toLowerCase() === 'pending').length > 0 && (
                    <Chip 
                      size="small" 
                      label={`${bookingsForDay.filter(b => b.status?.toLowerCase() === 'pending').length} pending`} 
                      color="warning" 
                    />
                  )}
                  {bookingsForDay.filter(b => b.status?.toLowerCase() === 'cancelled').length > 0 && (
                    <Chip 
                      size="small" 
                      label={`${bookingsForDay.filter(b => b.status?.toLowerCase() === 'cancelled').length} cancelled`} 
                      color="error" 
                    />
                  )}
                </Box>
              </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>
              {isLoadingOrganizers ? (
                <Box display="flex" justifyContent="center" py={3}>
                  <CircularProgress size={40} />
                </Box>
              ) : bookingsForDay.length > 0 ? (
                <List>
                  {bookingsForDay.map((booking) => (
                    <ListItem 
                      key={booking._id} 
                      divider 
                      sx={{ 
                        cursor: 'pointer',
                        '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.04)' }
                      }}
                      onClick={() => navigate(`/view-booking/${booking._id}`)}
                    >
                      <Box width="100%">
                        <Box display="flex" justifyContent="space-between" mb={1}>
                          <Typography variant="subtitle1">
                            Booking #{booking.tripSlipCode || booking._id?.slice(-4)}
                          </Typography>
                          <Chip 
                            label={booking.status} 
                            size="small" 
                            color={getStatusColor(booking.status)} 
                          />
                        </Box>
                        
                        <Box display="flex" alignItems="center" mb={1}>
                          <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
                          <Typography variant="body2">
                            {formatTimeSlot(booking.timeSlot)}
                          </Typography>
                        </Box>
                        
                        <Box display="flex" alignItems="center" mb={1}>
                          <GroupIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
                          <Typography variant="body2">
                            {booking.numberOfPeople || 0} people
                          </Typography>
                        </Box>
                        
                        <Box display="flex" alignItems="center">
                          <SchoolIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
                          <Typography variant="body2">
                            {booking.organizationName}
                          </Typography>
                        </Box>
                        
                        {(booking.organizer?.email || booking.organizerEmail) && (
                          <Box display="flex" alignItems="center" mt={1} pl={3}>
                            <Typography variant="caption" color="text.secondary">
                              {booking.organizer?.email || booking.organizerEmail}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Box py={2} textAlign="center">
                  <Typography variant="body1" color="text.secondary">
                    No bookings for this day
                  </Typography>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)}>Close</Button>
              {bookingsForDay.length > 0 && (
                <Button 
                  onClick={handleExportDayBookings} 
                  startIcon={<FileDownloadIcon />}
                  color="primary"
                >
                  Export to Calendar
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default BookingCalendar;