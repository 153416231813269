import React from 'react';
import { Box, Typography } from '@mui/material';
import { EventAvailable } from '@mui/icons-material';

const ViewWeeklyAvailability = ({ weeklyAvailability }) => {
  const daysOfWeek = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
  ];

  // Improved time format function that handles multiple time formats
  function formatTime(timeString) {
    if (!timeString) return null;
    
    try {
      // Already formatted as "12:00 PM" - just return it
      if (typeof timeString === 'string' && 
          (timeString.includes('AM') || timeString.includes('PM'))) {
        return timeString;
      }
      
      // ISO string format (contains 'T')
      if (typeof timeString === 'string' && timeString.includes('T')) {
        const date = new Date(timeString);
        if (!isNaN(date)) {
          return date.toLocaleTimeString('en-US', { 
            hour: 'numeric', 
            minute: '2-digit',
            hour12: true 
          });
        }
      }
      
      // Simple time string like "12:00:00"
      if (typeof timeString === 'string' && timeString.includes(':')) {
        // Try to create a date from a time string by adding a fake date
        const [hours, minutes] = timeString.split(':');
        if (!isNaN(hours) && !isNaN(minutes)) {
          const date = new Date();
          date.setHours(parseInt(hours, 10));
          date.setMinutes(parseInt(minutes, 10));
          return date.toLocaleTimeString('en-US', { 
            hour: 'numeric', 
            minute: '2-digit',
            hour12: true 
          });
        }
      }
      
      // If all else fails, just return the string as is
      return timeString;
    } catch (error) {
      console.error('Error formatting time:', error, timeString);
      return timeString; // Return the original if we can't format it
    }
  }

  // Format the availability display
  const formatAvailability = (day) => {
    const availability = weeklyAvailability[day];
    if (!availability) return 'Not specified';
    if (availability.isClosed) return 'Closed';
    
    const start = formatTime(availability.startTime);
    const end = formatTime(availability.endTime);
    
    if (!start || !end) return 'Hours not specified';
    return `${start} - ${end}`;
  };

  return (
    <Box sx={{ px:2, mt: 2, mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <EventAvailable color="primary" sx={{ mr: 1 }} />
          <Typography variant="h6">Weekly Availability</Typography>
        </Box>
      {daysOfWeek.map((day) => (
        <Box key={day} sx={{ mb: 1 }}>
          <Typography variant="body1">
            <strong>{day}:</strong> {formatAvailability(day)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ViewWeeklyAvailability;