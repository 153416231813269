import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, IconButton, Button, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
const Logo = styled('img')({
  height: '40px',
  marginRight: '16px',
});

function Header({toggleDrawer, open}) {

const navigate = useNavigate();
const handleLogout = () => {
  sessionStorage.clear(); // Clear all sessionStorage
  navigate('/'); // Navigate to the root path
};

const handleMenuClick = (event) => {
  // Prevent event bubbling if it's causing issues
  event.stopPropagation();
  // Call the toggle function from parent
  toggleDrawer();
};
const location = useLocation(); // Use location to check current path

const isSignInPage = location.pathname === '/';

  return (
    <AppBar position="fixed" sx={{ position: 'fixed', width: '100%', zIndex: 1201 }}>
      <Toolbar sx={{ display: "flex", justifyContent: 'space-between' }}>
        <Box sx={{ display: "flex", alignItems: 'center' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ marginRight: '16px' }}
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          {!sessionStorage.role && <Logo src="/assets/images/image.png" alt="Logo" sx={{my:2, width:'50px', height: 'auto' }}/>}
          {sessionStorage.role === 'organizer' && <Typography variant='h6'> TripSlip Organizer Portal </Typography> }
          {sessionStorage.role === 'vendor' && <Typography variant='h6'> TripSlip Vendor Portal </Typography> }
          
        </Box>
        {(!isSignInPage && 
          !sessionStorage.userId) &&
            <Button color="inherit" onClick={() => navigate('/')}>Login</Button>
        }
        {sessionStorage.userId && <Button color="inherit" onClick={handleLogout}>Logout</Button>}
        
      </Toolbar>
    </AppBar>
  );
}

export default Header;
