/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Chip,
  Tooltip,
  Divider,
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CardActions,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Refresh as RefreshIcon,
  Event as EventIcon,
  Pending as PendingIcon,
  Today as TodayIcon,
  CalendarMonth as CalendarMonthIcon,
  MoreVert as MoreVertIcon,
  ArrowForward as ArrowForwardIcon,
  Settings as SettingsIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Group,
  Pending,
  CheckCircle,
} from '@mui/icons-material';
import './VendorDashboard.css';
import BookingCalendar from './BookingsCalendar/BookingsCalendar';
import { now } from 'moment-timezone';
import VisitorsCard from './VisitorsCard/VisitorsCard';
import { bookingService, vendorService } from '../../../apiCalls/vendorServices';
import { formatTimeSlot } from '../../Bookings/ViewBooking/ViewBooking';
// Helper function to format date for display
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  // Parse the date using YYYY-MM-DD format
  const dateStr = new Date(dateString).toISOString().split('T')[0];
  const date = new Date(dateStr + 'T00:00:00');
  
  // Return formatted date
  return date.toLocaleDateString();
};

// Get color for status chip
const getStatusColor = (status) => {
  const statusLower = status ? status.toLowerCase() : '';
  
  switch (statusLower) {
    case 'confirmed':
      return 'success';
    case 'pending':
      return 'warning';
    case 'cancelled':
      return 'error';
    default:
      return 'default';
  }
};
const VendorDashboard = ({ data, setData }) => {
  const token = sessionStorage.getItem('token');
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to beginning of day for accurate comparison
  
  const [isLoading, setIsLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [vendorInfo, setVendorInfo] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const [selectedBooking, setSelectedBooking] = useState(null);
  
  const navigate = useNavigate();
  const url = process.env.REACT_APP_SERVICE_URL;
  const vendorId = sessionStorage.getItem('vendorId');
  console.log(vendorId)

  // Fetch organizer data for each booking
  const fetchOrganizerData = async () => {
    if (!data || !data.allBookings || data.allBookings.length === 0) return;
    
    try {
      // Get unique organizer IDs from all bookings
      const organizerIds = [...new Set(data.allBookings.map(booking => booking.organizerId))];
      
      // For each organizer ID, fetch the organizer data
      const organizersPromises = organizerIds.map(async (orgId) => {
        try {
          const response = await axios.get(`${url}/`);
          return {
            _id: orgId,
            name: `${response.data.firstName} ${response.data.lastName}`,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            email: response.data.email,
            contactPhoneNumber: response.data.contactPhoneNumber,
          };
        } catch (error) {
          console.error(`Error fetching organizer with ID ${orgId}:`, error);
          return {
            _id: orgId,
            name: "Unknown Organizer",
            firstName: "Unknown",
            lastName: "Organizer",
            email: "",
            contactPhoneNumber: "",
          };
        }
      });
      
      const organizersData = await Promise.all(organizersPromises);
      console.log(organizersData);
      setOrganizations(organizersData);
    } catch (error) {
      console.error('Error fetching organizer data:', error);
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchVendorInfo = async () => {
      try {
        const response = await vendorService.getVendorById(vendorId);
        if (response && Object.keys(response).length) {
          setVendorInfo(response);
        } else {
          setVendorInfo(null);
        }
      } catch (error) {
        console.error('Error fetching vendor information:', error);
        setVendorInfo(null);
      }
    };

    const fetchDashboardData = async () => {
      try {
        // Get dashboard data from API
        const dashboardData = await bookingService.getVendorDashboardData(vendorId, token);
        
        // Set the transformed data in the parent component
        setData({...dashboardData});
        
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (vendorId) {
      fetchVendorInfo();
      fetchDashboardData();
    } else {
      setIsLoading(false);
      setVendorInfo(null);
    }
  }, [url, vendorId]);

  // Call fetchOrganizerData when data changes
  useEffect(() => {
    if (data && data.allBookings && data.allBookings.length > 0) {
      fetchOrganizerData();
    }
  }, [data]);

  // Calculate summary statistics
  const totalMonthlyBookings = data?.upcomingMonthBookings?.length || 0;
  const totalConfirmedPeopleCount = data?.upcomingMonthBookings?.filter(booking => booking.status === 'confirmed')
    .reduce((sum, booking) => sum + (booking.numberOfPeople || 0), 0) || 0;
  const totalPendingPeopleCount = data?.pendingBookings?.reduce((sum, booking) => sum + (booking.numberOfPeople || 0), 0) || 0;
  const totalPeople = totalPendingPeopleCount + totalConfirmedPeopleCount;

  // Helper function to format date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
  };

  // Format today's date for the heading
  const formattedToday = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
  
  // Add a function to close notifications
  const handleCloseNotification = () => {
    setNotification({
      ...notification,
      open: false
    });
  };
  // Add a state for tracking loading and handling notifications
  const [actionLoading, setActionLoading] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  // Handle opening the confirmation dialog
  const handleActionClick = async (bookingId, action) => {
    
    // Find the booking by ID
    const booking = data.allBookings.find(b => b._id === bookingId);
    if (!booking) return;
    console.log(booking)


    setSelectedBooking(booking);
    setActionType(action);
    setDialogOpen(true);
   
  };
  // Handle confirming the action
  const handleConfirmAction = async () => {
    if (!selectedBooking || !actionType) return;
    
    try {
      setActionLoading(true);
      
      // Call the API service to update the booking
      await bookingService.submitBookingAction(selectedBooking._id, actionType);
      
      // Show success notification
      setNotification({
        open: true,
        message: actionType === 'approve' 
          ? 'Booking confirmed successfully' 
          : 'Booking cancelled successfully',
        severity: 'success'
      });
      
      // Refresh the dashboard data to reflect the changes
      refreshDashboardData();
      
    } catch (error) {
      console.error('Error updating booking status:', error);
      
      // Show error notification
      setNotification({
        open: true,
        message: `Failed to update booking: ${error.response?.data?.message || error.message}`,
        severity: 'error'
      });
    } finally {
      setActionLoading(false);
      handleDialogClose();
    }
  };


  // Handle closing the dialog
  const handleDialogClose = async () => {
    setDialogOpen(false);
    setSelectedBooking(null);
    setActionType('');
  };


  // Get organizer name from booking
  const getOrganizerName = (booking) => {
    // If booking is just an organizerId
    // if (typeof booking === 'string') {
    //   // Look up in organizations list
    //   const organization = organizations.find(org => org._id === booking);
    //   return organization ? organization.name : 'Unknown Organization';
    // }
    
    
    // If booking has organizerId
    if (booking.organizerId) {
      const organization = organizations.find(org => org._id === booking.organizerId);
      return booking.organizerId;
    }
    
    return 'Unknown Organization';
  };


  // Refresh all data
  const refreshDashboardData = async () => {
    setIsLoading(true);
    
    try {
      // Fetch vendor information
      const vendorResponse = await vendorService.getVendorById(vendorId);
      if (vendorResponse && Object.keys(vendorResponse).length) {
        setVendorInfo(vendorResponse);
      } else {
        setVendorInfo(null);
      }
      
      // Fetch dashboard data
      const dashboardData = await bookingService.getVendorDashboardData(vendorId, token);
      setData({...dashboardData});
      
    } catch (error) {
      console.error('Error refreshing dashboard data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity} 
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
      {/* Page Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" fontWeight="bold" color="primary">
          Vendor Dashboard
        </Typography>
        <Button 
          variant="contained" 
          startIcon={<RefreshIcon />}
          onClick={refreshDashboardData}
          disabled={isLoading}
        >
          Refresh
        </Button>
      </Box>
      <Divider />

      {/* Loading Indicator */}
      {isLoading && (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      )}

      {/* Profile Completion Alert */}
      {!vendorInfo && !isLoading && (
        <Alert 
          severity="warning" 
          sx={{ mb: 4 }}
          action={
            <Button 
              color="inherit" 
              size="small" 
              startIcon={<SettingsIcon />}
              onClick={() => navigate('/vendor-information')}
            >
              Complete Profile
            </Button>
          }
        >
          <AlertTitle>Profile Incomplete</AlertTitle>
          <Typography variant="body2">
            Your vendor profile is incomplete. To accept bookings, please complete your profile setup.
          </Typography>
        </Alert>
      )}

      {/* Content Container with conditional overlay for incomplete profile */}
      {!isLoading && (
        <Box sx={{pt:2}} className={!vendorInfo ? 'profile-incomplete-overlay' : ''}>
          
          {/* Summary Cards */}
          <Grid container spacing={3} mb={4}>
            <Grid item xs={12} sm={6} >
              <Card elevation={2} className="dashboard-card" sx={{ borderLeft: '4px solid #43a047' }}>
                <CardContent>
                  <Box display="flex" alignItems="center" mb={1}>
                    <CalendarMonthIcon color="success" sx={{ mr: 1 }} />
                    <Typography variant="subtitle2" color="text.secondary">
                      Next 30 Days
                    </Typography>
                  </Box>
                  <Typography variant="h4" component="div" fontWeight="bold">
                    {totalMonthlyBookings}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
                    Confirmed bookings
                  </Typography> 
                  <Button 
                    size="small" 
                    sx={{ mt: 2 }} 
                    onClick={() => navigate('/calendar')}
                    endIcon={<ArrowForwardIcon />}
                    disabled={!vendorInfo}
                  >
                    View Calendar
                  </Button>
                </CardContent>
              </Card>
            </Grid> 

            <Grid item xs={12} sm={6} >
              <VisitorsCard 
                data={data} 
                totalPendingPeopleCount={totalPendingPeopleCount} 
                totalConfirmedPeopleCount={totalConfirmedPeopleCount} 
              />
            </Grid>
          </Grid>

          {/* Main Content */}
          <Grid container spacing={4} mb={4}>
            {/* Pending Bookings Section */}
            <Grid item xs={12} md={6}>
              <Card elevation={3}>
                <CardHeader
                  title={
                    <Box display="flex" alignItems="center">
                      <PendingIcon sx={{ mr: 1, color: '#5e35b1' }} />
                      <Typography variant="h6">Pending Bookings</Typography>
                    </Box>
                  }
                  action={
                    <Tooltip title="View All Pending Bookings">
                      <IconButton onClick={() => navigate('/view-bookings')} disabled={!vendorInfo}>
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Divider />
                <CardContent sx={{ p: 0 }}>
                  {data?.pendingBookings?.length > 0 ? (
                    <TableContainer sx={{ maxHeight: 400 }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Time Slot</TableCell>
                            <TableCell align="center">People</TableCell>
                            <TableCell align="center">Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.pendingBookings.slice(0, 5).map((booking) => (
                            <TableRow key={booking._id} hover sx={{ cursor: 'pointer' }} onClick={() => navigate(`/view-booking/${booking._id}`)}>
                              <TableCell>
                                <Typography variant="body2">
                                  {formatDate(booking.bookingDate)}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Chip
                                  icon={<EventIcon fontSize="small" />}
                                  label={formatTimeSlot(booking.timeSlot)}
                                  size="large"
                                  variant="outlined"
                                  color="secondary"
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Chip 
                                  label={booking?.numberOfPeople} 
                                  size="small" 
                                  color="primary"
                                  variant="outlined"
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
                                  <Tooltip title="Confirm Booking">
                                    <IconButton 
                                      size="small" 
                                      color="success" 
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleActionClick(booking._id, 'approve');
                                      }}
                                    >
                                      <CheckCircleIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Cancel Booking">
                                    <IconButton 
                                      size="small" 
                                      color="error"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleActionClick(booking._id, 'deny');
                                      }}
                                    >
                                      <CancelIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Box p={3} textAlign="center" className="empty-state">
                      <PendingIcon sx={{ fontSize: 48, color: '#bdbdbd', mb: 2 }} />
                      <Typography variant="body1" color="text.secondary">
                        No pending bookings available
                      </Typography>
                    </Box>
                  )}
                  {data?.pendingBookings?.length > 5 && (
                    <Box p={2} textAlign="center">
                      <Button 
                        variant="outlined" 
                        endIcon={<ArrowForwardIcon />}
                        onClick={() => navigate('/view-bookings')}
                        disabled={!vendorInfo}
                      >
                        View All ({data.pendingBookings.length})
                      </Button>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>

            {/* Today's Bookings Section */}
            <Grid item xs={12} md={6}>
              <Card elevation={3}>
                <CardHeader
                  title={
                    <Box display="flex" alignItems="center">
                      <TodayIcon sx={{ mr: 1, color: '#e53935' }} />
                      <Typography variant="h6">Today's Bookings - {formattedToday}</Typography>
                    </Box>
                  }
                  action={
                    <Tooltip title="Refresh Today's Bookings">
                      <IconButton onClick={refreshDashboardData} disabled={!vendorInfo}>
                        <RefreshIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Divider />
                <CardContent sx={{ p: 0 }}>
                  {data?.todayBookings?.length > 0 ? (
                    <TableContainer sx={{ maxHeight: 400 }}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>Time Slot</TableCell>
                            <TableCell>Count</TableCell>
                            <TableCell>Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.todayBookings.map((booking) => (
                            <TableRow 
                              key={booking._id} 
                              hover
                              onClick={() => navigate(`/view-booking/${booking._id}`)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <TableCell>
                                <Chip
                                  icon={<EventIcon fontSize="small" />}
                                  label={formatTimeSlot(booking.timeSlot)}
                                  size="large"
                                  variant="outlined"
                                  color="secondary"
                                />
                              </TableCell>
                              <TableCell>
                                <Chip 
                                  label={booking?.numberOfPeople} 
                                  size="small" 
                                  color="primary"
                                  variant="outlined"
                                />
                              </TableCell>
                              <TableCell>
                                <Chip 
                                  label={booking?.status} 
                                  size="small" 
                                  color={getStatusColor(booking?.status)}
                                  variant="outlined"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <Box p={3} textAlign="center" className="empty-state">
                      <TodayIcon sx={{ fontSize: 48, color: '#bdbdbd', mb: 2 }} />
                      <Typography variant="body1" color="text.secondary">
                        No bookings scheduled for today
                      </Typography>
                      <Button 
                        variant="text" 
                        sx={{ mt: 2 }}
                        startIcon={<CalendarMonthIcon />}
                        onClick={() => navigate('/calendar')}
                        disabled={!vendorInfo}
                      >
                        View Calendar
                      </Button>
                    </Box>
                  )}

                  {data?.todayBookings?.length > 0 && (
                    <Box p={2} textAlign="center">
                      <Button 
                        variant="outlined" 
                        endIcon={<ArrowForwardIcon />}
                        onClick={() => navigate('/view-bookings')}
                        disabled={!vendorInfo}
                      >
                        View All ({data.todayBookings.length})
                      </Button>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Calendar View Section */}
          <BookingCalendar data={data}/>
        </Box>
      )}

      {/* Confirmation Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {actionType === 'approve' ? 'Confirm Booking' : 'Cancel Booking'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {actionType === 'approve'
              ? 'Are you sure you want to confirm this booking? This will update the status to confirmed.'
              : 'Are you sure you want to cancel this booking? This action cannot be undone.'}
          </DialogContentText>
          {selectedBooking && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">Booking Details:</Typography>
              <Typography variant="body2">
                Date: {formatDate(selectedBooking.bookingDate)}
              </Typography>
              <Typography variant="body2">
                Time: {formatTimeSlot(selectedBooking.timeSlot)}
              </Typography>
              <Typography variant="body2">
                People: {selectedBooking.numberOfPeople}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="inherit">
            Cancel
          </Button>
          <Button 
            onClick={handleConfirmAction} 
            color={actionType === 'approve' ? 'success' : 'error'} 
            variant="contained"
            autoFocus
          >
            {actionType === 'approve' ? 'Confirm Booking' : 'Cancel Booking'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default VendorDashboard;